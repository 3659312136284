import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import React from 'react'
import { Button } from 'application/presentation/common/uiComponents'

import s from './SignWashPage.module.scss'

const SignWashPage = () => {
  return (
    <PageLayout title="Подключить автомойку">
      <div className={s.content}>
        <div className={s.text}>
          Подключите технологичный комплекс для обсулижвания автомоек к себе в компанию <br />
          MOY SERVICE
          <br />
          <br />
          Внутри: Маркетинг + ПО для Администратора, Сайт и Приложение для онлайн записи для
          клиентов, ПО для каждого бокса, Электронная очередь на ТВ.
          <br />
          <br />
          <span className={s.fiol}>
            ⚡️ Подключение и обслуживание без абонентской платы. Индивидуальный условия
          </span>
          <Button
            isLink
            to="http://moyservice.com/"
            className={s.btn}
            color="fiol"
            text="Оставить заявку"
          ></Button>
        </div>
      </div>
    </PageLayout>
  )
}

export default React.memo(SignWashPage)
