import { fetchRegions, selectRegionRequest } from 'application/core/data/api/regions'
import { Regions } from 'application/core/domain/entity/region/Region'
import { useQuery } from '@tanstack/react-query'

export const useRegions = () => {
  const query = useQuery<Regions>(['regions'], fetchRegions)
  return query
}

export const sendRegionToServer = selectRegionRequest
