import moment, { Moment } from 'moment'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import _flatMap from 'lodash/flatMap'
import _sortBy from 'lodash/sortBy'
import _isArray from 'lodash/isArray'

import {
  BookingAreasList,
  BookingList,
  BookingLocks,
  BookingTable,
} from '../../entity/booking/Booking'
import { Outlet } from '../../entity/outlet/Outlet'
import { getOutletAppliedParameter, getOutletWorkInterval } from '../outlets/outletActions'

export type TimeItem = { id: number; time: string; date: string; table?: any }

export const splitBookingTimesToIntervals = (data: Array<TimeItem>) => {
  const morning: Array<TimeItem> = []
  const day: Array<TimeItem> = []
  const evening: Array<TimeItem> = []
  const now = moment()

  const filtered = _filter(data, (item) => now.isBefore(moment(item.date)))

  _map(filtered, (item) => {
    const hour = parseInt(item.time.slice(0, 2))
    if (hour >= 1 && hour <= 11) {
      morning.push(item)
    }
    if (hour >= 12 && hour <= 17) {
      day.push(item)
    }
    if (hour >= 18 || hour === 0) {
      evening.push(item)
    }
  })
  return {
    morning,
    day,
    evening,
  }
}

export const getTimes = (
  outlet: Outlet | null,
  selectedDate: Moment,
  defaultTimeInterval: number,
) => {
  const workInterval = getOutletWorkInterval(outlet)
  const dates = []
  if (workInterval.hours) {
    const start = moment(workInterval.startWork.date.toString())
    const intervals = workInterval.hours * (3600 / defaultTimeInterval)
    const todayDate = selectedDate || moment()
    for (let i = 0; i < intervals; i++) {
      dates.push({
        id: i,
        time: start.format('HH:mm'),
        date: todayDate.format(`YYYY-MM-DD ${start.format('HH:mm:ss')}`),
      })
      start.add(defaultTimeInterval / 60, 'minutes')
    }
  }

  return dates
}

type GetBookingFreeTimesData = Array<
  Array<{
    date?: Moment
    height?: number
    isFree: boolean
    number?: number
    table_id?: number
    freeTime?: number
  }>
>

export const getBookingFreeTimes = (
  data: GetBookingFreeTimesData | null,
  onlyAfterNow?: boolean,
) => {
  if (data && data.length) {
    const times = []
    let dataLength = data.length
    let itemLength = data[0].length
    for (let i = 0; i < itemLength; i++) {
      let itemIndex = 0
      let isFree = false
      for (let j = 0; j < dataLength; j++) {
        if (data[j][i].isFree) {
          isFree = true
          itemIndex = j
        }
      }
      const item = data[itemIndex][i]
      if (isFree && item.date) {
        times.push({
          id: item.date.unix(),
          time: item.date.format('HH:mm'),
          date: item.date.format('YYYY-MM-DD HH:mm:ss'),
          table_id: item.table_id,
          number: item.number,
        })
      }
    }
    if (onlyAfterNow) {
      const now = moment()
      const filtered = _filter(times, (item) => now.isBefore(moment(item.date)))
      return filtered
    }
    return times
  }
  return []
}

export const getNearestFreeBookingTime = (
  data: GetBookingFreeTimesData | null,
  productionTime: number,
) => {
  if (data && data.length) {
    const now = moment()
    const sorted = _map(data, (item) => {
      return _sortBy(item, (innerItem) => innerItem.date)
    })
    let time = null
    let dataLength = sorted.length
    let itemLength = sorted[0].length
    for (let i = 0; i < itemLength; i++) {
      for (let j = 0; j < dataLength; j++) {
        if (
          sorted[j][i].isFree &&
          now.isBefore(moment(sorted[j][i].date), 'minutes') &&
          //@ts-ignore
          productionTime <= sorted[j][i].freeTime
        ) {
          time = sorted[j][i]
          break
        }
      }
      if (time) {
        break
      }
    }
    return time
  }
  return null
}

export const getAreasTables = (bookingAreas: BookingAreasList) => {
  if (bookingAreas.length) {
    const flatten = _flatMap(bookingAreas, (item) => item.tables)
    const filtered = _filter(flatten, (item) => (item.status ? true : false))
    return filtered
  }
  return []
}

export const getAvailableAreasTablesByStatusAndDate = (
  tables: Array<BookingTable>,
  settlementDate: Moment,
) => {
  if (tables && tables.length) {
    const now = moment()
    return _filter(tables, (item) =>
      item.status ? true : settlementDate.isAfter(now, 'day') ? true : false,
    )
  }
  return []
}

type GetBookedDatesProps = {
  bookingList: BookingList
  tablesData: Array<BookingTable>
  times: Array<{ time: string; date: string }>
  defaultTimeInterval: number
  bookingLocks: BookingLocks
  settlementDate?: Moment
}

export const getBookedDates = ({
  bookingList,
  tablesData,
  times,
  defaultTimeInterval,
  bookingLocks,
  settlementDate = moment(),
}: GetBookedDatesProps) => {
  const nowWithDefaultTimeInterval = moment().add(defaultTimeInterval, 'seconds')
  if (bookingList && _isArray(bookingList)) {
    const filtered = _filter(bookingList, (item) =>
      settlementDate.isSame(moment(item.booking_date), 'day') ? true : false,
    )
    const filteredBookingLocks = _filter(bookingLocks, (item) =>
      settlementDate.isSame(moment(item.booking_date), 'day') ? true : false,
    )
    let height = 0
    const tableFormattedData = _map(tablesData, (headItem) => {
      const ordersInThisTable = _sortBy(
        _filter(filtered, (orderItem) => (orderItem.table.id === headItem.id ? true : false)),
        'booking_date',
      )
      const bookingLocksInThisTable = _sortBy(
        _filter(filteredBookingLocks, (lockItem) =>
          lockItem.table.id === headItem.id ? true : false,
        ),
        'booking_date',
      )
      const lastTimeInThisDay = times[times.length - 1]
      return _map(times, (timeItem) => {
        const itemDate = moment(`${settlementDate.format('YYYY-MM-DD')} ${timeItem.time}`)
        if (itemDate.isBefore(nowWithDefaultTimeInterval)) {
          return {
            height: 1,
            number: headItem.number,
            table_id: headItem.id,
            isFree: false,
          }
        }

        const bookingLock = _find(bookingLocksInThisTable, (item) => {
          const lockTime = moment(item.booking_date)
          return lockTime.isSame(itemDate, 'hour') && lockTime.isSame(itemDate, 'minute')
            ? true
            : false
        })
        if (bookingLock) {
          const productionTime = parseInt(bookingLock.production_time, 10)
          const itemHeight = Math.ceil(productionTime / defaultTimeInterval)
          if (itemHeight > 1) {
            height = itemHeight - 1
          } else if (itemHeight < 1) {
            height = 1
          }
          return {
            data: bookingLock,
            height: itemHeight,
            // id: `${order[0].order_id}`,
            number: headItem.number,
            table_id: headItem.id,
            isFree: false,
            // time: timeItem.name,
            // tableStatus: headItem.status,
          }
        }

        const order = _find(ordersInThisTable, (item) => {
          const orderTime = moment(item.booking_date)
          return orderTime.isSame(itemDate, 'hour') && orderTime.isSame(itemDate, 'minute')
            ? true
            : false
        })

        if (order) {
          const productionTime = order.production_time
          // const productionTime = _.sumBy(order, (item: Order) =>
          //   parseInt(item.varieties[0].production_time, 10),
          // )
          const itemHeight = Math.ceil(productionTime / defaultTimeInterval)
          if (itemHeight > 1) {
            height = itemHeight - 1
          }
          return {
            data: order,
            height: itemHeight,
            // id: `${order[0].order_id}`,
            number: headItem.number,
            table_id: headItem.id,
            isFree: false,
            // time: timeItem.name,
            // tableStatus: headItem.status,
          }
        }
        if (height > 0) {
          height -= 1
          return {
            isFree: false,
          }
        }
        let freeTime = 0
        let closestOrder = _find(ordersInThisTable, (item) => {
          const orderTime = moment(item.booking_date)
          return orderTime.isAfter(itemDate)
        })
        if (closestOrder) {
          freeTime = moment(closestOrder.booking_date).diff(itemDate, 'seconds')
        } else {
          let closestBookingLock = _find(bookingLocksInThisTable, (item) => {
            const lockTime = moment(item.booking_date)
            return lockTime.isAfter(itemDate)
          })
          if (closestBookingLock) {
            freeTime = moment(closestBookingLock.booking_date).diff(itemDate, 'seconds')
          } else {
            const lastDate = moment(
              `${settlementDate.format('YYYY-MM-DD')} ${lastTimeInThisDay.time}`,
            ).add(defaultTimeInterval / 60, 'minutes')
            freeTime = lastDate.diff(itemDate, 'seconds')
          }
        }
        return {
          // id: `${timeItem.id}_${index}`,
          height: 1,
          date: itemDate,
          number: headItem.number,
          table_id: headItem.id,
          isFree: true,
          freeTime: freeTime,
          // time: timeItem.name,
          // tableStatus: headItem.status,
        }
      })
    })
    return tableFormattedData
  }
  return null
}

export const getAvailableBookingDates = (outlet: Outlet | null, month: Moment) => {
  if (outlet) {
    const parameter = getOutletAppliedParameter(outlet, 'available_booking_days')
    const arr = []
    let daysCount = 0
    const now = moment()
    if (parameter && (parameter.value || parameter.value === '0')) {
      daysCount = parseInt(parameter.value, 10)
    }
    const endDay = moment().add(daysCount, 'days')
    for (let i = 0; i < 7; i++) {
      const itemDate = moment(month.weekday(i))
      arr.push({
        date: itemDate,
        disabled: itemDate.isBefore(now, 'day') || itemDate.isAfter(endDay, 'day'),
      })
    }
    return arr
  }
  return []
}

export const checkIfDateIsAvailableTodayAndTomorrow = (outlet: Outlet | null) => {
  if (outlet) {
    const parameter = getOutletAppliedParameter(outlet, 'available_booking_days')
    let daysCount = 0
    const now = moment()
    const tomorrow = moment().add(1, 'day')
    if (parameter && (parameter.value || parameter.value === '0')) {
      daysCount = parseInt(parameter.value, 10)
    }
    const endDay = moment().add(daysCount, 'days')
    return {
      today: now.isSameOrBefore(endDay, 'days'),
      tomorrow: tomorrow.isSameOrBefore(endDay, 'days'),
    }
  }
}
