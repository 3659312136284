import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import MainPage from '../pages/MainPage'
import AuthPage from '../pages/AuthPage'
import SelectCarPage from '../pages/SelectCarPage'
import BookingPage from '../pages/BookingPage'
import CreateOrderPage from '../pages/CreateOrderPage'
import OrderResultPage from '../pages/OrderResultPage'
import CarsPage from '../pages/CarsPage/CarsPage'
import PaymentSystemsPage from '../pages/PaymentSystemsPage/PaymentSystemsPage'
import InformationPage from '../pages/InformationPage/InformationPage'
import SupportPage from '../pages/SupportPage/SupportPage'
import OrderHistoryPage from '../pages/OrderHistoryPage/OrderHistoryPage'
import CancelOrderPage from '../pages/CancelOrderPage/CancelOrderPage'
import OrderInfoPage from '../pages/OrderInfoPage/OrderInfoPage'
import ProfilePage from '../pages/ProfilePage/ProfilePage'
import PasswordResetPage from '../pages/AuthPage/PasswordResetPage'
import CareServicePage from '../pages/CareServicePage/CareServicePage'
import SignWashPage from '../pages/SignWashPage/SignWashPage'
import OffertaPage from '../pages/OffertaPage/OffertaPage'

import NotPrivateRoute from './NotPrivateRoute'

const MenuNavigation = () => {
  return (
    <Routes>
      <Route element={<Navigate to="/booking" replace />} path="/" />
      <Route
        element={
          <NotPrivateRoute>
            <AuthPage />
          </NotPrivateRoute>
        }
        path="/authorization"
      />
      <Route
        element={
          <NotPrivateRoute>
            <PasswordResetPage />
          </NotPrivateRoute>
        }
        path="/password_reset"
      />
      <Route element={<SelectCarPage />} path="/select_car" />
      <Route element={<BookingPage />} path="/booking" />
      <Route element={<CreateOrderPage />} path="/create_order" />
      <Route element={<OrderResultPage />} path="/order_result" />
      <Route element={<CancelOrderPage />} path="/cancel_order/:order_id" />
      <Route element={<CarsPage />} path="/cars" />
      <Route element={<PaymentSystemsPage />} path="/payment_systems" />
      <Route element={<InformationPage />} path="/information" />
      <Route element={<SupportPage />} path="/support/:order_id" />
      <Route element={<OrderHistoryPage />} path="/orders">
        {/* <Route path=":order_id"></Route> */}
      </Route>
      <Route element={<OrderInfoPage />} path="/orders/:order_id" />
      <Route element={<ProfilePage />} path="/profile" />
      <Route element={<CareServicePage />} path="/care" />
      <Route element={<SignWashPage />} path="/sign_wash" />
      <Route element={<OffertaPage />} path="/offerta" />
    </Routes>
  )
}

export default React.memo(MenuNavigation)
