import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import React, { useMemo } from 'react'
import lightBolt from 'images/light_bolt.png'

import s from './HighDemandNotify.module.scss'

type Props = {
  outlet: Outlet | null
}

const HighDemandNotify = ({ outlet }: Props) => {
  const show = useMemo(
    () => getOutletAppliedParameter(outlet, 'high_demand_enabled')?.value === '1',
    [outlet],
  )
  return show ? (
    <div className={s.container}>
      <div className={s.icon}>
        <img src={lightBolt} />
      </div>
      <div className={s.txt}>
        В данный момент наблюдаем повышенный спрос, поэтому включена повышенная тарификация
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(HighDemandNotify)
