import { Car } from 'application/core/domain/entity/car/Car'
import React from 'react'
import carPng from 'images/car.png'
import { ReactComponent as CheckIcon } from 'images/icons/yellow_check.svg'
import cn from 'classnames'

import s from './CarItem.module.scss'

type Props = {
  item: Car
  onClick: (item: Car) => void
  isActive: boolean
}

const CarItem = ({ item, onClick, isActive }: Props) => {
  return (
    <div className={cn(s.container, { [s.active]: isActive })} onClick={() => onClick(item)}>
      <div className={s.car_img}>
        <img src={carPng} alt="" />
      </div>
      <div className={s.car_name}>
        {item.name} {item.modelName}
      </div>
      <div className={s.car_number}>
        {item.number} {item.regionNumber}
      </div>
      {isActive && (
        <div className={s.check_icon}>
          <CheckIcon />
        </div>
      )}
    </div>
  )
}

export default React.memo(CarItem)
