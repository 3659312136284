import React from 'react'
import _map from 'lodash/map'
import cn from 'classnames'
import { Button } from 'application/presentation/common/uiComponents'

import s from './TimesList.module.scss'
type Props = {
  data: {
    morning: any
    day: any
    evening: any
  }
  onClick: Function
  activeItem: number
  onNextDayPressHandler: Function
}

const TimesList = ({ data, onClick, activeItem, onNextDayPressHandler }: Props) => {
  if (!data.day.length && !data.morning.length && !data.evening.length) {
    return (
      <>
        <div className={s.empty}>
          К сожалению, все забронировано <br />
          Попробуйте другой день
        </div>
        <Button
          text="Смотреть время на завтра"
          onClick={onNextDayPressHandler}
          color="gray"
          containerClassName={s.empty_btn}
        />
      </>
    )
  }
  return (
    <div className={s.container}>
      {data.morning.length ? (
        <>
          <div className={s.title}>Утро</div>
          <div className={s.times}>
            {data.morning.map((item: any, i: number) => {
              return (
                <div
                  className={cn(s.time_item, { [s.active]: item.id === activeItem })}
                  key={`time_item_${item.id}`}
                  onClick={() => onClick(item)}
                >
                  {item.time}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      {data.day.length ? (
        <>
          <div className={s.title}>День</div>
          <div className={s.times}>
            {_map(data.day, (item) => {
              return (
                <div
                  className={cn(s.time_item, { [s.active]: item.id === activeItem })}
                  key={`time_item_${item.id}`}
                  onClick={() => onClick(item)}
                >
                  {item.time}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <></>
      )}
      {data.evening.length ? (
        <>
          <div className={s.title}>Вечер</div>
          <div className={s.times}>
            {_map(data.evening, (item) => {
              return (
                <div
                  className={cn(s.time_item, { [s.active]: item.id === activeItem })}
                  key={`time_item_${item.id}`}
                  onClick={() => onClick(item)}
                >
                  {item.time}
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(TimesList)
