import React, { useCallback, useMemo } from 'react'
import PageLayout from 'application/presentation/common/PageLayout'
import { useClientConfigs } from 'application/core/domain/useCase/client_configs/clientConfigsActions'
import { useAppSelector } from 'application/core/domain/store/hooks'
import _filter from 'lodash/filter'
import { Button, Loader } from 'application/presentation/common/uiComponents'
import { useDispatch } from 'react-redux'
import { Car } from 'application/core/domain/entity/car/Car'
import { setCar } from 'application/core/domain/store/carsReducer'
import { useNavigate } from 'react-router'
import AddOrEditCarMW from 'application/presentation/common/ModalWindows/AddOrEditCarMW/AddOrEditCarMW'
import { NavLink } from 'react-router-dom'

import CarItem from './components/CarItem'
import s from './CarsPage.module.scss'

const CarsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token, currentCar } = useAppSelector(({ userReducer, carsReducer }) => ({
    token: userReducer.token,
    currentCar: carsReducer.currentCar,
  }))
  const { data, isLoading } = useClientConfigs(token || '')

  const cars = useMemo(() => {
    if (data) {
      return _filter(data, (item) => (item.car ? true : false))
    }
    return []
  }, [data])

  const onItemClick = useCallback(
    (item: Car) => {
      setCar(item)(dispatch)
    },
    [dispatch],
  )

  return (
    <PageLayout title="Автомобили">
      {isLoading ? (
        <Loader size={32} />
      ) : (
        <>
          <div className={s.cars_container}>
            {cars.length ? (
              cars.map((item) => (
                <CarItem
                  key={`car_item_${item.car?.id}`}
                  //@ts-ignore
                  item={item.car}
                  onClick={onItemClick}
                  isActive={currentCar && currentCar.id === item.car?.id ? true : false}
                />
              ))
            ) : (
              <div className={s.empty}>Список машин пуст</div>
            )}
          </div>
          <NavLink className={s.add_new} to="#add_car">
            Добавить новую
          </NavLink>
          <Button
            text="Сохранить выбор"
            onClick={() => navigate(-1)}
            color="fiol"
            containerClassName={s.btn}
          />
        </>
      )}
      <AddOrEditCarMW />
    </PageLayout>
  )
}

export default React.memo(CarsPage)
