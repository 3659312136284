import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import React from 'react'
import { Button } from 'application/presentation/common/uiComponents'

import s from './CareServicePage.module.scss'

const CareServicePage = () => {
  return (
    <PageLayout title="Служба заботы">
      <div className={s.content}>
        <div className={s.text}>
          Мы с радостью поможем с решением ваших вопросов онлайн в чате Telegram. <br />
          <br />
          Так же вы можете написать нам на почту:{' '}
          <a href="mailto:moyservice@gmail.com">moyservice@gmail.com</a>
        </div>
        <Button
          color="gray"
          text="Написать на почту"
          className={s.btn}
          isLink
          to="mailto:moyservice@gmail.com"
        />
        <Button
          color="fiol"
          text="Написать в Telegram"
          className={s.btn}
          isLink
          to="https://t.me/MOYsupport"
        />
      </div>
    </PageLayout>
  )
}

export default React.memo(CareServicePage)
