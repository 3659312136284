import { Product } from 'application/core/domain/entity/product/Product'
import {
  createClientConfigsRequest,
  getClientConfigsRequest,
  deleteClientConfigsRequest,
} from 'application/core/data/api/client_configs'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  ClientConfigs,
  ClientFavoriteProduct,
} from 'application/core/domain/entity/clientConfigs/ClientConfig'
import queryClient from 'application/core/data/apiClient/queryClient'
import _filter from 'lodash/filter'

import { Car } from '../../entity/car/Car'

export const createClientConfigs = async (data: Product | Car | ClientFavoriteProduct) => {
  const stringify = JSON.stringify(data)
  return createClientConfigsRequest(stringify)
}

export const getClientConfigs = getClientConfigsRequest

export const filterClientConfigs = (
  data: ClientConfigs,
  type?: 'car' | 'client_favorite_product',
) => {
  switch (type) {
    case 'car':
      return _filter(data, (item) => (item && item.car ? true : false))
    case 'client_favorite_product':
      return _filter(data, (item) => (item && item.client_favorite_product ? true : false))
    default:
      return data
  }
}

export const useClientConfigs = (token: string, type?: 'car' | 'client_favorite_product') => {
  const query = useQuery<ClientConfigs>(['client_configs', token], getClientConfigsRequest, {
    enabled: token ? true : false,
  })
  return {
    ...query,
    data: query.data ? filterClientConfigs(query.data, type) : undefined,
    isLoading: !token ? false : query.isLoading,
  }
}

export const deleteClientConfigs = deleteClientConfigsRequest

export const DeleteClientConfigsMutation = (token: string | null) => {
  const mutation = useMutation(({ id }: { id: number }) => deleteClientConfigsRequest(id), {
    onMutate: async ({ id }: { id: number }) => {
      await queryClient.cancelQueries(['client_configs'])
      const previousData = queryClient.getQueryData<any>(['client_configs', token || ''])
      if (previousData) {
        queryClient.setQueryData(
          ['client_configs', token || ''],
          _filter(previousData, (item) => item.id !== id),
        )
      }
      return { previousData }
    },
    onError: (err, variables, context: any) => {
      queryClient.setQueryData(['client_configs', token || ''], context.previousData)
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries(['client_configs'])
    },
  })
  return mutation
}
