import apiClient from 'application/core/data/apiClient'

export const fetchProducts = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/products?outlet_id=${outlet_id}`)
  return data
}

export const fetchAllProducts = async (outlet_id?: number) => {
  const { data } = await apiClient.get(
    `/api/products?show_hidden=true${outlet_id ? `&outlet_id=${outlet_id}` : ''}`,
  )
  return data
}

export const fetchProduct = async (id: number, outlet_id: number) => {
  const { data } = await apiClient.get(`/api/products/${id}?outlet_id=${outlet_id}`)
  return data
}
