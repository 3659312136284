import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { Region } from 'application/core/domain/entity/region/Region'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { setOutlet, setUserRegion } from 'application/core/domain/store/userReducer'
import { useOutlets } from 'application/core/domain/useCase/outlets/getOutlets'
import { useRegions } from 'application/core/domain/useCase/regions/getRegions'
import moment from 'moment'
import React, { useMemo, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import _sort from 'lodash/sortBy'
import _filter from 'lodash/filter'
import _cloneDeep from 'lodash/cloneDeep'
import { getGeolocationDistance, useCurrentPosition } from 'application/utils/geolocationUtils'
import { setBasket } from 'application/core/domain/store/basketReducer'

import { Loader, OutletItem } from '../../uiComponents'
import MWContainer from '../MWContainer'

import s from './OutletsMW.module.scss'

const OutletsMW = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { hash } = useLocation()
  const { region, outlet } = useAppSelector(({ userReducer }) => ({
    region: userReducer.region,
    outlet: userReducer.outlet,
  }))

  const currentPosition = useCurrentPosition()

  const { data, isLoading } = useOutlets()
  const { data: regionsData } = useRegions()

  const opened = useMemo(() => hash.indexOf('outlets') !== -1, [hash])

  const onRegionClickHandler = useCallback(
    (item: Region) => {
      setUserRegion(item)(dispatch)
    },
    [dispatch],
  )

  const selectBar = useCallback(
    (item: Outlet) => {
      sessionStorage.setItem('outlet_id', item.id.toString())
      setOutlet(item)(dispatch)
      setBasket([])(dispatch)
      //   setStage(2)
      navigate(-1)
    },
    [navigate, dispatch],
  )

  const barIsNotWorking = useCallback((bar_item: any) => {
    if (bar_item) {
      let end = bar_item.time_work
      const now = moment()
      end = end.replace(/\s+/g, '').trim()
      end = end.split('-')[1]
      let endTime = moment().format('YYYY-MM-DD')
      endTime = `${endTime} ${end}`
      const endDate = moment(endTime)
      return endDate.diff(now, 'minutes') <= 10 ? true : false
    }
    return true
  }, [])

  const barList = useMemo(() => {
    if (data && data.length) {
      let filtered = _cloneDeep(data)
      if (region) {
        filtered = _filter(data, (item: Outlet) => item.region_id === region.id)
      }
      let outlets = _sort(
        filtered.map((item: any) => ({
          ...item,
          is_not_workink: barIsNotWorking(item),
          distance: currentPosition
            ? getGeolocationDistance(
                item.height,
                item.width,
                currentPosition.longitude,
                currentPosition.latitude,
              )
            : null,
        })),
        (item) => {
          return item.is_distance_order ? item.distance || 1 : item.distance + 50
        },
      )
      return outlets
    }
    return []
  }, [data, barIsNotWorking, currentPosition, region])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (hash.indexOf('outlets') !== -1) {
          navigate(-1)
        }
      }}
      className={s.modal_window_desk}
      btnClassName={s.close_btn}
    >
      <div className={s.title}>Выберите мойку</div>
      <div className={s.regions}>
        {regionsData ? (
          regionsData.map((item) => (
            <div
              className={`${s.region_item} ${region && item.id === region.id ? s.active : ''}`}
              onClick={() => onRegionClickHandler(item)}
              key={`region_item_${item.id}`}
            >
              {item.name}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      {isLoading ? (
        <Loader size={32} />
      ) : (
        <div className={s.outlets}>
          {barList.map((item: Outlet) => (
            <OutletItem
              data={item}
              onClick={selectBar}
              key={`outlet_item_${item.id}`}
              isActive={outlet && outlet.id === item.id ? true : false}
            />
          ))}
        </div>
      )}
    </MWContainer>
  )
}

export default React.memo(OutletsMW)
