/* eslint-disable @typescript-eslint/naming-convention */
import { PaymentDataProps, PaymentProps } from 'application/core/domain/types/PaymentsType'
// import { ApplePay } from 'react-native-apay'

const applePay = async ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  onStart()
  const requestData: any = {
    merchantIdentifier: 'merchant.ru.dondonuts',
    supportedNetworks: ['mastercard', 'visa'],
    countryCode: 'RU',
    currencyCode: 'RUB',
    paymentSummaryItems: [
      {
        label: 'Don Donuts',
        amount: `${data.amount}.00`,
      },
    ],
  }
  // if (ApplePay.canMakePayments) {
  //   ApplePay.requestPayment(requestData)
  //     .then((paymentData: any) => {
  //       apayProcess(paymentData, data, onSuccess, onError)
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       onError('error', 'Не удалось запустить applepay сессию')
  //     })
  // }
}

const apayProcess = (
  cryptogram: any,
  payData: PaymentDataProps,
  onSuccess: Function,
  onError: Function,
) => {
  const { amount, ip_adress, card_name, order_id } = payData
  const data = {
    crypto: cryptogram,
    amount,
    ip_adress,
    card_name,
    open_order_id: order_id,
  }
  // apiClient({
  //   method: 'POST',
  //   url: '/api/payments',
  //   data: data,
  // })
  //   .then(response => {
  //     if (response.data.status === 'OK') {
  //       ApplePay.complete(ApplePay.SUCCESS)
  //       onSuccess('success')
  //     } else {
  //       ApplePay.complete(ApplePay.FAILURE)
  //       onError('error', response.data.Model.CardHolderMessage)
  //     }
  //   })
  //   .catch(error => {
  //     ApplePay.complete(ApplePay.FAILURE)
  //     onError(
  //       'error',
  //       `Произошла ошибка \n ${error.response && error.response.data ? error.response.data : ''}`,
  //     )
  //   })
}

export default applePay
