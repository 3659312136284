/* eslint-disable @typescript-eslint/naming-convention */
import { Product } from 'application/core/domain/entity/product/Product'
import { ProductOptionItem } from 'application/core/domain/entity/product/ProductOption'
import _ from 'lodash'

import { Basket } from '../../entity/basket/Basket'

type AddAdditionalToProductProps = {
  product: Product
  optionId: number
  optionItemId: number
}

export const addAdditionalToProduct = (
  { product, optionId, optionItemId }: AddAdditionalToProductProps,
  onlyUniqOptions?: boolean,
): Product => {
  if (product) {
    const tempProd = _.clone(product)
    if (!tempProd.selected_options) {
      //@ts-ignore
      tempProd.selected_options = []
    }
    let option = _.find(tempProd.options, { id: optionId })
    if (option) {
      // const optionItem = _.find(option.items, { id: optionItemId })
      // @ts-ignore
      // option = { ...option, selected: true, items: [{ ...optionItem, selected: true }] }
      option = {
        ...option,
        selected: true,
        selectedOptionId: optionItemId,
        items: _.map(option.items, (item) => ({
          ...item,
          selected: item.id === optionItemId ? true : false,
        })),
      }

      if (onlyUniqOptions) {
        const index = _.findIndex(tempProd.selected_options, {
          //@ts-ignore
          id: option.id,
        })
        if (index !== -1) {
          _.set(tempProd, `selected_options[${index}]`, option)
        } else {
          // @ts-ignore
          tempProd.selected_options.push(option)
        }
      } else {
        // @ts-ignore
        tempProd.selected_options.push(option)
      }
    }
    return tempProd
  }
  return product
}

export const getProductsIds = (basket: Basket) => {
  if (basket.length) {
    return _.map(basket, (item) => item.id)
  }
  return []
}

type RemoveAdditionalFromProductProps = {
  product: Product
  optionId: number
  optionItemId?: number
  options?: {
    decrementCount?: boolean
  }
}

export const removeAdditionalFromProduct = ({
  product,
  optionId,
  optionItemId,
  options,
}: RemoveAdditionalFromProductProps): Product => {
  const tempProd = _.clone(product)
  if (!tempProd.selected_options) {
    return tempProd
  }
  if (options && options.decrementCount) {
    const index = _.findIndex(tempProd.selected_options, (item) =>
      item.id === optionId && item.items[0].id === optionItemId ? true : false,
    )
    tempProd.selected_options.splice(index, 1)
  } else {
    tempProd.selected_options = _.remove(tempProd.selected_options, (item) => {
      if (optionItemId) {
        return item.id === optionId && item.items[0].id === optionItemId ? false : true
      }
      return item.id === optionId ? false : true
    })
  }

  return tempProd
}

export const getSelectedAdditionasIds = (product: Product) => {
  let arr: any = []
  if (product) {
    // let temp = _.filter(product.options, { selected: true })
    let temp = _.filter(product.selected_options, { selected: true })
    temp.map((item: any) => {
      item.items.map((item2: any) => {
        if (item2.selected) {
          arr.push(item2.variety_id)
        }
      })
    })
  }
  return Object.assign({}, arr)
}

export const checkAdditionalOptionToVolumeRelation = (
  option: ProductOptionItem,
  selectedVolume: number,
) => {
  if (!option.item_to_variety || !option.item_to_variety.length) {
    return true
  }
  let res = _.find(
    option.item_to_variety,
    (item_to_variety) => item_to_variety.product_variety_id === selectedVolume,
  )

  return res ? true : false
}

export const editSelectedOptionsBySelectedVolume = (
  data: Product,
  selectedVolume: number,
): Product => {
  const options = _.map(data.selected_options, (option) => {
    if (!option.combo_additional) {
      const selected = _.find(option.items, (item) => (item.selected ? true : false))
      if (selected) {
        const name = selected.name.replace(/ .*/, '').toLowerCase()
        let haveSelected = false
        const items = _.map(option.items, (item) => {
          let select = false
          const item_name = item.name.replace(/ .*/, '').toLowerCase()
          if (name == item_name && checkAdditionalOptionToVolumeRelation(item, selectedVolume)) {
            select = true
            haveSelected = true
          }
          return {
            ...item,
            selected: select,
          }
        })
        return { ...option, items, selected: haveSelected }
      }
      return { ...option, selected: false }
    }
    return option
  })
  return {
    ...data,
    selected_options: _.filter(options, (option) => (option.selected ? true : false)),
  }
}
