import React, { useCallback, useState } from 'react'
import { ImageComponent, RatingIcon } from 'application/presentation/common/uiComponents'
import moment from 'moment'
import { Order } from 'application/core/domain/entity/order/Order'
import Rating from 'application/presentation/common/uiComponents/Rating/Rating'
import Tips from 'application/presentation/common/uiComponents/Tips/Tips'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import supportPng from 'images/support_img.png'
import { rateOrder } from 'application/core/domain/useCase/orders/orderAction'
import queryClient from 'application/core/data/apiClient/queryClient'
import { NavLink, useNavigate } from 'react-router-dom'

import s from '../OrderInfoPage.module.scss'

type Props = {
  data: Order
}

const OrderInfoContent = ({ data }: Props) => {
  const navigate = useNavigate()
  const [rating, setRating] = useState<number>(data.rating || 0)
  const [tipsVal, setTipsVal] = useState<number>(0)

  const changeRatingHandler = useCallback(
    (val: number) => {
      setRating(val)
      rateOrder(data.id, val).then(() => {
        queryClient.invalidateQueries(['orders'])
        queryClient.invalidateQueries(['order'])
      })
    },
    [data],
  )

  const onSupportClickHandler = useCallback(
    () => navigate(`/support/${data.order_id ? data.order_id : data.id}`),
    [navigate, data],
  )

  return (
    <div>
      <div className={s.title}>{moment(data.created_at).format('DD.MM, в HH:mm')}</div>
      <div className={s.user_containers}>
        <div className={s.user_item}>
          {/* <RatingIcon value={4.3} containerClassName={s.rate} /> */}
          <ImageComponent
            imageUrl={data.barista.photo || ''}
            containerClassName={s.img_container}
          />
          <div className={s.name}>{data.barista.name}</div>
        </div>
        <div className={s.user_item} onClick={onSupportClickHandler}>
          <div className={s.img_container}>
            <img src={supportPng} alt="" />
          </div>
          <div className={s.name}>Поддержка</div>
        </div>
      </div>
      <Rating rating={rating} onChange={changeRatingHandler} containerClassName={s.rating} />
      {/* <Tips value={tipsVal} onChange={setTipsVal} containerClassName={s.tips} /> */}
      <NavLink className={s.details_btn} to="#details">
        <InfoSvg />
        <div>Детали заказа</div>
        <div className={s.trigger}>
          <TriggerSvg />
        </div>
      </NavLink>
    </div>
  )
}

export default React.memo(OrderInfoContent)
