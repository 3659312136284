import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import PaySelector from 'application/presentation/common/uiComponents/PaySelector/PaySelector'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { useUserPaymentCards } from 'application/core/domain/useCase/payments/userPaymentsCard'
import { setPayMethod } from 'application/core/domain/store/paymentReducer'
import _map from 'lodash/map'
import _concat from 'lodash/concat'
import { ReactComponent as SberpaySvg } from 'images/payIcons/sberpay2.svg'
import { ReactComponent as CardSvg } from 'images/payIcons/card_icon.svg'
import { ReactComponent as MirSvg } from 'images/payIcons/mir.svg'
import { ReactComponent as VisaSvg } from 'images/payIcons/visa.svg'
import { ReactComponent as MasterCardSvg } from 'images/payIcons/mastercard.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import SelectTipsMW from 'application/presentation/common/ModalWindows/SelectTipsMW/SelectTipsMW'

import s from './PaymentSystems.module.scss'
import PaymentSystemItem from './components/PaymentSystemItem'

const payMethods = [
  {
    id: 5,
    name: 'SberPay',
    icon: <SberpaySvg />,
  },
  { id: 1, name: 'Привязать новую карту', icon: <CardSvg /> },
]

const PaymentSystemsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token, selectedPayMethod } = useAppSelector(({ userReducer, paymentMethodReducer }) => ({
    token: userReducer.token,
    selectedPayMethod: paymentMethodReducer.selectedPayMethod,
  }))
  const [tipsValue, setTipsValue] = useState<number>(0)
  const { data: cardsData } = useUserPaymentCards(token)

  const onItemClick = useCallback(
    (item: { id: number }) => {
      setPayMethod(item.id)(dispatch)
      localStorage.setItem('last_pay_method', item.id.toString())
    },
    [dispatch],
  )

  const onTipsClickHandler = useCallback(() => navigate('#select_tips'), [navigate])

  const paymentSystems = useMemo(() => {
    if (cardsData) {
      const cards = _map(cardsData, (item) => {
        let icon = null
        switch (item.payment_system) {
          case 'VISA':
            icon = <VisaSvg />
            break
          case 'MASTERCARD':
            icon = <MasterCardSvg />
            break
          case 'MIR':
            icon = <MirSvg />
            break
          default:
            icon = <CardSvg />
            break
        }
        return {
          ...item,
          name: `${item.payment_system ? item.payment_system : ''} ${`**${
            item.card_number ? item.card_number.replace(/X/g, '') : ''
          }`}`,
          icon: icon,
        }
      })
      return _concat(cards, payMethods)
    }
    return payMethods
  }, [cardsData])

  return (
    <PageLayout
      title="Способ оплаты"
      rightComponent={
        <NavLink className={s.edit} to="#edit_ps">
          Ред.
        </NavLink>
      }
    >
      <div className={s.container}>
        {_map(paymentSystems, (item) => (
          <PaymentSystemItem
            item={item}
            key={`payment_system_${item.id}`}
            onItemClick={onItemClick}
            isActive={selectedPayMethod === item.id}
          />
        ))}
        {/* <div className={s.tips_container} onClick={onTipsClickHandler}>
          <div>Чаевые по умолчанию</div>
          <div>{tipsValue}%</div>
        </div> */}
      </div>
      <SelectTipsMW value={tipsValue} onChange={setTipsValue} />
    </PageLayout>
  )
}

export default React.memo(PaymentSystemsPage)
