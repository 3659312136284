import { Loader } from 'application/presentation/common/uiComponents'
import React from 'react'
import InputMask from 'react-input-mask'
import cn from 'classnames'

import s from './MaskedTextInput.module.scss'

type Props = {
  title?: string
  value: string
  onChange: Function
  mask: string
  inputMode?: 'search' | 'text' | 'email' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  maskChar: string
  rightTxt?: string
  isLoading?: boolean
  onFocus?: Function
  onBlur?: Function
  readOnly?: boolean
  placeholder?: string
  containerClassName?: string
  inputClassName?: string
}

const MaskedTextInput = ({
  value,
  onChange,
  mask,
  inputMode,
  maskChar,
  rightTxt,
  title,
  isLoading,
  onFocus,
  onBlur,
  readOnly,
  placeholder,
  containerClassName,
  inputClassName,
}: Props) => {
  return (
    <div className={s.out_container}>
      {title ? <div className={s.title}>{title}</div> : <></>}
      <div className={cn(s.container, containerClassName || '')}>
        <InputMask
          mask={mask}
          alwaysShowMask
          // inputRef={inputRef}
          // ref={inputRef}

          //@ts-ignore
          maskChar={maskChar}
          inputMode={inputMode}
          value={value}
          placeholder={placeholder}
          readOnly={readOnly || false}
          className={inputClassName}
          //@ts-ignore
          formatChars={{
            '9': '[0-9]',
            a: '[A-Za-zА-Яа-я]',
            '*': '[A-Za-z0-9]',
            r: '[А-Яа-я]',
          }}
          onChange={(e: any) => onChange(e.target.value)}
          onFocus={() => (onFocus ? onFocus() : {})}
          onBlur={() => (onBlur ? onBlur() : {})}
        />
        {isLoading ? (
          <div className={s.right}>
            <Loader color="#ff7a00" containerClassName={s.loader} size={16} />
          </div>
        ) : rightTxt ? (
          <div className={s.right}>{rightTxt}</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(MaskedTextInput)
