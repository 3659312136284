import FormContainer from 'application/presentation/common/FormContainer/FormContainer'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import SelectCarForm from 'application/presentation/common/SelectCarForm'

const SelectCarPage = () => {
  const navigate = useNavigate()

  const onAddCar = useCallback(() => {
    navigate('/booking')
  }, [navigate])

  return (
    <div>
      <FormContainer title="Выберите марку машины" subtitle="Это необходимо для актуализации цен">
        <SelectCarForm onSuccess={onAddCar} />
      </FormContainer>
    </div>
  )
}

export default React.memo(SelectCarPage)
