import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import React, { useMemo } from 'react'
import { Loader, OrderItem } from 'application/presentation/common/uiComponents'
import { ReactComponent as RightTrigger } from 'images/icons/right_gray_trigger.svg'
import { NavLink, useParams } from 'react-router-dom'
import _find from 'lodash/find'
import { useOutlets } from 'application/core/domain/useCase/outlets/getOutlets'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import { useOrder } from 'application/core/domain/useCase/orders/getOrders'

import s from './SupportPage.module.scss'

const SupportPage = () => {
  const params: any = useParams()
  const orderId = useMemo(() => (params?.order_id ? parseInt(params.order_id) : 0), [params])
  const { data, isLoading } = useOrder(orderId, false)
  const { data: outletsData, isLoading: outletsIsLoading } = useOutlets()

  const outlet = useMemo(() => {
    if (data && outletsData) {
      return _find(outletsData, (item) => item.id === data.outlet_id)
    }
    return null
  }, [outletsData, data])

  const outletParams = useMemo(() => {
    if (outlet) {
      const phone = getOutletAppliedParameter(outlet, 'phone')
      return { phone: phone?.value ? `+${phone?.value.replace(/\D/g, '')}` : '' }
    }
    return {
      phone: null,
    }
  }, [outlet])
  return (
    <PageLayout title="Служба поддержки">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={s.container}>
            <div className={s.left}>
              {data ? <OrderItem item={data} notNeedSupport /> : <div>У вас нет заказов</div>}
            </div>
            <div className={s.right}>
              <NavLink to="/orders" className={s.list_item}>
                <div>Проблема с другим заказом</div>
                <div className={s.item_icon}>
                  <RightTrigger />
                </div>
              </NavLink>
              {/* <NavLink to="#" className={s.list_item}>
                <div>Проблема со списанием денег</div>
                <div className={s.item_icon}>
                  <RightTrigger />
                </div>
              </NavLink> */}
              <a
                href={outletParams.phone ? `tel:${outletParams.phone}` : 'https://t.me/MOYsupport'}
                className={s.list_item}
              >
                <div>Связаться с мойкой</div>
                <div className={s.item_icon}>
                  <RightTrigger />
                </div>
              </a>
              {/* <NavLink to="#" className={s.list_item}>
                <div>Оставить отзыв о мойщике</div>
                <div className={s.item_icon}>
                  <RightTrigger />
                </div>
              </NavLink> */}
              <a href="https://t.me/MOYsupport" className={s.list_item}>
                <div>Если что-то сломалось</div>
                <div className={s.item_icon}>
                  <RightTrigger />
                </div>
              </a>
            </div>
          </div>
        </>
      )}
    </PageLayout>
  )
}

export default React.memo(SupportPage)
