import React, { useCallback, useState } from 'react'
import { Button } from 'application/presentation/common/uiComponents'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import { useNavigate } from 'react-router'

import s from './CancelOrderPage.module.scss'

const CancelOrderPage = () => {
  const navigate = useNavigate()
  const [comment, setComment] = useState<string>('')

  const onCompleteBtnClickHandler = useCallback(() => {
    navigate('/booking')
  }, [navigate])

  return (
    <div className={s.container}>
      <div className={s.inner_container}>
        <div className={s.title}>Ваш заказ отменен!</div>
        <div className={s.subtitle}>С вашего баланса спишется сумма в 200 ₽ </div>
        <UniversalInputComponent
          type="textarea"
          value={comment}
          onChange={setComment}
          placeholder="Опишите причину отмены заказа"
          inputContainerClassName={s.input_container}
        />
        <Button
          text="Готово"
          onClick={onCompleteBtnClickHandler}
          color="fiol"
          containerClassName={s.support_btn}
        />
      </div>
    </div>
  )
}

export default React.memo(CancelOrderPage)
