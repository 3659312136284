import {
  createBookingLocksRequest,
  fetchBookingLocks,
  removeBookingLocksRequest,
} from 'application/core/data/api/booking'
import queryClient from 'application/core/data/apiClient/queryClient'
import { useMutation, useQuery } from '@tanstack/react-query'
import _cloneDeep from 'lodash/cloneDeep'
import _filter from 'lodash/filter'
import { BookingLock } from 'application/core/domain/entity/booking/Booking'

export const useBookingLocks = (outlet_id: number) => {
  const query = useQuery(['booking_locks', outlet_id], () => fetchBookingLocks(outlet_id), {
    enabled: !!outlet_id,
    refetchInterval: 5000,
  })
  return { ...query, isLoading: outlet_id ? query.isLoading : false }
}

export const createBookingLocks = createBookingLocksRequest
export const removeBookingLocks = removeBookingLocksRequest

type CreateBookingLocksMutationType = {
  booking_date: string
  booking_status: number
  outlet_area_table_id: number
  outletId: number
}

export const CreateBookingLocksMutation = (callback?: () => void) => {
  const mutation = useMutation(
    (sendData: CreateBookingLocksMutationType) => createBookingLocksRequest(sendData),
    {
      onMutate: async ({
        outlet_area_table_id,
        outletId,
        booking_date,
      }: CreateBookingLocksMutationType) => {
        await queryClient.cancelQueries(['booking_locks'])
        const previousData = queryClient.getQueryData<any>(['booking_locks', outletId])

        if (previousData) {
          let newData: any = _cloneDeep(previousData)

          const newItem: BookingLock = {
            id: 0,
            booking_date: booking_date,
            production_time: '10',
            table: {
              id: outlet_area_table_id,
              number: 0,
              outlet_area_id: outletId,
              total_seats: 1,
              created_at: null,
              updated_at: null,
              status: 0,
            },
          }
          newData.push(newItem)

          queryClient.setQueryData(['booking_locks', outletId], newData)

          if (callback) {
            callback()
          }
        }

        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['booking_locks', variables.outletId], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['booking_locks'])
      },
    },
  )
  return mutation
}

export const RemoveBookingLocksMutation = (callback?: () => void) => {
  const mutation = useMutation((sendData: BookingLock) => removeBookingLocksRequest(sendData.id), {
    onMutate: async ({ table, id }: BookingLock) => {
      await queryClient.cancelQueries(['booking_locks'])
      const previousData = queryClient.getQueryData<any>(['booking_locks', table.outlet_area_id])

      if (previousData) {
        let newData: any = _cloneDeep(previousData)
        newData = _filter(newData, (item) => item.id !== id)

        queryClient.setQueryData(['booking_locks', table.outlet_area_id], newData)

        if (callback) {
          callback()
        }
      }

      return { previousData }
    },
    onError: (err, variables, context: any) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ['booking_locks', variables.table.outlet_area_id],
          context.previousData,
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['booking_locks'])
    },
  })
  return mutation
}
