import { Car } from 'application/core/domain/entity/car/Car'

export const formatCarNumber = (car: Car) => {
  const splittedNumber = car.number.split('')
  splittedNumber[0] = splittedNumber[0] + ' '
  splittedNumber[3] = splittedNumber[3] + ' '
  const formated = splittedNumber.join('')
  return `${formated} ${car.regionNumber}`.toUpperCase()
}

export const formatStringToCarNumber = (carNumber: string) => {
  const formattedNumber = carNumber.replace(/ /g, '')
  const n = formattedNumber.length === 9 ? 3 : 2
  const region = formattedNumber.substring(formattedNumber.length - n)
  const number = formattedNumber.slice(0, formattedNumber.length - n)

  const splittedNumber = number.split('')
  splittedNumber[0] = splittedNumber[0] + ' '
  splittedNumber[3] = splittedNumber[3] + ' '
  const formated = splittedNumber.join('')
  return `${formated} ${region}`.toUpperCase()
}

export const formatStringToNumberAndRegion = (carNumber: string) => {
  const formattedNumber = carNumber.replace(/ /g, '')
  const n = formattedNumber.length === 9 ? 3 : 2
  const region = formattedNumber.substring(formattedNumber.length - n)
  const number = formattedNumber.slice(0, formattedNumber.length - n)
  return { region, number }
}
