import { getOrderNewStatus } from 'application/core/domain/useCase/orders/getOrderStatus'
import { Order } from 'application/core/domain/entity/order/Order'

import apiClient from '../apiClient'

export const fetchOrdersHistory = async () => {
  const { data } = await apiClient.get('/api/history')
  return data
}

export const fetchActuallyOrders = async () => {
  const { data } = await apiClient.post('/api/actually')
  return data
}

export const fetchOrder = async (id: number, isOpenOrder: boolean) => {
  const { data } = await apiClient.get(
    `/api/orders?${isOpenOrder ? 'open_order_id' : 'order_id'}=${id}`,
  )
  return data
}

export const fetchLastOrders = async () => {
  const { data } = await apiClient.get('/api/orders/getLastOrders')
  return data
}

export const createBaristaOrderRequest = async (sendData: any) => {
  const { data } = await apiClient.post('/api/barista/sendDistanceOrder', sendData)
  return data
}

export const rateOrderRequest = async (order_id: number, rating: number) => {
  const { data } = await apiClient.post('/api/rating', {
    order_id: order_id,
    rating: rating,
  })
  return data
}

export const changeOrderStatusRequest = async (open_order: Order) => {
  const newStatus = getOrderNewStatus(open_order.status)
  const id = open_order.open_order_id ? open_order.open_order_id : open_order.id
  const { data } = await apiClient.post(
    `/api/orders/changeStatusOpenOrders${newStatus.rusStatus === 'Завершен' ? `?o_id=${id}` : ''}`,
    {
      open_order: {
        ...open_order,
        id: id,
        status: newStatus.rusStatus,
      },
    },
  )
  return data
}

export const updateOrderItemRequest = async (orderItemId: number, method: string) => {
  const { data } = await apiClient.post('/api/orders/updateLastOrder', {
    orderItemId: orderItemId,
    order_method: method,
  })
  return data
}

export const cancelOpenOrderRequest = async (sendData: {
  open_order_id: number
  comment: string
}) => {
  const { data } = await apiClient.post('/api/orders/open/cancel', sendData)
  return data
}
