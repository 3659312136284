const getAppLocalStorage = (): Storage => {
  return window.localStorage
}

const getAppSessionStorage = (): Storage => {
  return window.sessionStorage
}

export const appSessionStorage = getAppSessionStorage()
const appLocalStorage = getAppLocalStorage()

export default appLocalStorage
