import React, { useCallback, useState, useEffect } from 'react'
import FormContainer from 'application/presentation/common/FormContainer'
import {
  CodeInput,
  UniversalInputComponent,
} from 'application/presentation/common/uiComponents/InputComponents'
import { Button } from 'application/presentation/common/uiComponents'
import { useLocation, useNavigate } from 'react-router'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { useAppSelector } from 'application/core/domain/store/hooks'
import PinCodeInput from 'application/presentation/common/uiComponents/InputComponents/PinCodeInput/PinCodeInput'
import { reachGoalSmsInput } from 'application/core/utils/metrika/yandexReachGoals'

import s from './AuthPage.module.scss'
import { useAuthorization } from './useAuthorization'

const AuthPage = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { search } = useLocation()
  const navigate = useNavigate()
  const [stage, setStage] = useState<number>(1)
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfim, setPasswordConfirm] = useState<string>('')
  const [name, setName] = useState<string>('')

  const {
    sendCode,
    errMsg,
    regIsLoading,
    phoneIsFull,
    checkIsLoading,
    checkPhone,
    isRegistered,
    btnHidden,
    authorize,
    checkCode,
    register,
  } = useAuthorization({ setStage })

  const onNextClickHandler = useCallback(
    (stageNum: number) => {
      if (stageNum === 6) {
        navigate('/select_car')
      } else {
        setStage(stageNum)
      }
    },
    [navigate],
  )

  const send = useCallback((passReset?: boolean) => sendCode(phone, passReset), [phone, sendCode])

  const registerClickHandler = useCallback(() => {
    if (password !== passwordConfim) {
      return ToastNotify('Пин-коды не совпадают')
    }
    return register(phone, password, () => {
      ToastNotify('Регистрация прошла успешно')
      navigate('/select_car')
    })
  }, [phone, password, passwordConfim, navigate, register])

  const auth = useCallback(
    () =>
      authorize(phone, password, () => {
        const params = new URLSearchParams(search)
        navigate(params.get('from_create_order') === 'true' ? '/create_order' : '/booking', {
          replace: true,
        })
        ToastNotify('Авторизация прошла успешно')
      }),
    [phone, password, authorize, navigate, search],
  )

  useEffect(() => {
    if (code.length === 4) {
      reachGoalSmsInput()
    }
  }, [code])

  useEffect(() => {
    checkPhone(phone)
  }, [phone, checkPhone])

  useEffect(() => {
    if (code && phone) {
      checkCode(phone, code, () => {
        setStage(3)
      })
    }
  }, [checkCode, code, phone])

  useEffect(() => {
    if (user) {
      navigate('/booking', { replace: true })
    }
  }, [user, navigate])

  return (
    <div className={s.container}>
      {stage === 1 && (
        <FormContainer
          title="Введите номер телефона чтобы начать"
          subtitle="Введите номер телефона"
        >
          <UniversalInputComponent
            type="phone"
            value={phone}
            onChange={setPhone}
            placeholder="Номер телефона"
            inputContainerClassName={s.input_container}
          />
          {phoneIsFull && (
            <Button
              color="fiol"
              text={isRegistered ? 'Далее' : 'Отправить код'}
              onClick={() => (isRegistered ? onNextClickHandler(5) : send())}
              isLoading={regIsLoading || checkIsLoading}
              containerClassName={s.btn}
            />
          )}
        </FormContainer>
      )}
      {stage === 2 && (
        <FormContainer title="Вам отправлен смс код" subtitle="Введите его сюда">
          <CodeInput onChange={setCode} phone={phone} />
          {/* <Button
            color="fiol"
            text="Далее"
            onClick={() => onNextClickHandler(3)}
            containerClassName={s.btn}
          /> */}
        </FormContainer>
      )}
      {stage === 3 && (
        <FormContainer title="Придумайте Ваш пин-код" subtitle="Введите его сюда">
          <PinCodeInput onChange={setPassword} controlled />
          {password.length === 4 && (
            <Button
              color="fiol"
              text="Далее"
              onClick={() => onNextClickHandler(4)}
              containerClassName={s.btn}
            />
          )}
        </FormContainer>
      )}
      {stage === 4 && (
        <FormContainer title="Повторите Ваш пин-код" subtitle="Введите его сюда">
          <PinCodeInput onChange={setPasswordConfirm} controlled />
          {passwordConfim.length === 4 && (
            <Button
              color="fiol"
              text="Далее"
              onClick={registerClickHandler}
              containerClassName={s.btn}
              isLoading={regIsLoading}
            />
          )}
        </FormContainer>
      )}
      {stage === 5 && (
        <FormContainer title="Введите ваш пин-код" subtitle="Введите его сюда">
          <PinCodeInput onChange={setPassword} controlled />

          <div className={s.password_recovery} onClick={() => send(true)}>
            Восстановить пин код?
          </div>
          {password.length === 4 && (
            <Button
              color="fiol"
              text="Далее"
              onClick={auth}
              containerClassName={s.btn}
              isLoading={regIsLoading}
            />
          )}
        </FormContainer>
      )}
      {/* {stage === 5 && (
        <FormContainer title="Как к Вам обращаться?" subtitle="Введите свое имя">
          <UniversalInputComponent
            type="text"
            value={name}
            onChange={setName}
            placeholder="Введите имя"
            inputContainerClassName={s.input_container}
          />
          <Button
            color="fiol"
            text="Далее"
            onClick={() => onNextClickHandler(6)}
            containerClassName={s.btn}
          />
        </FormContainer>
      )} */}
    </div>
  )
}

export default React.memo(AuthPage)
