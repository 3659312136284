import _map from 'lodash/map'
import _filter from 'lodash/filter'

import apiClient from '../apiClient'
export const createClientConfigsRequest = async (product: string) => {
  const { data } = await apiClient.post('/api/clients_configs', { value: product })
  return data
}

export const getClientConfigsRequest: any = async (
  filterType?: 'car' | 'client_favirite_product' | 'coffe',
) => {
  const { data } = await apiClient.get('/api/clients_configs')

  if (data) {
    const result = _map(data, (item: any) => {
      const parsedData = JSON.parse(item.value)
      if (parsedData.modelName) {
        return {
          id: item.id,
          client_id: item.client_id,
          car: JSON.parse(item.value),
        }
      }
      if (parsedData.config_type && parsedData.config_type === 'client_favorite_product') {
        return {
          id: item.id,
          client_id: item.client_id,
          client_favorite_product: JSON.parse(item.value),
        }
      }
      return {
        id: item.id,
        client_id: item.client_id,
        product: JSON.parse(item.value),
      }
    })
    return filterType
      ? _filter(result, (item) => {
          switch (filterType) {
            case 'car':
              return item.car ? true : false
            case 'client_favirite_product':
              return item.client_favorite_product ? true : false
            case 'coffe':
              return item.product ? true : false
            default:
              return true
          }
        })
      : result
  }
  return undefined
}

export const deleteClientConfigsRequest = async (id: number) => {
  const { data } = await apiClient.delete(`/api/clients_configs/${id}`)
  return data
}
