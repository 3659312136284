/* eslint-disable @typescript-eslint/naming-convention */
import { Loader } from 'application/presentation/common/uiComponents'
import React, { useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import MenuNavigation from 'application/presentation/navigation/MenuNavigation'
import { useDispatch } from 'react-redux'
import { ToastContainer, Zoom } from 'react-toastify'
import apiClient from 'application/core/data/apiClient'
import { Helmet } from 'react-helmet'
import { setBasket, setBookingData } from 'application/core/domain/store/basketReducer'
import {
  setOutlet,
  setUser,
  setUserRegion,
  setUserToken,
} from 'application/core/domain/store/userReducer'
import { getOutlets } from 'application/core/domain/useCase/outlets/getOutlets'
import _ from 'lodash'
import { getUserPaymentCards } from 'application/core/domain/useCase/payments/userPaymentsCard'
import { setPayMethod } from 'application/core/domain/store/paymentReducer'
import Header from 'application/presentation/common/Header'
import { setCar } from 'application/core/domain/store/carsReducer'
import { getClientConfigs } from 'application/core/domain/useCase/client_configs/clientConfigsActions'
import OutletsMW from 'application/presentation/common/ModalWindows/OutletsMW/OutletsMW'

import s from './AppView.module.scss'

const AppView = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const bootstrapAsync = async () => {
      try {
        if (window.ENV && window.ENV.url) {
          apiClient.defaults.baseURL = window.ENV.url
        }
        const urlParams = new URLSearchParams(window.location.search)
        const outletId = urlParams.get('outlet_id')
        const localOutletId = sessionStorage.getItem('outlet_id')

        const appConfigOutletId = window.ENV && window.ENV.outletId ? window.ENV.outletId : 0

        const basket = localStorage.getItem('basketData')
        let token = localStorage.getItem('user_token')
        const user_region = localStorage.getItem('user_region')
        const booking_data = localStorage.getItem('bookingData')
        const currentCar = localStorage.getItem('currentCar')
        let outlet_id = appConfigOutletId || outletId || localOutletId

        if (user_region) {
          setUserRegion(JSON.parse(user_region))(dispatch)
        }
        if (booking_data) {
          setBookingData(JSON.parse(booking_data))(dispatch)
        }

        // TODO: че я за хуйню написал ниже?
        if (!appConfigOutletId && outletId) {
          if (outletId && outletId !== localOutletId) {
            sessionStorage.setItem('outlet_id', outletId)
          }
        }
        const outlets = await getOutlets(outlet_id ? parseInt(`${outlet_id}`) : 0)
        if (outlets && outlet_id) {
          const o = _.find(outlets.outlets ? outlets.outlets : outlets, {
            id: parseInt(`${outlet_id}`, 10),
          })
          setOutlet(o)(dispatch)
        }
        if (basket) {
          try {
            const basketDat = JSON.parse(basket)
            setBasket(basketDat)(dispatch)
          } catch (e) {
            setBasket([])(dispatch)
          }
        }
        if (token) {
          try {
            const { data } = await apiClient.get('/api/user', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            setUser(data && data.data ? data.data : null)(dispatch)
            setUserToken(token)(dispatch)
            // @ts-ignore
            apiClient.defaults.headers.Authorization = `Bearer ${token}`
            const last_pay_method = localStorage.getItem('last_pay_method')
            if (last_pay_method && parseInt(last_pay_method, 10) > 5) {
              const cards = await getUserPaymentCards()
              const card = _.find(cards, { id: parseInt(last_pay_method, 10) })
              if (card) {
                setPayMethod(card.id)(dispatch)
              }
            }
            const clientConfigs = await getClientConfigs()
            if (currentCar) {
              const car = JSON.parse(currentCar)
              if (clientConfigs && clientConfigs.length) {
                const actuallyCar = _.find(
                  clientConfigs,
                  (item) => item && item.car && item.car.id === car.id,
                )
                if (actuallyCar) {
                  setCar(actuallyCar.car)(dispatch)
                } else {
                  setCar(clientConfigs[0].car)(dispatch)
                }
              }
            } else if (clientConfigs && clientConfigs.length) {
              setCar(clientConfigs[0].car)(dispatch)
            }
          } catch (e) {
            localStorage.removeItem('user_token')
            token = null
            console.log(e)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    bootstrapAsync().finally(() => {
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {window.ENV && window.ENV.metaTitle
            ? window.ENV.metaTitle
            : 'Электронное меню - MoyService'}
        </title>
        <meta
          name="description"
          content={
            window.ENV && window.ENV.metaDescription
              ? window.ENV.metaDescription
              : 'Электронное меню - MoyService'
          }
        />
      </Helmet>
      {isLoading ? (
        <Loader size={64} />
      ) : (
        <>
          <Header />
          <div className={s.app_container}>
            <Routes>
              <Route path="/*" element={<MenuNavigation />} />
            </Routes>
          </div>
          <OutletsMW />
          <Portal>
            <ToastContainer transition={Zoom} limit={1} autoClose={100} />
          </Portal>
        </>
      )}
    </>
  )
}

export default React.memo(AppView)
