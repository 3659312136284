import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'

import MWContainer from '../MWContainer'
import SelectCarForm from '../../SelectCarForm/SelectCarForm'

import s from './AddOrEditCarMW.module.scss'

const ProductInfoMW = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('add_car') !== -1
  }, [location])

  const onAddCar = useCallback(() => navigation(-1), [navigation])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (location.hash.indexOf('add_car') !== -1) {
          navigation(-1)
        }
      }}
    >
      <div className={s.container}>
        <SelectCarForm onAddCar={onAddCar} />
      </div>
    </MWContainer>
  )
}

export default React.memo(ProductInfoMW)
