/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import apiClient from 'application/core/data/apiClient'
import { PaymentDataProps, PaymentProps } from 'application/core/domain/types/PaymentsType'

const bankPay = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  onStart()

  bankPayProcess('', data, onSuccess, onError)
}

const bankPayProcess = (
  cryptogram: any,
  payData: PaymentDataProps,
  onSuccess: Function,
  onError: Function,
) => {
  const { amount, order_id, selected_pay_method, returnUrl } = payData
  const data = {
    amount,
    open_order_id: order_id,
    type: 'sberpay-card',
    return_url: returnUrl || 'dondonuts://',
  }

  //Немного хардкода
  if (selected_pay_method >= 6) {
    //@ts-ignore
    data.bind_id = selected_pay_method
    //@ts-ignore
    data.features = 'AUTO_PAYMENT'
  } else {
    //@ts-ignore
    data.auto_payment = true
  }
  apiClient({
    method: 'POST',
    url: '/api/payment',
    data: data,
  })
    .then((response) => {
      if (response.data.formUrl) {
        onSuccess('3ds', response.data)
      } else if (response.data.redirect) {
        onSuccess('waiting')
      } else if (response.data.errorTypeName) {
        onError('error', response.data.errorMessage)
      }
    })
    .catch(({ response }: any) => {
      const error =
        response && response.data
          ? response.data.errorTypeName
            ? response.data.errorMessage
            : response.data.message
            ? response.data.message
            : 'Произошла ошибка при совершении оплаты'
          : 'Произошла ошибка при совершении оплаты'
      onError('error', error)
    })
}

export const tipsPayProcess = ({ data: payData, onStart, onSuccess, onError }: PaymentProps) => {
  const { tips_id, selected_pay_method } = payData
  const data = {
    type_id: tips_id,
    type: 'tips',
  }
  //Немного хардкода
  if (selected_pay_method >= 6) {
    //@ts-ignore
    data.bind_id = selected_pay_method
    //@ts-ignore
    data.features = 'AUTO_PAYMENT'
  } else if (selected_pay_method === 5) {
    //@ts-ignore
    data.method = 'sberpay'
  } else {
    //@ts-ignore
    data.auto_payment = true
  }
  if (onStart) {
    onStart()
  }
  apiClient({
    method: 'POST',
    url: '/api/pay',
    data: data,
  })
    .then((response) => {
      if (response.data.formUrl) {
        onSuccess('3ds', response.data)
      } else if (response.data.redirect) {
        onSuccess('waiting', response.data)
      }
    })
    .catch(({ response }: any) => {
      onError(
        'error',
        response && response.data && response.data.message
          ? response.data.message
          : 'Произошла ршибка при совершении оплаты',
      )
    })
}

export default bankPay
