import { reportMetrikaEvent } from './yandexMetrika'

const reachGoal = (goal: string) => {
  try {
    // @ts-ignore
    if (typeof document !== 'undefined') {
      console.log('reachGoal => ', goal)
      //@ts-ignore
      return ym(94458073, 'reachGoal', goal)
    }
    return reportMetrikaEvent(goal)
  } catch (e) {
    return null
  }
}

export const reachGoalSmsInput = () => reachGoal('Sms')

export const reachGoalPhoneInput = () => reachGoal('Code')

export const reachGoalAddCar = () => reachGoal('Auto')

export const reachGoalSelectBookingTime = () => reachGoal('Time')

export const reachGoalCreateOrderClick = () => reachGoal('Payment')

export const reachGoalCancelOrderClick = () => reachGoal('Cancel')

export const reachGoalRouteClick = () => reachGoal('Route')

export const reachGoalWriteMoreClick = () => reachGoal('Write more')

export const reachGoalOrderEnd = () => reachGoal('End')

export const reachGoalPartnersClick = () => reachGoal('Partners')
