import ToastNotify from 'application/presentation/context/ToastNotify'
import React, { useEffect, useState } from 'react'
import VerificationInput from 'react-verification-input'
import apiClient from 'application/core/data/apiClient/apiClient'

import s from './CodeInput.module.scss'

const CELL_COUNT = 4

type Props = {
  title?: string
  phone?: string
  onSuccess?: Function
  onFocus?: () => void
  onBlur?: () => void
  controlled?: boolean
  onChange?: Function
  containerClassName?: string
}

const CodeInput = ({
  phone,
  onSuccess,
  onBlur,
  onFocus,
  controlled,
  onChange,
  title,
  containerClassName,
}: Props) => {
  const [code, setCode] = useState<string>('')

  useEffect(() => {
    return () => {
      if (onBlur) {
        onBlur()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (code.length === CELL_COUNT && !controlled) {
      apiClient
        .post('/api/attempt?return_token=true', {
          phone: phone ? phone.replace(/[^0-9]/g, '') : '',
          pin: code,
        })
        .then((response: any) => {
          if (response.data && response.data.data) {
            if (onSuccess) {
              onSuccess(response.data.data)
            }
          }
        })
        .catch((error: any) => {
          ToastNotify(
            error && error.response && error.response.data && error.response.data.errors
              ? error.response.data.errors
              : 'Произошла ошибка',
          )
        })
    }
  }, [code, phone, onSuccess, controlled])
  return (
    <div className={`${s.sms_code_block} ${containerClassName || ''}`}>
      {title ? <div className={s.title}>{title}</div> : <></>}
      <VerificationInput
        length={CELL_COUNT}
        onFocus={onFocus}
        onBlur={onBlur}
        passwordMode
        classNames={{
          container: `${s.sms_code}`,
          character: `${s.number_input}`,
          characterInactive: 'character--inactive',
          characterSelected: `${s.selected}`,
        }}
        placeholder=""
        inputProps={{
          id: 'single-factor-code-text-field',
          autoComplete: 'one-time-code',
        }}
        validChars="0-9"
        onChange={(val: any) => {
          setCode(val)
          if (onChange) {
            onChange(val)
          }
          //   setSmsStatus('')
        }}
      />
    </div>
  )
}

export default React.memo(CodeInput)
