import moment from 'moment'

export const millisToMinutesAndSeconds = (millis: number) => {
  let seconds = Math.floor(millis / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  minutes = minutes % 60
  return {
    hours: hours.toString(),
    minutes: minutes.toString(),
    seconds: (parseInt(`${seconds}`, 10) < 10 ? '0' : '') + seconds,
  }
}

export const getTimeDiff = (startTime: string, productionTime: number) => {
  const now = moment()
  const start = moment(startTime)
  const end = moment(startTime).add(productionTime, 'seconds')
  if (now.isBetween(start, end)) {
    const diff = end.valueOf() - now.valueOf()
    return millisToMinutesAndSeconds(diff)
  }
  if (now.isBefore(start)) {
    const diff = start.valueOf() - now.valueOf()
    return { ...millisToMinutesAndSeconds(diff), isBefore: true }
  }
  if (now.isAfter(end)) {
    const diff = now.valueOf() - end.valueOf()
    return { ...millisToMinutesAndSeconds(diff), isAfter: true }
  }
  return {
    minutes: '0',
    seconds: '0',
    hours: '0',
  }
}
