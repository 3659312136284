import apiClient from 'application/core/data/apiClient/apiClient'
import { AuthContext } from 'application/presentation/context/Authorization'
import { reachGoalPhoneInput } from 'application/core/utils/metrika/yandexReachGoals'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

type Props = {
  setSeconds?: Function
  setStage?: Function
}

export const useAuthorization = ({ setSeconds, setStage }: Props) => {
  const navigation = useNavigate()
  const authContext = useContext(AuthContext)
  const timer = useRef<any>()

  const [regIsLoading, setRegIsLoading] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState<string>('')
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [phoneIsFull, setPhoneIsFull] = useState<boolean>(false)
  const [checkIsLoading, setCheckIsLoading] = useState<boolean>(false)
  const [btnHidden, setBtnHidden] = useState<boolean>(false)

  const startTimer = useCallback(() => (setSeconds ? setSeconds(60) : {}), [setSeconds])

  const sendCode = useCallback(
    (phone: string, passReset?: boolean) => {
      if (passReset) {
        return authContext.sendPassResetCode({
          data: {
            phone: phone,
          },
          onStart: () => setRegIsLoading(true),
          onSuccess: () => {
            // setStage(4)
            navigation(`/password_reset?phone=${phone}`)
            setRegIsLoading(false)
            startTimer()
          },
          onError: (msg: string) => {
            setErrMsg(msg)
            setRegIsLoading(false)
          },
        })
      } else {
        authContext.sendCode({
          data: {
            phone: phone,
          },
          onStart: () => setRegIsLoading(true),
          onSuccess: () => {
            if (setStage) {
              setStage(2)
            }
            // navigation(`/registration?phone=${phone}`)
            setRegIsLoading(false)
            startTimer()
          },
          onError: (msg: string) => {
            setErrMsg(msg)
            setRegIsLoading(false)
          },
        })
      }
    },
    [authContext, navigation, startTimer, setStage],
  )

  const checkPhone = useCallback((phone: string) => {
    const formatted = phone.replace(/[^0-9]/g, '')
    if (formatted.length === 11) {
      reachGoalPhoneInput()
      setPhoneIsFull(true)
      setCheckIsLoading(true)
      apiClient
        .post('/api/isUser', {
          phone: phone,
        })
        .then(({ data }: any) => {
          if (data && data.data.is_user) {
            setIsRegistered(data.data.is_user)
          } else {
            setIsRegistered(false)
          }
          setCheckIsLoading(false)
        })
    } else {
      setPhoneIsFull(false)
    }
  }, [])

  // useEffect(() => {
  //   const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
  //     setBtnHidden(true)
  //   })
  //   const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
  //     setBtnHidden(false)
  //   })
  //   return () => {
  //     if (showSubscription && hideSubscription) {
  //       showSubscription.remove()
  //       hideSubscription.remove()
  //     }
  //   }
  // }, [])

  const authorize = useCallback(
    (phone: string, password: string, callback: Function) => {
      setRegIsLoading(true)
      authContext.login({
        data: {
          phone: phone,
          password,
        },
        onStart: () => {},
        onSuccess: () => {
          setRegIsLoading(false)
          requestAnimationFrame(() => callback())
          // InteractionManager.runAfterInteractions(() => callback())
        },
        onError: (msg: string) => {
          setErrMsg(msg)
          setRegIsLoading(false)
        },
      })
    },
    [authContext],
  )

  const register = useCallback(
    (phone: string, password: string, callback: Function) => {
      setRegIsLoading(true)
      authContext.registration({
        data: {
          phone: phone,
          password,
        },
        onStart: () => {},
        onSuccess: () => {
          //   toast.show('Регистрация прошла успешно')
          //   navigation.goBack()
          setRegIsLoading(false)
          requestAnimationFrame(() => callback())
          // InteractionManager.runAfterInteractions(() => callback())
        },
        onError: (msg: string) => {
          setErrMsg(msg)
          setRegIsLoading(false)
        },
      })
    },
    [authContext],
  )

  const changePassword = useCallback(
    (
      phone: string,
      password: string,
      confirmPassword: string,
      code: string,
      callback: Function,
    ) => {
      // if (password.length < 8) {
      //   return setErrMsg('Пароль должен быть более 8 символов')
      // }
      if (password !== confirmPassword) {
        return setErrMsg('Пин коды не совпадают')
      }
      authContext.changePassword({
        data: {
          phone: phone,
          password,
          pin: code,
        },
        onStart: () => {},
        onSuccess: () => {
          authorize(phone, password, callback)
        },
        onError: (msg: string) => {
          setErrMsg(msg)
          setRegIsLoading(false)
        },
      })
    },
    [authContext, authorize],
  )

  const checkCode = useCallback(
    (phone: string, code: string, callback: Function) => {
      if (code.length === 4) {
        authContext.checkCode({
          data: {
            phone: phone,
            pin: code,
          },
          onStart: () => setCheckIsLoading(true),
          onSuccess: () => {
            //   setStage(3)
            setCheckIsLoading(false)
            requestAnimationFrame(() => callback())
            // InteractionManager.runAfterInteractions(() => callback())
          },
          onError: (msg: string) => {
            setErrMsg(msg)
            setCheckIsLoading(false)
          },
        })
      } else {
        setErrMsg('Введите все 4 цифры')
      }
    },
    [authContext],
  )

  return {
    timer,
    sendCode,
    errMsg,
    regIsLoading,
    isRegistered,
    checkIsLoading,
    checkPhone,
    phoneIsFull,
    btnHidden,
    authorize,
    register,
    changePassword,
    checkCode,
  }
}
