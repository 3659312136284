import React, { useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useOrder } from 'application/core/domain/useCase/orders/getOrders'

import MWContainer from '../MWContainer'
import { Loader } from '../../uiComponents'

import s from './OrderDetailsMW.module.scss'
import OrderDetailsContent from './OrderDetailsContent'

const OrderDetailsMW = () => {
  const { hash, search } = useLocation()
  const params = useParams()
  const navigation = useNavigate()

  const isOpenOrder = useMemo(() => {
    const p = new URLSearchParams(search)
    return p.get('is_open_order') ? true : false
  }, [search])

  const orderId = useMemo(() => {
    return params && params.order_id ? parseInt(params.order_id, 10) : 0
  }, [params])

  const opened = useMemo(() => {
    return orderId && hash.indexOf('details') !== -1 ? true : false
  }, [orderId, hash])

  const { data, isLoading } = useOrder(orderId, isOpenOrder)

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (orderId) {
          navigation(-1)
        }
      }}
    >
      <div className={s.container}>
        {isLoading ? (
          <Loader size={32} />
        ) : data ? (
          <OrderDetailsContent data={data} />
        ) : (
          <div>Заказ не найден</div>
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(OrderDetailsMW)
