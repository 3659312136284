/* eslint-disable @typescript-eslint/naming-convention */
import {
  fetchCalculatedBaristaBasket,
  fetchCalculatedBasket,
} from 'application/core/data/api/basket'
import { Product } from 'application/core/domain/entity/product/Product'
import {
  ProductOption,
  ProductOptionItem,
} from 'application/core/domain/entity/product/ProductOption'
import { AxiosRequestConfig } from 'axios'
import _ from 'lodash'

import { getSelectedAdditionasIds } from '../products/productsAction'

export const calculateBasket = async (
  products: Array<any>,
  outlet_id: number,
  config?: AxiosRequestConfig,
) => {
  return fetchCalculatedBasket(products, outlet_id, config)
}

export const calculateBaristaBasket = async ({
  sendData,
  onSuccess,
  onError,
}: {
  sendData: {
    products: Array<any>
    outlet_id: number
    client_id: number
  }
  onSuccess: Function
  onError: Function
}) => {
  try {
    const data = await fetchCalculatedBaristaBasket(sendData)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const mergeBasketData = (currentBasket: any, calculatedBasket: any) => {
  if (calculatedBasket.length && currentBasket.length) {
    const temp = _.cloneDeep(currentBasket)

    return temp.map((item: any) => {
      const f = _.find(calculatedBasket, (calc_item) => calc_item.uniq_id === item.uniq_id)
      if (f) {
        item.full_cost = f.full_cost
        item.product_name = f.product_name
        if (f.temperature) {
          item.temperature = f.temperature
        }
        if (f.availability_status) {
          item.availability_status = f.availability_status
        }
        if (f.options) {
          item.options.map((o: ProductOption) => {
            o.items.map((v) => {
              f.options.map((new_o: ProductOptionItem) => {
                if (new_o.id == v.variety_id) {
                  v.availability_status = new_o.availability_status
                }
              })
              return v
            })
            return item
          })
        }
        // if (f.variety) {
        //   item.variety = f.variety
        // }
        item.discont = f.discont || 0
        if (item.variety && item.variety.cost) {
          item.variety.cost = f.variety.cost
          item.variety.availability_status = f.variety.availability_status
          item.variety.name = f.variety.name
        } else {
          item.variety = f.variety
        }
      }
      return item
    })
  }
  return currentBasket
}

export const checkIsSomethingNotInStock = (data: Array<Product>) => {
  const finded = _.find(data, (p) => {
    return p.availability_status === 'not_available' || p.availability_status === 'ended'
  })
  if (finded) {
    return true
  } else {
    let optionNotAvailable = false
    data.map((item: Product) => {
      if (item.varieties) {
        const f = _.find(item.varieties, (v) => {
          return (
            v.selected === true &&
            (v.availability_status === 'not_available' || v.availability_status === 'ended')
          )
        })
        if (f) {
          optionNotAvailable = true
        }
      }
      if (item.options) {
        item.options.map((o: ProductOption) => {
          const f = _.find(o.items, (v) => {
            return (
              v.selected === true &&
              (v.availability_status === 'not_available' || v.availability_status === 'ended')
            )
          })
          if (f) {
            optionNotAvailable = true
          }
        })
      }
    })
    return optionNotAvailable ? true : false
  }
}

const isEqual = (a: Product, b: Product) => {
  return (
    _.isEqual(a.product_name, b.product_name) &&
    _.isEqual(a.varieties, b.varieties) &&
    _.isEqual(a.selected_options, b.selected_options) &&
    a.temperature === b.temperature &&
    (a.variety && b.variety ? a.variety.id === b.variety.id : false)
  )
}

export const groupBasketData = (basket: any) => {
  const currentBasket = _.cloneDeep(basket)
  const withDiscont = _.filter(currentBasket, (i) => i.discont && i.discont > 0)
  const withoutDiscont = _.filter(currentBasket, (i) => !i.discont || i.discont === 0)
  const uniq = _.uniqWith(withoutDiscont, (a: Product, b: Product) => {
    return isEqual(a, b)
  })
  const uniq_items = uniq.map((item) => {
    let c = 0
    let sum = 0
    let uniq_ids: Array<string> = []
    withoutDiscont.map((basketItem: Product) => {
      if (isEqual(item, basketItem)) {
        c = c + 1
        sum = sum + basketItem.full_cost
        uniq_ids.push(basketItem.uniq_id)
      }
    })
    return { ...item, count: c, uniq_ids, full_cost: sum }
  })
  return withDiscont && withDiscont.length ? _.concat(withDiscont, uniq_items) : uniq_items
}

export const getBasketToSend = (basketData: any) => {
  return basketData
    ? basketData.map((item: any) => {
        const volume = _.find(item.varieties, { selected: true })
        return {
          id: item.id,
          uniq_id: item.uniq_id,
          discont: item.discont ? item.discont : 0,
          variety_id: volume ? volume.variety_id : null,
          options: getSelectedAdditionasIds(item),
        }
      })
    : null
}

export const getEditAdditionalsData = (product: Product) => {
  return product.options.map((item: any) => {
    if (item.selected) {
      const t = [true]
      item.items.forEach((opt: any) => {
        if (opt.selected) {
          t.push(opt.id)
        }
      })

      return { ...item, selected: t }
    }
    return { ...item, selected: [false] }
  })
}

export const recalculateBasketDiscont = (basketData: Product[]) => {
  let c = 0
  if (basketData && basketData.length) {
    basketData.forEach((item: any) => {
      const volume = _.find(item.varieties, { selected: true })
      if ((volume && volume.variety_id === 728) || (volume && volume.variety_id === 1891)) {
        c = c + 1
      }
    })
    return basketData.map((item: any) => {
      const volume = _.find(item.varieties, { selected: true })
      if ((volume && volume.variety_id === 728) || (volume && volume.variety_id === 1891)) {
        return {
          ...item,
          discont: c > 2 && c < 6 ? 8.33 : c > 5 ? 16.66 : item.discont ? item.discont : 0,
        }
      }
      return item
    })
  }
  return []
}
