import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { ReactComponent as PrevArrowSvg } from 'images/icons/prev_black_arrow.svg'
import moment, { Moment } from 'moment'
import cn from 'classnames'
import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { getAvailableBookingDates } from 'application/core/domain/useCase/booking/bookingActions'

import s from './DateSelector.module.scss'

const nowDate = moment()

type Props = {
  onChange: (date: Moment) => void
  selectedDate: Moment
  outlet: Outlet | null
}

const DateSelector = ({ onChange, selectedDate, outlet }: Props) => {
  const [weekNumber, setWeekNumber] = useState<number>(0)

  const month = useMemo(() => {
    const m = moment().add(weekNumber, 'weeks')
    return m
  }, [weekNumber])

  const onWeekClickHandler = useCallback(
    (type: string) => {
      if (type === 'up') {
        setWeekNumber(weekNumber + 1)
      } else {
        setWeekNumber(weekNumber - 1)
      }
    },
    [weekNumber],
  )

  const dates = useMemo(() => getAvailableBookingDates(outlet, month), [month, outlet])

  const onDayClickHandler = useCallback((date: Moment) => onChange(date), [onChange])

  useEffect(() => {
    if (selectedDate.isAfter(month, 'week')) {
      setWeekNumber((prev) => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.month}>
          {month.format('MMMM').charAt(0).toUpperCase() + month.format('MMMM, YYYY').slice(1)}
        </div>
        <div className={s.control}>
          <div
            className={cn(s.left, { [s.disabled]: nowDate.isSameOrAfter(month, 'week') })}
            onClick={() => onWeekClickHandler('down')}
          >
            <PrevArrowSvg />
          </div>
          <div className={s.right} onClick={() => onWeekClickHandler('up')}>
            <PrevArrowSvg />
          </div>
        </div>
      </div>
      <div className={s.dates}>
        {dates.map((item) => (
          <div
            className={cn(s.date_item, {
              [s.selected]: selectedDate.isSame(item.date, 'day'),
              [s.disabled]: item.disabled,
            })}
            key={`date_item_${item.date.format('DD.MM.YYYY-HH:mm:ss')}`}
            onClick={() => onDayClickHandler(item.date)}
          >
            <div className={s.day}>{item.date.format('dd').toUpperCase()}</div>
            <div className={s.number}>{item.date.format('DD')}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(DateSelector)
