import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import carsDB from 'application/core/data/localData/cars.json'
import _find from 'lodash/find'
import _map from 'lodash/map'
import { Car } from 'application/core/domain/entity/car/Car'
import moment from 'moment'
import getRandomInRange from 'application/utils/getRandomInRange'
import { createClientConfigs } from 'application/core/domain/useCase/client_configs/clientConfigsActions'
import queryClient from 'application/core/data/apiClient/queryClient'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { useDispatch } from 'react-redux'
import { setCar } from 'application/core/domain/store/carsReducer'
import { formatStringToCarNumber } from 'application/core/utils/carUtils'
import { ReactComponent as BackSvg } from 'images/icons/back_icon.svg'

import CarSubmitStage from './stages/CarSubmitStage'
import SelectCarNumberStage from './stages/SelectCarNumberStage'
import SelectCarModelStage from './stages/SelectCarModelStage'
import SelectCarStage from './stages/SelectCarStage'
import s from './SelectCarForm.module.scss'

type Props = {
  onAddCar?: Function
}

const SelectCarForm = ({ onAddCar }: Props) => {
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const [numberIsChecked, setNumberIsChecked] = useState<boolean>(false)
  const [manualAddCar, setManualAddCar] = useState<boolean>(false)
  const [carsModelsList, setCarsModelsList] = useState<any>([])
  const [selectedCarId, setSelectedCarId] = useState<string | number>(0)
  const [selectedCarModelId, setSelectedCarModelId] = useState<string | number>(0)
  const [carNumber, setCarNumber] = useState<string>('')
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const [fetchedCar, setFetchedCar] = useState<{ name: string; modelName: string; number: string }>(
    {
      name: '',
      modelName: '',
      number: '',
    },
  )

  const onBackClickHandler = useCallback(() => {
    if (numberIsChecked && manualAddCar) {
      setSelectedCarId(0)
      setSelectedCarModelId(0)
      setManualAddCar(false)
      setNumberIsChecked(false)
    } else if (numberIsChecked) {
      setNumberIsChecked(false)
    } else {
      navigation(-1)
    }
  }, [manualAddCar, navigation, numberIsChecked])

  const onNumberCheckHandler = useCallback(
    (responseData: any) => {
      if (responseData) {
        setManualAddCar(false)
        if (responseData.carModel && (responseData.carName || responseData.carBodyName)) {
          const car = responseData
          const findedCar = _find(
            carsDB,
            (item) => item.name.toUpperCase().indexOf(car.carName.toUpperCase()) !== -1,
          )
          if (findedCar) {
            const findedModel = _find(
              findedCar.models,
              (item) =>
                item.name.toUpperCase() === car.carModel.toUpperCase() ||
                item.name.toUpperCase() === car.carBodyName.toUpperCase(),
            )
            if (findedModel) {
              setSelectedCarId(findedCar.id)
              setSelectedCarModelId(findedModel.id)
              setFetchedCar({
                name: findedCar.name,
                modelName: findedModel.name,
                number: formatStringToCarNumber(carNumber),
              })
            } else {
              setFetchedCar({ name: '', modelName: '', number: '' })
              setManualAddCar(true)
            }
          }
        } else {
          setManualAddCar(true)
        }
      } else {
        setManualAddCar(true)
      }
      setNumberIsChecked(true)
    },
    [carNumber],
  )

  const onCarSavePressHandler = useCallback(() => {
    const findedCar = _find(carsDB, (item) => item.id === selectedCarId)
    setSaveIsLoading(true)
    if (findedCar) {
      const selectedCar = _find(findedCar.models, (item) => item.id === selectedCarModelId)
      if (selectedCar) {
        const n = carNumber.length === 14 ? 3 : 2
        const formattedNumber = carNumber.replace(/ /g, '')
        const region = formattedNumber.substring(formattedNumber.length - n)
        const number = formattedNumber.slice(0, formattedNumber.length - n)
        const carModel: Car = {
          id: parseInt(`${moment().unix()}${getRandomInRange(1, 1000)}`, 10),
          name: `${selectedCarId}`,
          carId: `${selectedCarId}`,
          carName: `${findedCar.name}`,
          modelName: `${selectedCar.name}`,
          modelId: `${selectedCarModelId}`,
          number: number,
          regionNumber: region,
          //@ts-ignore
          class: selectedCar.class,
          //@ts-ignore
          cyrillic_name: selectedCar['cyrillic-name'],
          //@ts-ignore
          year_from: selectedCar['year-from'],
          //@ts-ignore
          year_to: selectedCar['year-to'],
        }
        createClientConfigs(carModel)
          .then(() => {
            queryClient.invalidateQueries(['client_configs']).then(() => {
              setSaveIsLoading(false)
              setCar(carModel)(dispatch)
              if (onAddCar) {
                onAddCar()
              } else {
                navigation(-1)
              }
            })
          })
          .catch(() => {
            ToastNotify('Не удалось сохранить конфигурацию')
            setSaveIsLoading(false)
          })
      }
    } else {
      ToastNotify('Не удалось сохранить конфигурацию')
      setSaveIsLoading(false)
    }
  }, [carNumber, dispatch, navigation, onAddCar, selectedCarId, selectedCarModelId])

  useEffect(() => {
    if (selectedCarId) {
      const car = _find(carsDB, (item) => item.id === selectedCarId)
      if (car) {
        const list = _map(car.models, (item) => ({
          id: item.id,
          label: `${item.name}`,
          value: item.id,
          data: item,
        }))
        setCarsModelsList(list)
      } else {
        setCarsModelsList([])
      }
    }
  }, [selectedCarId])

  return (
    <div className={s.out_container}>
      <div className={s.head}>
        <div className={s.back_btn} onClick={onBackClickHandler}>
          <BackSvg />
        </div>
        <div className={s.title}>
          {!numberIsChecked
            ? 'Введите номер машины'
            : !manualAddCar
            ? ''
            : !selectedCarId
            ? 'Выберите марку'
            : 'Выберите модель'}
        </div>
        <div className={s.right_btn}></div>
      </div>

      <div className={s.container}>
        {!numberIsChecked ? (
          <SelectCarNumberStage
            carNumber={carNumber}
            setCarNumber={setCarNumber}
            onNumberCheck={onNumberCheckHandler}
          />
        ) : (
          <>
            {manualAddCar ? (
              <>
                {selectedCarId ? (
                  <SelectCarModelStage
                    carsDB={carsDB}
                    setSelectedCarModelId={setSelectedCarModelId}
                    selectedCarId={selectedCarId}
                    selectedCarModelId={selectedCarModelId}
                    onNextPressHandler={onCarSavePressHandler}
                    saveIsLoading={saveIsLoading}
                  />
                ) : (
                  <SelectCarStage carsDB={carsDB} setSelectedCarId={setSelectedCarId} />
                )}
              </>
            ) : (
              <CarSubmitStage
                onNextPressHandler={onCarSavePressHandler}
                onBackClick={onBackClickHandler}
                setManualAddCar={setManualAddCar}
                saveIsLoading={saveIsLoading}
                fetchedCar={fetchedCar}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(SelectCarForm)
