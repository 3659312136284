import React, { useState, useCallback } from 'react'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import cn from 'classnames'
import { useNavigate } from 'react-router'
import { Product } from 'application/core/domain/entity/product/Product'
import { addProductToBasket } from 'application/core/domain/useCase/basket/addToBasket'
import { useDispatch } from 'react-redux'
import { removeProductFromBasket } from 'application/core/domain/useCase/basket/removeFromBasket'

import s from './ProductItem.module.scss'

type Props = {
  item: Product
  productUniqId: string
}

const ProductItem = ({ item, productUniqId }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onClickHandler = useCallback(() => {
    if (productUniqId) {
      removeProductFromBasket({ ...item, uniq_id: productUniqId })(dispatch)
    } else {
      addProductToBasket(item, {
        selected_temperature: 0,
        selected_volume: 0,
      })(dispatch)
    }
  }, [productUniqId, item, dispatch])

  const onInfoPressHandler = useCallback(
    (e: any) => {
      e.stopPropagation()
      navigate(`?product_id=${item.id}#info`)
    },
    [navigate, item],
  )

  return (
    <div
      className={cn(s.container, { [s.active]: productUniqId ? true : false })}
      onClick={onClickHandler}
    >
      <div className={s.left}>
        <div className={s.title_container}>
          <div className={s.title}>{item.product_name}</div>
          <div className={s.icon} onClick={onInfoPressHandler}>
            <InfoSvg />
          </div>
        </div>
        {item.description ? <div className={s.desc}>{item.description}</div> : <></>}
      </div>
      <div className={s.right}>
        <div className={s.title}>{item.varieties[0].cost} ₽</div>
        <div className={s.time}>{parseInt(item.varieties[0].production_time, 10) / 60} мин</div>
      </div>
    </div>
  )
}

export default React.memo(ProductItem)
