import { Product } from 'application/core/domain/entity/product/Product'
import axios, { AxiosRequestConfig } from 'axios'

import apiClient from '../apiClient'

let ajaxRequest: any

export const fetchCalculatedBasket = async (
  products: Array<Product>,
  outlet_id: number,
  config?: AxiosRequestConfig,
) => {
  const { data } = await apiClient.post(
    '/api/items_calculation',
    {
      products,
      outlet_id,
      calculate_type: 'app',
    },
    config,
  )
  return data
}

export const fetchCalculatedBaristaBasket = async (sendData: {
  products: Array<Product>
  outlet_id: number
  client_id: number
}) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  const { data } = await apiClient({
    method: 'POST',
    url: '/api/items_calculation',
    data: sendData,
    headers: { Authorization: '' },
    cancelToken: ajaxRequest.token,
  })
  return data
}
