import { useQuery } from '@tanstack/react-query'
import { fetchBookingList, fetchBookingAreas } from 'application/core/data/api/booking'

export const useBookingList = (outlet_id?: number) => {
  const query = useQuery(['booking_list', outlet_id || 1], () => fetchBookingList(outlet_id), {
    refetchInterval: 5000,
  })
  return { ...query, data: query.data && query.data.length ? query.data : [] }
}

export const useBookingAreas = (outlet_id?: number) => {
  const query = useQuery(['booking_areas', outlet_id], () => fetchBookingAreas(outlet_id))
  return query
}
