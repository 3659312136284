import React from 'react'
import PageLayout from 'application/presentation/common/PageLayout/PageLayout'

import s from './OffertaPage.module.scss'

const OffertaPage = () => {
  return (
    <PageLayout title="Договор оферты">
      <div className={s.content}>
        Сервис представляет информационные услуги по представлению автомоечных комплексов в России.
        Подробнее на сайте <a href="https://moyservice.com">moyservice.com</a> Сервис представляет
        информационные услуги по представлению автомоечных комплексов в России. Подробнее на сайте.
        <br />
        <br />
        <b>1 раздел</b>
        <br />
        Сервис представляет информационные услуги по представлению автомоечных комплексов в России.
        Подробнее на сайте
        <br />
        <br />
        <b>2 раздел</b>
        <br />
        Сервис представляет информационные услуги по представлению автомоечных комплексов в России.
        Подробнее на сайте
      </div>
    </PageLayout>
  )
}

export default React.memo(OffertaPage)
