import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Button, Loader } from 'application/presentation/common/uiComponents'
import { ReactComponent as CarSvg } from 'images/icons/car.svg'
import { ReactComponent as CloseSvg } from 'images/icons/close.svg'
import { ReactComponent as BluePlusSvg } from 'images/icons/blue_plus.svg'
import apiClient from 'application/core/data/apiClient/apiClient'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { ReactComponent as CalendarSvg } from 'images/icons/calendar.svg'
import { useDispatch } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { setBasket } from 'application/core/domain/store/basketReducer'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { NavLink } from 'react-router-dom'
import NoticeMW from 'application/presentation/common/ModalWindows/NoticeMW/NoticeMW'
import { cancelOpenOrder } from 'application/core/domain/useCase/orders/orderAction'
import queryClient from 'application/core/data/apiClient/queryClient'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import cn from 'classnames'
import {
  reachGoalCancelOrderClick,
  reachGoalOrderEnd,
  reachGoalRouteClick,
  reachGoalWriteMoreClick,
} from 'application/core/utils/metrika/yandexReachGoals'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import generateCalendarUrl from 'application/core/utils/generateCalendarUrl'

import s from './OrderResultPage.module.scss'

const fetchPaymentStatus = async (id: number) => {
  const { data } = await apiClient.post('/api/payment/check', {
    open_order_id: id,
  })
  return data
}

const OrderResultPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [cancelOrderIsLoading, setCancelOrderIsLoading] = useState<boolean>(false)
  const { outlet, bookingData } = useAppSelector(({ userReducer, basketReducer }) => ({
    outlet: userReducer.outlet,
    bookingData: basketReducer.bookingData,
  }))
  const [orderId, setOrderId] = useState<number>(0)
  const [checkPaymentStage, setCheckPaymentStage] = useState<boolean>(true)

  const { data: paymentStatusData, isLoading: paymentStatusDataIsLoading } = useQuery(
    ['checkpayment', orderId],
    () => fetchPaymentStatus(orderId),
    {
      enabled: checkPaymentStage && orderId && orderId !== 0 ? true : false,
      refetchInterval: 10000,
    },
  )

  const phone = useMemo(() => {
    const param = getOutletAppliedParameter(outlet, 'phone')
    return param?.value || ''
  }, [outlet])

  const callPressHandler = useCallback(() => {
    window.location.href = phone ? `tel:${phone}` : 'https://t.me/MOYsupport'
  }, [phone])

  const onCloseOrderConfirm = useCallback(() => {
    setCancelOrderIsLoading(true)
    reachGoalCancelOrderClick()
    cancelOpenOrder({
      comment: 'отменён пользователем',
      open_order_id: orderId,
    })
      .then(() => {
        queryClient.invalidateQueries(['actually'])
        setCancelOrderIsLoading(false)
        navigate(`/cancel_order/${orderId}`, { replace: true })
      })
      .catch(() => {
        ToastNotify('Произошла ошибка!')
      })
  }, [orderId, navigate])

  const calendarUrl = useMemo(
    () => generateCalendarUrl({ outlet, bookingData }),
    [outlet, bookingData],
  )

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('open_order_id')
    reachGoalOrderEnd()
    if (id) {
      setOrderId(parseInt(id, 10))
    }
  }, [])

  useEffect(() => {
    if (paymentStatusData && paymentStatusData.status === 'Оплата прошла успешно') {
      setCheckPaymentStage(false)
      setBasket([])(dispatch)
    }
  }, [paymentStatusData, dispatch])

  return (
    <div className={s.container}>
      {paymentStatusDataIsLoading ? (
        <div className={s.check_pay}>
          <Loader size={64} color="#4200FF" containerClassName={s.pay_check_loader} />
          <div className={s.notice}>Проверяем оплату, пожалуйста, подождите</div>
        </div>
      ) : (
        <div className={s.inner_container}>
          <div className={s.title}>
            Ожидаем Вас на мойке {moment(bookingData?.date).format('DD.MM.YYYY к HH:mm')}
          </div>
          <div className={s.subtitle}>{outlet?.address}</div>
          <div className={s.box}>Бокс №{bookingData ? bookingData.table.number : 0}</div>

          <div className={s.buttons}>
            <a
              className={s.btn}
              href={`https://yandex.ru/maps/?rtext=~${outlet?.width}%2C${outlet?.height}`}
              onClick={reachGoalRouteClick}
            >
              <div className={s.icon}>
                <CarSvg />
              </div>
              <div className={s.btn_name}>
                Проложить
                <br />
                маршрут
              </div>
            </a>
            <NavLink className={s.btn} to="#confirm_cancel_order">
              <div className={s.icon}>
                <CloseSvg />
              </div>
              <div className={s.btn_name}>
                Отменить
                <br />
                заказ
              </div>
            </NavLink>
            <NavLink className={s.btn} to="/booking" onClick={reachGoalWriteMoreClick}>
              <div className={s.icon}>
                <BluePlusSvg />
              </div>
              <div className={s.btn_name}>
                Записаться
                <br />
                еще
              </div>
            </NavLink>
            {!!calendarUrl && (
              <a className={s.btn} href={calendarUrl}>
                <div className={cn(s.icon, s.calendar_icon)}>
                  <CalendarSvg />
                </div>
                <div className={s.btn_name}>
                  Добавить
                  <br />в календарь
                </div>
              </a>
            )}
          </div>
          <Button
            text="Статус заказа"
            to={`/orders/${orderId}?is_open_order=true`}
            color="fiol"
            isLink
            containerClassName={s.status_btn}
          />
          <Button
            text="Связаться с мойкой"
            onClick={callPressHandler}
            color="gray"
            containerClassName={s.support_btn}
          />
        </div>
      )}
      <NoticeMW
        triggerHash="confirm_cancel_order"
        title="Вы уверены, что хотите отменить заказ?"
        text="Деньги за заказ вернуться в течение 3-5 рабочих дней"
        confirmationProps={{
          onConfirm: onCloseOrderConfirm,
          confirmIsLoading: cancelOrderIsLoading,
        }}
      />
      {/* <ConfirmationMW {...confirmMwData} /> */}
    </div>
  )
}

export default React.memo(OrderResultPage)
