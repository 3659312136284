import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { ReactComponent as TrashSvg } from 'images/icons/trash_icon.svg'
import { Product } from 'application/core/domain/entity/product/Product'
import cn from 'classnames'
import { removeProductFromBasket } from 'application/core/domain/useCase/basket/removeFromBasket'
import { useDispatch } from 'react-redux'

import s from './BasketItem.module.scss'

type Props = {
  item: Product
  canRemove?: boolean
  containerClassName?: string
}

const BasketItem = ({ item, containerClassName, canRemove }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onInfoPressHandler = useCallback(() => {
    navigate(`?product_id=${item.id}#info`)
  }, [navigate, item])

  const removeItem = useCallback(() => {
    removeProductFromBasket(item)(dispatch)
  }, [item, dispatch])

  const variety = useMemo(() => {
    return item.varieties && item.varieties.length
      ? item.varieties[0]
      : item.variety
      ? item.variety
      : null
  }, [item])

  return (
    <div className={cn(s.container, containerClassName || '')}>
      <div className={s.left}>
        <div className={s.title_container}>
          <div className={s.title}>{item.product_name}</div>
          <div className={s.icon} onClick={onInfoPressHandler}>
            <InfoSvg />
          </div>
        </div>
        <div className={s.desc}>{item.description}</div>
      </div>
      <div className={s.right}>
        <div className={s.title}>
          {item.full_cost ? item.full_cost : variety ? variety.cost : 0} ₽
        </div>
        {variety ? (
          <div className={s.time}>{parseInt(variety.production_time, 10) / 60} мин</div>
        ) : (
          <></>
        )}
      </div>
      {canRemove && (
        <div className={s.trash_icon} onClick={removeItem}>
          <TrashSvg />
        </div>
      )}
    </div>
  )
}

export default React.memo(BasketItem)
