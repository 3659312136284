import { Order } from 'application/core/domain/entity/order/Order'
import React, { useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import supportPng from 'images/support_img.png'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import { useNavigate } from 'react-router'
import { multipleAddToBasket } from 'application/core/domain/useCase/basket/addToBasket'
import { useDispatch } from 'react-redux'

import { BasketItem, Button, ImageComponent, RatingIcon } from '../../uiComponents'
import Rating from '../../uiComponents/Rating/Rating'
import Tips from '../../uiComponents/Tips/Tips'

import s from './OrderDetailsMW.module.scss'

type Props = {
  data: Order
}

const OrderDetailsContent = ({ data }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showDetails, setShowDetails] = useState<boolean>(true)
  const [rating, setRating] = useState<number>(data.rating || 0)
  const [tipsVal, setTipsVal] = useState<number>(0)

  const carData = useMemo(() => {
    if (data && data.comments && data.comments.length) {
      const car = JSON.parse(data.comments[0].comment)
      return car.car
    }
    return null
  }, [data])

  const repeatOrder = useCallback(() => {
    if (data && data.products) {
      multipleAddToBasket(data.products)(dispatch)
      navigate('/booking')
    }
  }, [data, dispatch, navigate])

  const onSupportClickHandler = useCallback(() => navigate(`/support/${data.id}`), [navigate, data])

  return (
    <div className={s.content}>
      {showDetails ? (
        <>
          <div className={s.title}>Детали заказа</div>
          {data.barista ? (
            <div className={s.washer}>
              <ImageComponent
                imageUrl={data.barista.photo || ''}
                containerClassName={s.washer_img}
              />
              <div className={s.washer_info}>
                <div className={s.washer_name}>{data.barista.name}</div>
                <div className={s.washer_notice}>Мойщик</div>
              </div>
              {/* <RatingIcon value={4.3} containerClassName={s.washer_rate} /> */}
            </div>
          ) : (
            <></>
          )}

          <div className={s.basket}>
            {data.products.map((item) => (
              <BasketItem
                item={item}
                key={`basket_order_item_${item.id}`}
                containerClassName={s.basket_item}
              />
            ))}
          </div>
          <div className={s.bottom_info}>
            {carData ? (
              <div className={s.info_item}>
                <div className={s.info_title}>Автомобиль</div>
                <div className={s.info_subtitle}>
                  {carData.number}
                  {carData.regionNumber} {carData.name} {carData.modelName}
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* <div className={s.info_item}>
              <div className={s.info_title}>Оплата</div>
              <div className={s.info_subtitle}>Мир ****4937</div>
            </div> */}
          </div>
          <div className={s.buttons}>
            <Button
              color="gray"
              text="Помощь с заказом"
              className={s.btn}
              onClick={onSupportClickHandler}
            />
            <Button color="fiol" text="Повторить заказ" className={s.btn} onClick={repeatOrder} />
          </div>
        </>
      ) : (
        <>
          <div className={s.title}>{moment(data.created_at).format('DD.MM, в HH:mm')}</div>
          <div className={s.user_containers}>
            {data.barista ? (
              <div className={s.user_item}>
                {/* <RatingIcon value={4.3} containerClassName={s.rate} /> */}
                <ImageComponent
                  imageUrl={data.barista.photo || ''}
                  containerClassName={s.img_container}
                />
                <div className={s.name}>{data.barista.name}</div>
              </div>
            ) : (
              <></>
            )}

            <div className={s.user_item}>
              <div className={s.img_container}>
                <img src={supportPng} alt="" />
              </div>
              <div className={s.name}>Поддержка</div>
            </div>
          </div>
          <Rating rating={rating} onChange={setRating} containerClassName={s.rating} />
          <Tips value={tipsVal} onChange={setTipsVal} containerClassName={s.tips} />
          <div className={s.details_btn} onClick={() => setShowDetails(true)}>
            <InfoSvg />
            <div>Детали заказа</div>
            <div className={s.trigger}>
              <TriggerSvg />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(OrderDetailsContent)
