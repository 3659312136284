/* eslint-disable react/require-default-props */
import React from 'react'
import cn from 'classnames'
import ReactTextareaAutosize from 'react-textarea-autosize'
import ReactInputMask from 'react-input-mask'
import Select from 'react-select'
import _find from 'lodash/find'
import { ReactComponent as TriggerSvg } from 'images/icons/black_down_trigger.svg'

import ErrorMsg from '../../ErrorMsg'
import './SelectorStyle.scss'

import s from './UniversalInputComponent.module.scss'

export type UniversalInputComponentType =
  | 'text'
  | 'number'
  | 'password'
  | 'textarea'
  | 'phone'
  | 'datetime'
  | 'time'
  | 'date'
  | 'selector'

type Props = {
  type: UniversalInputComponentType
  onChange: Function
  value: string | number
  title?: string
  errorMsg?: string
  placeholder?: string
  containerClassName?: string
  inputContainerClassName?: string
  onKeyUp?: Function
  inputRef?: any
  disabled?: boolean
  options?: Array<{ value: number; label: string }>
  isClearable?: boolean
  onInputChange?: Function
  filterOption?: any
}

const InputComponent = React.memo(
  ({
    type = 'text',
    onChange,
    value,
    placeholder,
    onKeyUp,
    inputRef,
    disabled,
    options,
    isClearable,
    onInputChange,
    filterOption,
  }: Props) => {
    switch (type) {
      case 'selector':
        const selectedValue = _find(options, { value: value })
        return (
          <Select
            //@ts-ignore
            options={options}
            //@ts-ignore
            value={selectedValue}
            classNamePrefix={'universalCustomSelect'}
            placeholder={placeholder || 'Выберите...'}
            isClearable={isClearable || false}
            isSearchable
            isDisabled={disabled || false}
            className={s.select_container}
            escapeClearsValue
            backspaceRemovesValue
            ref={inputRef}
            filterOption={filterOption || false}
            //@ts-ignore
            onInputChange={onInputChange}
            //@ts-ignore
            onChange={(val: { label: string; value: number | string }) => {
              if (val && (typeof val.value === 'number' || typeof val.value === 'string')) {
                onChange(val.value)
              } else {
                onChange(null)
              }
            }}
            components={{
              DropdownIndicator: () => (
                <div className={s.dropdown_trigger}>
                  <TriggerSvg />
                </div>
              ),
              // ClearIndicator: ({ innerProps }) => {
              //   return (
              //     <div {...innerProps} className={s.clear_indicator}>
              //       <CloseSvg />
              //     </div>
              //   )
              // },
            }}
          />
        )
      case 'phone':
        return (
          <ReactInputMask
            mask="+7 (999) 999-99-99"
            maskChar={'_'}
            inputMode="numeric"
            alwaysShowMask
            placeholder={placeholder}
            // inputRef={inputRef}
            // ref={inputRef}
            value={value}
            //   readOnly={readOnly || false}
            onChange={(e: any) => onChange(e.target.value)}
          />
        )
      case 'number':
        return (
          <input
            type="text"
            onChange={(e: any) => onChange(e.target.value.replace(/\D/, ''))}
            value={value}
            readOnly={disabled || false}
            className={s.input}
            ref={inputRef}
            placeholder={placeholder}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      case 'datetime':
      case 'date':
      case 'time':
        return (
          <input
            type={type === 'datetime' ? 'datetime-local' : type}
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
          />
        )
      case 'textarea':
        return (
          <ReactTextareaAutosize
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      case 'password':
      case 'text':
        return (
          <input
            type={type}
            onChange={(e: any) => onChange(e.target.value)}
            value={value}
            className={s.input}
            readOnly={disabled || false}
            placeholder={placeholder}
            ref={inputRef}
            onKeyUp={(e: any) => (onKeyUp ? onKeyUp(e) : {})}
          />
        )
      default:
        return <></>
    }
  },
)

const UniversalInputComponent = (props: Props) => {
  const { title, inputContainerClassName, containerClassName, errorMsg } = props
  return (
    <div className={cn(s.container, containerClassName || '')}>
      {title ? <div className={s.title}>{title}</div> : ''}

      <div className={cn(s.input_container, inputContainerClassName || '')}>
        <InputComponent {...props} />
        {errorMsg ? <ErrorMsg text={errorMsg} /> : <></>}
      </div>
    </div>
  )
}

export default React.memo(UniversalInputComponent)
