import { useAppSelector } from 'application/core/domain/store/hooks'
import { RootState } from 'application/core/domain/store/reduxStore'
import { useOrdersHistory } from 'application/core/domain/useCase/orders/getOrders'
import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import React, { useMemo } from 'react'
import { Order } from 'application/core/domain/entity/order/Order'
import { Loader, OrderItem } from 'application/presentation/common/uiComponents'
import OrderDetailsMW from 'application/presentation/common/ModalWindows/OrderDetailsMW/OrderDetailsMW'

import s from './OrdersHistoryPage.module.scss'

const OrderHistoryPage = () => {
  const { token } = useAppSelector(({ userReducer }: RootState) => ({
    token: userReducer.token,
  }))
  const { data, isLoading } = useOrdersHistory(token || '')

  return (
    <PageLayout title="История моек">
      <div className={s.container}>
        {isLoading ? (
          <Loader size={32} />
        ) : data && data.length ? (
          data.map((item: Order) => <OrderItem item={item} key={`order_item_${item.order_id}`} />)
        ) : (
          <div className={s.empty}>У вас нет заказов</div>
        )}
      </div>
      <OrderDetailsMW />
    </PageLayout>
  )
}

export default React.memo(OrderHistoryPage)
