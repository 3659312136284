import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { Order } from 'application/core/domain/entity/order/Order'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as CarSvg } from 'images/icons/car.svg'
import { ReactComponent as CloseSvg } from 'images/icons/close.svg'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { ReactComponent as TriggerSvg } from 'images/icons/right_gray_trigger.svg'
import { Button } from 'application/presentation/common/uiComponents'
import NoticeMW from 'application/presentation/common/ModalWindows/NoticeMW/NoticeMW'
import moment from 'moment'
import _sumBy from 'lodash/sumBy'
import { getTimeDiff } from 'application/utils/timeConverter'
import { cancelOpenOrder } from 'application/core/domain/useCase/orders/orderAction'
import queryClient from 'application/core/data/apiClient/queryClient'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import {
  reachGoalCancelOrderClick,
  reachGoalRouteClick,
} from 'application/core/utils/metrika/yandexReachGoals'

import s from '../OrderInfoPage.module.scss'

type Props = {
  outlet: Outlet | null
  data: Order
}

const getProcent = (startTime: string, productionTime: number) => {
  const now = moment()
  const start = moment(startTime)
  const end = moment(startTime).add(productionTime, 'seconds')
  if (now.isBetween(start, end)) {
    const diffSec = (end.valueOf() - now.valueOf()) / 1000
    return (diffSec / productionTime) * 100
  } else if (now.isAfter(end)) {
    return 100
  }
  return 0
}

const OpenOrderInfoContent = ({ outlet, data }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [cancelOrderIsLoading, setCancelOrderIsLoading] = useState<boolean>(false)
  const timer = useRef<any>()
  const [timerValue, setTimerValue] = useState<{
    minutes: string
    seconds: string
    hours: string
    isBefore?: boolean
    isAfter?: boolean
  }>({
    minutes: '0',
    seconds: '0',
    hours: '0',
  })
  const [progress, setProgress] = useState<number>(0)
  const fullProductionTime = useMemo(() => {
    return _sumBy(data.products, (item) =>
      item.variety.production_time ? parseInt(item.variety.production_time, 10) : 0,
    )
  }, [data])

  const startTimer = useCallback(() => {
    if (timer && timer.current) {
      clearTimeout(timer.current)
    }
    if (data.booking_info) {
      const timeDiff = getTimeDiff(data.booking_info?.booking_date, fullProductionTime)
      const procent = getProcent(data.booking_info?.booking_date, fullProductionTime)
      setTimerValue(timeDiff)
      setProgress(procent)
      if (parseInt(timeDiff.minutes, 10) >= 0 || parseInt(timeDiff.seconds, 10) > 0) {
        timer.current = setTimeout(() => startTimer(), 1000)
      }
    }
  }, [fullProductionTime, data.booking_info])

  const onCloseOrderConfirm = useCallback(() => {
    setCancelOrderIsLoading(true)
    reachGoalCancelOrderClick()
    cancelOpenOrder({
      comment: 'отменён пользователем',
      open_order_id: data.id,
    })
      .then(() => {
        queryClient.invalidateQueries(['actually'])
        setCancelOrderIsLoading(false)
        navigate(`/cancel_order/${data.id}`, { replace: true })
      })
      .catch(() => {
        ToastNotify('Произошла ошибка!')
      })
  }, [data, navigate])

  const titleString = useMemo(() => {
    if (data.status === 'Забронирован') {
      return timerValue.isBefore
        ? `Ожидаем Вас на мойке ${moment(data.booking_info.booking_date).format('D.MM')} к ${moment(
            data.booking_info.booking_date,
          ).format('H:mm')}`
        : `Ожидание начала мойки. Ваше время началось.`
    }
    if (data.status === 'Отменен') {
      return 'Ваш заказ отменен'
    }
    return timerValue.isBefore
      ? `Ожидаем Вас на мойке ${moment(data.booking_info.booking_date).format('D.MM')} к ${moment(
          data.booking_info.booking_date,
        ).format('H:mm')}`
      : timerValue.isAfter
      ? 'Мойка завершена'
      : 'Мойка началась'
  }, [data, timerValue.isAfter, timerValue.isBefore])

  const onSupportBtnClick = useCallback(
    () => (window.location.href = 'https://t.me/MOYsupport'),
    [],
  )

  useEffect(() => {
    if (data && data.state === 'saved') {
      navigate(`/orders/${data.id}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    startTimer()
  }, [startTimer])

  return (
    <>
      <div className={s.inner_container}>
        <div className={s.title}>{titleString}</div>
        {outlet ? <div className={s.subtitle}>{outlet.address}</div> : <></>}
        <div className={s.box}>Бокс №{data ? data.booking_info.table.number : 0}</div>

        {timerValue.isAfter ? (
          <div className={s.timer}> Мойка завершена</div>
        ) : (
          <div className={s.status_block}>
            <div className={s.progress_line}>
              <div className={s.progress} style={{ width: `${progress}%` }}></div>
            </div>
            <div className={s.timer}>
              До {timerValue.isBefore ? 'начала' : 'завершения'} мойки осталось{' '}
              {timerValue.hours && timerValue.hours !== '0' ? `${timerValue.hours} ч. ` : ''}
              {timerValue.minutes} мин.
            </div>
          </div>
        )}

        <div className={s.buttons}>
          {outlet ? (
            <a
              className={s.btn}
              href={`https://yandex.ru/maps/?rtext=~${outlet.width}%2C${outlet.height}`}
              onClick={reachGoalRouteClick}
            >
              <div className={s.icon}>
                <CarSvg />
              </div>
              <div className={s.btn_name}>
                Проложить
                <br />
                маршрут
              </div>
            </a>
          ) : (
            <></>
          )}
          <NavLink
            className={s.btn}
            to={`${location.pathname}${
              location.search ? `${location.search}` : ''
            }#confirm_cancel_order`}
          >
            <div className={s.icon}>
              <CloseSvg />
            </div>
            <div className={s.btn_name}>
              Отменить
              <br />
              заказ
            </div>
          </NavLink>
        </div>
        <NavLink
          className={s.details_btn}
          to={`${location.pathname}${location.search ? `${location.search}` : ''}#details`}
        >
          <InfoSvg />
          <div>Детали заказа</div>
          <div className={s.trigger}>
            <TriggerSvg />
          </div>
        </NavLink>
        <Button
          text="Написать в поддержку"
          onClick={onSupportBtnClick}
          color="fiol"
          containerClassName={s.support_btn}
        />
      </div>

      <NoticeMW
        triggerHash="confirm_cancel_order"
        title="Вы уверены, что хотите отменить заказ?"
        text="Деньги за заказ вернуться в течение 3-5 рабочих дней"
        confirmationProps={{
          onConfirm: onCloseOrderConfirm,
          confirmIsLoading: cancelOrderIsLoading,
        }}
      />
    </>
  )
}

export default React.memo(OpenOrderInfoContent)
