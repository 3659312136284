import React, { useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { Button } from 'application/presentation/common/uiComponents'
import PageLayout from 'application/presentation/common/PageLayout/PageLayout'

import s from './ErrorBoundary.module.scss'
// import Bugsnag from '@bugsnag/js'

// const bugsnag = new Client('fd983297ffd48ade73b5f6df44cfafa5')

const errorHandler = (error: Error) => {
  // Bugsnag.notify(error)
}

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any
  resetErrorBoundary: () => void
}) {
  const onBackPress = useCallback(() => {
    window.location.href = '/booking'
  }, [])
  return (
    <PageLayout title="В приложении что-то сломалось">
      <div className={s.errorContainer}>
        <div className={s.errorContent}>
          <div className={s.errorDetailText}>
            <b>Ошибка:</b>
            <br />
            {error ? error.toString() : null}
          </div>
          <div className={s.errorText}>
            Мы уже работаем над этой проблемой, можете вернуться назад или попробовать загрузить ещё
            раз:
          </div>
          <Button
            text="Попробовать снова"
            onClick={() => resetErrorBoundary()}
            color="fiol"
            className={s.btn}
          />
          <Button text="Вернуться назад" onClick={onBackPress} color="gray" className={s.btn} />
        </div>
      </div>
    </PageLayout>
  )
}

export const ErrorHandler = ({ children }: { children: React.ReactNode }) => {
  const { reset } = useQueryErrorResetBoundary()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler} onReset={reset}>
      {children}
    </ErrorBoundary>
  )
}
