import apiClient from '../apiClient'

export const selectRegionRequest = async (region_id: number) => {
  const { data } = await apiClient.post('/api/user/region_selection', {
    region_id,
  })
  return data
}

export const fetchRegions = async () => {
  const { data } = await apiClient.get('/api/regions')
  return data
}
