import React, { useCallback, useState } from 'react'
import _map from 'lodash/map'
import { ReactComponent as InfoSvg } from 'images/icons/info_icon.svg'
import { useNavigate } from 'react-router'
import _filter from 'lodash/filter'
import _sampleSize from 'lodash/sampleSize'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { useProducts } from 'application/core/domain/useCase/products/getProducts'
import { Product, getProductPrice } from 'application/core/domain/entity/product/Product'
import { addProductWithMinPrice } from 'application/core/domain/useCase/basket/addToBasket'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { useDispatch } from 'react-redux'

import s from './Additionals.module.scss'

const Additionals = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const outlet = useAppSelector(({ userReducer }) => userReducer.outlet)
  const { data: productsData, isLoading: productsIsLoading } = useProducts(outlet ? outlet.id : 0)
  const [products, setProducts] = useState<Array<Product> | null>(
    productsData
      ? () => {
          let prod = _sampleSize(
            _filter(
              productsData,
              (p) => p.availability_status === 'in_stock' && getProductPrice(p) > 0,
            ),
            3,
          )
          if (prod && prod.length) {
            return prod
          }
          return _sampleSize(
            _filter(
              productsData,
              (p) => p.availability_status === 'in_stock' && getProductPrice(p) > 0,
            ),
          )
        }
      : null,
  )

  const addProduct = useCallback(
    (prod: Product) =>
      addProductWithMinPrice(prod, {
        callback: () => {
          setProducts(_filter(products, (p) => p.id !== prod.id))
          ToastNotify(`${prod.product_name} добавлен в корзину!`)
        },
      })(dispatch),
    [dispatch, products],
  )
  const onInfoPressHandler = useCallback(
    (e: any, id: number) => {
      e.stopPropagation()
      navigate(`?product_id=${id}#info`)
    },
    [navigate],
  )

  return (
    <div className={s.container}>
      <div className={s.title}>Добавить в заказ?</div>
      <div className={s.items}>
        {_map(products, (item) => (
          <div className={s.item} onClick={() => addProduct(item)} key={`additionals_${item.id}`}>
            <div className={s.item_title}>{item.product_name}</div>
            <div className={s.time}>60 мин</div>
            <div className={s.price}>{item.varieties[0].cost} ₽</div>
            <div className={s.info_icon} onClick={(e: any) => onInfoPressHandler(e, item.id)}>
              <InfoSvg />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(Additionals)
