import React, { useEffect, useRef, useState, useCallback, useContext, useMemo } from 'react'
import { ReactComponent as UserSvg } from 'images/icons/user_pic.svg'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthUser } from 'application/core/domain/entity/user/User'
import cn from 'classnames'
import { AuthContext } from 'application/presentation/context/Authorization'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { reachGoalPartnersClick } from 'application/core/utils/metrika/yandexReachGoals'

import { Button } from '../../uiComponents'

import s from './UserMenu.module.scss'

type Props = {
  user: AuthUser
}

const useOutsideClick = (ref: any, setOpened: Function) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, setOpened])
}

const UserMenu = ({ user }: Props) => {
  const navigate = useNavigate()
  const { currentCar } = useAppSelector(({ carsReducer }) => ({
    currentCar: carsReducer.currentCar,
  }))
  const authContext = useContext(AuthContext)
  const wrapperRef = useRef<any>()
  const [opened, setOpened] = useState<boolean>(false)
  useOutsideClick(wrapperRef, setOpened)

  const showOutletSelector = useMemo(
    () =>
      //@ts-ignore
      window.ENV && window.ENV.outletId ? false : true,
    [],
  )

  const handleClick = useCallback(() => {
    setOpened((o) => !o)
  }, [])

  const logout = useCallback(
    () =>
      authContext.logout(() => {
        navigate('/booking')
      }),
    [authContext, navigate],
  )

  return (
    <div
      className={`${s.more_button} ${opened ? s.opened : ''}`}
      onClick={(e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (!opened) handleClick()
      }}
    >
      <div className={s.profile_btn}>
        <UserSvg />
        <div className={s.profile_name}>Профиль</div>
      </div>
      <div className={`${s.dropdown_menu} ${opened ? s.opened : ''}`} ref={wrapperRef}>
        {user ? (
          <>
            <NavLink className={s.user_block} to="/profile" onClick={handleClick}>
              <div className={s.image}>
                <div>{user.name[0].toUpperCase()}</div>
              </div>
              <div className={s.info}>
                <div className={s.title}>{user.name}</div>
                <div className={s.phone}>{user.phone}</div>
              </div>
            </NavLink>
            <NavLink className={s.list_item} to="/cars" onClick={handleClick}>
              <div className={s.title}>Автомобиль</div>
              <div className={cn(s.subtitle, s.gray)}>
                {currentCar ? (
                  <>
                    {currentCar.number} {currentCar.regionNumber}{' '}
                    <b>
                      {currentCar.name} {currentCar.modelName}
                    </b>
                  </>
                ) : (
                  'Не выбран'
                )}
              </div>
            </NavLink>
            <NavLink className={s.list_item} to="/payment_systems" onClick={handleClick}>
              <div className={s.title}>Способы оплаты</div>
            </NavLink>
            <NavLink className={s.list_item} to="/orders" onClick={handleClick}>
              <div className={s.title}>История моек</div>
            </NavLink>{' '}
          </>
        ) : (
          <Button
            color="fiol"
            text="Войти"
            isLink
            to="/authorization"
            containerClassName={s.auth_btn}
          />
        )}

        {showOutletSelector && (
          <NavLink className={s.list_item} to="#outlets">
            <div className={s.title}>Каталог моек</div>
          </NavLink>
        )}
        <NavLink className={s.list_item} to="/care" onClick={handleClick}>
          <div className={s.title}>Служба заботы</div>
        </NavLink>
        <NavLink className={s.list_item} to="/information" onClick={handleClick}>
          <div className={s.title}>Информация</div>
        </NavLink>
        <NavLink
          className={cn(s.list_item, s.sign_wash)}
          to="/sign_wash"
          onClick={reachGoalPartnersClick}
        >
          ⚡️ Подключить автомойку
        </NavLink>
        {user ? (
          <div className={s.list_item} onClick={logout}>
            <div className={cn(s.title, s.red)}>Выйти</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default React.memo(UserMenu)
