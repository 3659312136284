import { fetchProduct, fetchProducts } from 'application/core/data/api/products'
import { Product } from 'application/core/domain/entity/product/Product'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from '../../store/hooks'

export const useProducts = (outlet_id: number) => {
  const query = useQuery<{ products: Array<Product> }>(
    ['products', outlet_id],
    () => fetchProducts(outlet_id),
    {
      refetchInterval: 60000,
      enabled: !!outlet_id,
    },
  )

  return {
    ...query,
    data: query.data ? query.data.products : [],
    isLoading: outlet_id ? query.isLoading : false,
  }
}

export const useAllProducts = (outlet_id?: number) => {
  const user = useAppSelector(({ userReducer }) => userReducer.user)
  const outlet =
    outlet_id || outlet_id === 0 ? outlet_id : user && user.session ? user.session.outlet_id : 0

  const query = useQuery<{ products: Array<Product> }>(
    ['all_products', `outlet_${outlet}`],
    () => fetchProducts(outlet),
    {
      enabled: user ? true : false,
      refetchInterval: 60000,
      refetchOnWindowFocus: false,
    },
  )
  return query
}

export const useProduct = (id: number, outlet_id: number) => {
  const query = useQuery<{ product: Product }>(
    ['product', id, outlet_id],
    () => fetchProduct(id, outlet_id || 1),
    {
      enabled: id !== 0 ? true : false,
    },
  )

  return { ...query, data: query.data ? query.data.product : null }
}
