import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

type PaymentMethodState = {
  selectedPayMethod: number
}

const initialState: PaymentMethodState = {
  selectedPayMethod: 1,
}

export const payReducer = createSlice({
  name: 'payReducer',
  initialState,
  reducers: {
    setPaymentMethodAction: (state, { payload: method }: PayloadAction<number>) => {
      state.selectedPayMethod = method
    },
  },
})

const { setPaymentMethodAction } = payReducer.actions

export const setPayMethod = (method: number) => (dispatch: Dispatch) =>
  dispatch(setPaymentMethodAction(method))

export default payReducer.reducer
