import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as Calendar } from 'images/icons/calendar.svg'
import {
  Button,
  ErrorMsg,
  HighDemandNotify,
  Loader,
} from 'application/presentation/common/uiComponents'
import { ReactComponent as NextArrow } from 'images/icons/next_white_arrow.svg'
import ProductInfoMW from 'application/presentation/common/ModalWindows/ProductInfoMW/ProductInfoMW'
import { useLocation, useNavigate } from 'react-router'
import { useBookingList, useBookingAreas } from 'application/core/domain/useCase/booking/getBooking'
import { useAppSelector } from 'application/core/domain/store/hooks'
import moment, { Moment } from 'moment'
import {
  getAreasTables,
  getBookedDates,
  getBookingFreeTimes,
  getTimes,
  splitBookingTimesToIntervals,
} from 'application/core/domain/useCase/booking/bookingActions'
import { useProducts } from 'application/core/domain/useCase/products/getProducts'
import _find from 'lodash/find'
import { setBookingData } from 'application/core/domain/store/basketReducer'
import { useDispatch } from 'react-redux'
import { BookingTable } from 'application/core/domain/entity/booking/Booking'
import _clone from 'lodash/clone'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _concat from 'lodash/concat'
import _sumBy from 'lodash/sumBy'
import { getCarTypeByClass, useCarTypes } from 'application/core/domain/useCase/car/carActions'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import { useCategories } from 'application/core/domain/useCase/categories/getCategories'
import { Product } from 'application/core/domain/entity/product/Product'
import { useActuallyOrders } from 'application/core/domain/useCase/orders/getOrders'
import { useBookingLocks } from 'application/core/domain/useCase/booking/bookingLocks'
import { reachGoalSelectBookingTime } from 'application/core/utils/metrika/yandexReachGoals'

import s from './BookingPage.module.scss'
import ProductItem from './components/ProductItem'
import TimesList from './components/TimesList'
import ActuallyOrder from './components/ActuallyOrder'
import DateSelector from './components/DateSelector'

let timer: any

const BookingPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const productsContainerRef = useRef<any>()
  const { outlet, token, basket, car } = useAppSelector(
    ({ userReducer, basketReducer, carsReducer }) => ({
      outlet: userReducer.outlet,
      token: userReducer.token,
      basket: basketReducer.basket,
      car: carsReducer.currentCar,
    }),
  )
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState<number>(0)

  const [selectedDate, setSelectedDate] = useState<Moment>(moment())

  const { data: bookingList, isLoading: bookingListIsLoading } = useBookingList(
    outlet ? outlet.id : 1,
  )
  const { data: productsData, isLoading: productsIsLoading } = useProducts(outlet ? outlet.id : 1)
  const { data: bookingTables, isLoading: bookingTablesIsLoading } = useBookingAreas(
    outlet ? outlet.id : 1,
  )
  const { data: categoriesData, isLoading: categoriesIsLoading } = useCategories(
    outlet ? outlet.id : 1,
  )

  const { data: actuallyOrders, isLoading: actuallyOrdersIsLoading } = useActuallyOrders(
    token || '',
  )

  const { data: bookingLocks, isLoading: bookingLocksIsLoading } = useBookingLocks(
    outlet ? outlet.id : 1,
  )

  const carTypes = useCarTypes(outlet)

  const defaultTimeInterval = useMemo(() => {
    const parameter = getOutletAppliedParameter(outlet, 'time_separation')
    return parameter && parameter.value ? parseInt(parameter.value) : 3600
  }, [outlet])

  const products = useMemo(() => {
    if (productsData && categoriesData) {
      const carType = getCarTypeByClass(car, carTypes, outlet)
      const prodArr = _map(productsData, (item) => ({
        ...item,
        varieties: _filter(item.varieties, (v) => v.name === carType.name),
      }))

      const filtered = _filter(prodArr, (p) => (p.varieties[0] ? true : false))
      const withCategories: any[] = []
      categoriesData.map((item: any, i: number) => {
        const categoryProducts = _filter(filtered, { client_category_name: item.name })
        if (categoryProducts && categoryProducts.length) {
          withCategories.push({
            title: item.name,
            id: item.id,
            key: `title_${item.name}`,
            type: 'title',
            products: categoryProducts,
          })
        }
      })

      return withCategories
    }
    return []
  }, [productsData, car, categoriesData, carTypes, outlet])

  const tablesData = useMemo(
    () => getAreasTables(bookingTables && bookingTables.data ? bookingTables.data : []),
    [bookingTables],
  )

  const bookedDates = useMemo(() => {
    const times = getTimes(outlet, selectedDate, defaultTimeInterval)
    const d = getBookedDates({
      bookingList,
      tablesData,
      times: times,
      defaultTimeInterval,
      bookingLocks,
      settlementDate: selectedDate,
    })
    return d || []
  }, [bookingList, tablesData, outlet, defaultTimeInterval, bookingLocks, selectedDate])

  const bookingTimes = useMemo(() => {
    const today = getBookingFreeTimes(bookedDates)
    return today
  }, [bookedDates])

  const splittedTime = useMemo(() => splitBookingTimesToIntervals(bookingTimes), [bookingTimes])

  const onTimeClickHandler = useCallback(
    (timeItem: { id: number; time: string; date: string; table: BookingTable | null }) => {
      setActiveItem(timeItem.id)
      if (productsContainerRef && productsContainerRef.current) {
        productsContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    [],
  )

  const getProductUniqId = useCallback(
    (id: number) => {
      const f = _find(basket, (item) => item.id === id)
      return f ? f.uniq_id : ''
    },
    [basket],
  )

  const lastOrder = useMemo(() => {
    return actuallyOrders && actuallyOrders.length ? actuallyOrders[0] : null
  }, [actuallyOrders])

  const bookingData = useMemo(() => {
    if (activeItem) {
      const timeItem = _find(bookingTimes, { id: activeItem })
      if (timeItem) {
        const momentTime = moment(timeItem.date)
        const basketProductionTime = _sumBy(basket, (item) =>
          parseInt(item.varieties[0].production_time, 10),
        )
        let freeTable = null
        for (let i = 0; i < bookedDates.length; i++) {
          const findedFreeTable = _find(bookedDates[i], (item) => {
            if (
              item.date &&
              item.date.isSame(momentTime, 'day') &&
              item.date.isSame(momentTime, 'minutes') &&
              item.freeTime >= basketProductionTime
            ) {
              return true
            }
            return false
          })
          if (findedFreeTable) {
            freeTable = findedFreeTable
            break
          }
        }
        return freeTable
          ? {
              date: timeItem.date,
              //@ts-ignore
              table: {
                id: freeTable.table_id || 0,
                number: freeTable.number || 0,
              },
            }
          : null
      }
    }
    return null
  }, [activeItem, basket, bookedDates, bookingTimes])

  const createOrderClickHandler = useCallback(() => {
    reachGoalSelectBookingTime()
    setBookingData(
      //@ts-ignore
      bookingData,
    )(dispatch)
    navigate('/create_order')
  }, [navigate, bookingData, dispatch])

  const timeNotAvailable = useMemo(() => {
    if (activeItem && !bookingData) {
      return true
    }
    return false
  }, [activeItem, bookingData])

  const noticeMsg = useMemo(() => {
    if (timeNotAvailable) {
      return 'Мы не можем забронировать вас на такую продолжительность'
    }
    if (!basket.length) {
      return 'Корзина пуста'
    }
    if (!bookingData || !activeItem) {
      return 'Выберите время'
    }
  }, [timeNotAvailable, basket, bookingData, activeItem])

  const onNextDayPressHandler = useCallback(() => {
    const date = moment(selectedDate)
    setSelectedDate(date.add(1, 'day'))
  }, [selectedDate])

  useEffect(() => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (!outlet && location.hash.indexOf('outlets') === -1) {
        navigate('#outlets')
      }
    }, 200)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={s.container}>
      <div className={s.title}>Бронирование</div>
      {bookingListIsLoading ||
      productsIsLoading ||
      bookingTablesIsLoading ||
      actuallyOrdersIsLoading ||
      categoriesIsLoading ? (
        <Loader size={32} />
      ) : (
        <>
          {lastOrder && <ActuallyOrder order={lastOrder} />}
          <div className={s.date_container}>
            <DateSelector onChange={setSelectedDate} selectedDate={selectedDate} outlet={outlet} />
            <TimesList
              data={splittedTime}
              onClick={onTimeClickHandler}
              activeItem={activeItem}
              onNextDayPressHandler={onNextDayPressHandler}
            />
          </div>
          {car || !token ? (
            <>
              <div className={s.products_container} ref={productsContainerRef}>
                <div className={s.title_block}>
                  <div>Все услуги</div>
                </div>
                <HighDemandNotify outlet={outlet} />
                <div className={s.products}>
                  {_map(products, (item) => (
                    <React.Fragment key={item.id}>
                      <div className={s.category_title}>{item.title}</div>
                      {item.products ? (
                        item.products.map((p: Product, j: number) => (
                          <ProductItem
                            item={p}
                            key={`product_item_${p.id}`}
                            productUniqId={getProductUniqId(p.id)}
                          />
                        ))
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <Button
                color="fiol"
                onClick={createOrderClickHandler}
                containerClassName={s.btn_container}
                disabled={noticeMsg ? true : false}
              >
                {noticeMsg ? (
                  <div>{noticeMsg}</div>
                ) : (
                  <>
                    <div>Забронировать</div>
                    <NextArrow />
                  </>
                )}
              </Button>
              <ProductInfoMW />
            </>
          ) : (
            <>
              <ErrorMsg text="Вам необходимо выбрать машину" containerStyle={s.error_msg} />
              <Button color="fiol" isLink to="/cars" containerClassName={s.btn_container}>
                Выбрать машину
              </Button>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(BookingPage)
