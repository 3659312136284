import React, { useMemo } from 'react'
import { useOrder } from 'application/core/domain/useCase/orders/getOrders'
import { useLocation, useParams } from 'react-router'
import { Loader } from 'application/presentation/common/uiComponents'
import { useOutlets } from 'application/core/domain/useCase/outlets/getOutlets'
import _find from 'lodash/find'
import OrderDetailsMW from 'application/presentation/common/ModalWindows/OrderDetailsMW'
import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import ProductInfoMW from 'application/presentation/common/ModalWindows/ProductInfoMW/ProductInfoMW'

import s from './OrderInfoPage.module.scss'
import OpenOrderInfoContent from './components/OpenOrderInfoContent'
import OrderInfoContent from './components/OrderInfoContent'

const OrderInfoPage = () => {
  const { search } = useLocation()
  const params = useParams()

  const isOpenOrder = useMemo(() => {
    const p = new URLSearchParams(search)
    return p.get('is_open_order') ? true : false
  }, [search])

  const orderId = useMemo(() => {
    return params && params.order_id ? parseInt(params.order_id, 10) : 0
  }, [params])
  const { data: outletsData, isLoading: outletsIsLoading } = useOutlets()
  const { data, isLoading } = useOrder(orderId, isOpenOrder)

  const outlet = useMemo(() => {
    if (outletsData && data) {
      return _find(outletsData, (item) => item.id === data.outlet_id)
    }
    return null
  }, [outletsData, data])

  return (
    <PageLayout title={`Заказ №${orderId}`}>
      <div className={s.container}>
        {isLoading || outletsIsLoading ? (
          <div className={s.check_pay}>
            <Loader size={64} color="#4200FF" containerClassName={s.loader} />
          </div>
        ) : data ? (
          isOpenOrder ? (
            <OpenOrderInfoContent data={data} outlet={outlet} />
          ) : (
            <OrderInfoContent data={data} />
          )
        ) : (
          <div>Заказ не найден</div>
        )}
        <OrderDetailsMW />
        <ProductInfoMW />
        {/* <ConfirmationMW {...confirmMwData} /> */}
      </div>
    </PageLayout>
  )
}

export default React.memo(OrderInfoPage)
