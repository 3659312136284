/* eslint-disable @typescript-eslint/no-redeclare */
import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { useState } from 'react'

function toRad(Value: number): number {
  /** Converts numeric degrees to radians */
  return (Value * Math.PI) / 180
}
export const getGeolocationDistance = (lon1: number, lat1: number, lon2: number, lat2: number) => {
  var R = 6371 // Radius of the earth in km
  var dLat = toRad(lat2 - lat1) // Javascript functions in radians
  var dLon = toRad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km
  return d
}

export function getClosestGeolocationItem<T>(
  items: Array<T & { width: number; height: number }>,
  coords: { longitude: number; latitude: number },
): T {
  let minDistance: any
  items.map((item) => {
    const distance = getGeolocationDistance(
      item.height,
      item.width,
      coords.longitude,
      coords.latitude,
    ).toFixed(1)
    if (!minDistance || distance < minDistance.distance) {
      minDistance = { ...item, distance }
    }
  })
  return minDistance
}

export function checkIsNearestOutlet(
  outlets: Array<Outlet>,
  outlet: Outlet | null,
  currentPosition: Geolocation | null,
) {
  if (currentPosition) {
    const closest = getClosestGeolocationItem(outlets, currentPosition)
    if (outlet && closest) {
      return outlet.id === closest.id
    }
  }
  return true
}

export type Geolocation = {
  latitude: number
  longitude: number
  altitude: number | null
  accuracy: number
  altitudeAccuracy: number | null
  heading: number | null
  speed: number | null
}

export const useCurrentPosition = (): Geolocation | null => {
  const [currentPosition, setCurrentPosition] = useState<Geolocation | null>(null)

  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      if (!currentPosition) {
        setCurrentPosition(position.coords)
      }
    })
  }

  return currentPosition
}
