import React, { useMemo, useState } from 'react'
import { SearchInput } from 'application/presentation/common/uiComponents/InputComponents'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import cn from 'classnames'
import { Button } from 'application/presentation/common/uiComponents'
import rus_to_latin from 'application/utils/rusToLatin'

import s from '../SelectCarForm.module.scss'

type Props = {
  carsDB: any
  selectedCarId: string | number
  setSelectedCarModelId: Function
  selectedCarModelId: number | string
  onNextPressHandler: Function
  saveIsLoading: boolean
}

const SelectCarModelStage = ({
  carsDB,
  selectedCarId,
  setSelectedCarModelId,
  selectedCarModelId,
  onNextPressHandler,
  saveIsLoading,
}: Props) => {
  const [searchVal, setSearchVal] = useState<string>('')

  const topCarsModels = useMemo(() => {
    const car = _find(carsDB, (item) => item.id === selectedCarId)
    return car ? car.models : []
  }, [selectedCarId, carsDB])

  const filteredModels = useMemo(() => {
    return _filter(
      topCarsModels,
      (item) =>
        item.name.toLowerCase().indexOf(searchVal.toLocaleLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(rus_to_latin(searchVal.toLowerCase())) !== -1,
    )
  }, [topCarsModels, searchVal])

  return (
    <div>
      <SearchInput
        value={searchVal}
        onChange={setSearchVal}
        placeholder="Введите модель автомобиля"
      />
      {!searchVal ? (
        <>
          <div className={s.list}>
            {topCarsModels.map((item: any) => (
              <div
                className={cn(s.item, { [s.active]: item.id === selectedCarModelId })}
                onClick={() => setSelectedCarModelId(item.id)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className={s.list}>
            <div className={s.list_title}>Результаты поиска</div>
            {filteredModels.map((item) => (
              <div
                className={cn(s.item, { [s.active]: item.id === selectedCarModelId })}
                onClick={() => setSelectedCarModelId(item.id)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </>
      )}
      {selectedCarModelId ? (
        <Button
          text="Далее"
          color="fiol"
          containerClassName={s.next_btn}
          onClick={onNextPressHandler}
          isLoading={saveIsLoading}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(SelectCarModelStage)
