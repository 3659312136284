import React from 'react'

import s from './FormContainer.module.scss'
type Props = {
  title: string
  subtitle?: string
  children: React.ReactNode
}

const FormContainer = ({ title, subtitle, children }: Props) => {
  return (
    <div className={s.container}>
      <div className={s.title}>{title}</div>
      <div className={s.subtitle}>{subtitle}</div>
      <div className={s.form}>{children}</div>
    </div>
  )
}

export default React.memo(FormContainer)
