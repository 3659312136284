import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'application/core/domain/store/hooks'

type Props = {
  children: React.ReactElement
}

const NotPrivateRoute = ({ children }: Props) => {
  const isAuth = useAppSelector(({ userReducer }) => userReducer.token)

  if (isAuth) {
    return <Navigate to="/booking" replace />
  }

  return children
}

export default React.memo(NotPrivateRoute)
