import { Order } from 'application/core/domain/entity/order/Order'
import React, { useMemo } from 'react'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { getProductFullPrice } from 'application/core/domain/entity/product/Product'
import cn from 'classnames'

import s from './OrderItem.module.scss'

type Props = {
  item: Order
  containerClassName?: string
  notNeedSupport?: boolean
}

const OrderItem = ({ item, containerClassName, notNeedSupport }: Props) => {
  const price = useMemo(() => {
    let fullPrice = 0
    item.products.map((p) => {
      fullPrice += getProductFullPrice(p)
    })
    return fullPrice
  }, [item])

  return (
    <NavLink
      className={cn(s.container, containerClassName || '')}
      to={`/orders/${item.order_id ? item.order_id : item.id}`}
    >
      <div className={s.time}>
        {moment(
          item.booking_info && item.booking_info.booking_date
            ? item.booking_info.booking_date
            : item.create_time,
        ).format('DD.MM, в HH:mm')}
      </div>
      <div className={s.list}>
        {item.products.length ? (
          item.products.map((p, index) => (
            <div className={s.list_item} key={`list_item_${p.id}_${index}`}>
              <div className={s.dot}>.</div>
              <div>{p.product_name}</div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <div className={s.bottom_row}>
        {!notNeedSupport && (
          <NavLink
            to={`/support/${item.order_id ? item.order_id : item.id}`}
            className={s.support_link}
            onClick={(e) => e.stopPropagation()}
          >
            Помощь
          </NavLink>
        )}

        <div className={s.price}>{price} ₽</div>
      </div>
    </NavLink>
  )
}

export default React.memo(OrderItem)
