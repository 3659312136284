import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import appLocalStorage from 'application/core/data/appLocalStorage'

import { Outlet } from '../entity/outlet/Outlet'
import { Product } from '../entity/product/Product'
import { Region } from '../entity/region/Region'
import { AuthData, AuthUser } from '../entity/user/User'

type UserState = {
  user: AuthUser
  authData: AuthData
  token: string | null
  cookies: string
  outlet: Outlet | null
  orderResultData?: {
    id: string
    data: Array<Product>
  }
  app_config: any
  region: Region | null
}

const initialState: UserState = {
  user: null,
  token: null,
  cookies: '',
  authData: {
    login: '',
    password: '',
  },
  outlet: null,
  orderResultData: {
    id: '0',
    data: [],
  },
  app_config: {},
  region: null,
}

// TODO: подправить orderResultAction и setAppConfig

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUserAction: (state, { payload: user }: PayloadAction<AuthUser>) => {
      state.user = user
      appLocalStorage.setItem('userData', JSON.stringify(user))
    },
    setUserTokenAction: (state, { payload: token }: PayloadAction<string | null>) => {
      state.token = token
      appLocalStorage.setItem('user_token', token || '')
    },
    setSelectedOutletAction: (state, { payload: outlet }: PayloadAction<Outlet>) => {
      state.outlet = outlet
      appLocalStorage.setItem('selectedBar', JSON.stringify(outlet))
    },
    setCookiesAction: (state, { payload: cookies }: PayloadAction<string>) => {
      state.cookies = cookies
    },
    setUserAuthDataAction: (state, { payload: authData }: PayloadAction<AuthData>) => {
      state.authData = authData
    },
    setAppConfigAction: (state, { payload: app_config }) => {
      state.app_config = app_config
    },
    setOrderResultData: (state, action) => {
      state.orderResultData = action.payload
    },
    setUserRegionAction: (state, { payload: region }: PayloadAction<Region | null>) => {
      state.region = region
    },
  },
})

const {
  setUserAction,
  setUserTokenAction,
  setSelectedOutletAction,
  setOrderResultData,
  setAppConfigAction,
  setUserRegionAction,
} = userReducer.actions

export const setUser = (user: AuthUser) => (dispatch: Dispatch) => dispatch(setUserAction(user))
export const setUserToken = (token: string | null) => (dispatch: Dispatch) =>
  dispatch(setUserTokenAction(token))

export const setOutlet = (outlet: Outlet) => (dispatch: Dispatch) =>
  dispatch(setSelectedOutletAction(outlet))

export const setOrderResult =
  (result: { id: string; data: Array<Product> }) => (dispatch: Dispatch) =>
    dispatch(setOrderResultData(result))

export const setAppConfig = (app_config: any) => (dispatch: Dispatch) =>
  dispatch(setAppConfigAction(app_config))

export const setUserRegion = (region: Region | null) => (dispatch: Dispatch) =>
  dispatch(setUserRegionAction(region))

export default userReducer.reducer
