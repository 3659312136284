import moment from 'moment'

import { BookingDataType } from '../domain/entity/booking/Booking'
import { Outlet } from '../domain/entity/outlet/Outlet'

type Props = {
  outlet: Outlet | null
  bookingData: BookingDataType | null
}

const generateCalendarUrl = ({ outlet, bookingData }: Props) => {
  if (!outlet || !bookingData) {
    return null
  }
  const dates = `${moment(bookingData.date).format()}`
  const details = `Бокс ${bookingData.table.number}, мойка ${outlet.name}`
  const location = `${outlet.address}`
  const text = `Запись на мойку ${outlet.name}`
  return `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${dates}&details=${details}&location=${location}&output=xml%23eventpage_6&pli=1&sf=true&sprop=name:YCLIENTS&sprop=yclients.com/mobile/cabinet/record/7c21fa48bfa6834f1a66161ce5ed0271/&text=${text}&trp=true`
}

export default generateCalendarUrl
