/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash'

import { ProductOption } from './ProductOption'
import { ProductVariety } from './ProductVariety'

export type Product = {
  id: number
  client_category_name: string
  description: string
  image: string
  isDrink: boolean
  more: string
  options: Array<ProductOption>
  selected_options: Array<ProductOption>
  product_id: number
  product_name: string
  uniq_id: string
  uniq_ids: Array<string>
  varieties: Array<ProductVariety>
  variety: ProductVariety
  full_cost: number
  can6: boolean | number
  discont?: number
  temperature?: string
  availability_status?: 'in_stock' | 'ended' | 'not_available'
  is_combo?: boolean
  count: number
}

export const productVarietyIsAvailable = (product: Product): boolean => {
  if (!product.isDrink) {
    return true
  }
  if (product.varieties.length) {
    const available = _.findIndex(product.varieties, (item) =>
      item.availability_status === 'in_stock' ? true : false,
    )
    return available !== -1 ? true : false
  }
  return true
}

export const getProductPrice = (product: Product): number => {
  let tempPrice = 0
  if (product && product.varieties && product.varieties.length) {
    let min_val = _.sortBy(product.varieties, 'cost')
    min_val = _.filter(min_val, (item) => item.availability_status === 'in_stock')
    tempPrice += min_val && min_val[0] ? min_val[0].cost : 0
  }
  return tempPrice
}

export const getProductFullPrice = (product: Product, selectedVolumeId?: number): number => {
  let p = 0
  if (product) {
    if (product.full_cost) {
      return product.full_cost
    }
    const sel: any = _.filter(product.selected_options, { selected: true })
    if (sel) {
      sel.map((item: any) => {
        if (item.items) {
          item.items.map((item2: any) => {
            if (item2.selected) {
              p += item2.cost
            }
          })
        }
      })
    }
    const volume = getSelectedVariety(product.varieties, selectedVolumeId)
    p += volume ? volume.cost : 0
  }

  return p
}

export const getSelectedVariety = (
  varieties: Array<ProductVariety>,
  selectedVolumeId?: number,
): ProductVariety => {
  if (selectedVolumeId) {
    let v = _.find(varieties, (item) => item.id === selectedVolumeId)
    if (v) {
      return v
    }
  }
  let variety = _.find(varieties, { selected: true })
  if (!variety) {
    variety = varieties[0]
  }
  return variety
}

export const getSelectedAdditionals = (
  product: Product,
): Array<ProductVariety & { category: string }> => {
  let arr: Array<ProductVariety & { category: string }> = []
  if (product) {
    let temp = _.filter(product.selected_options, { selected: true })
    temp.map((item) => {
      item.items.map((item2: any) => {
        if (item2.selected) {
          arr.push({ ...item2, category: item.name })
        }
      })
    })
  }
  return arr
}
