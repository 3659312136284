import React from 'react'
import { Button } from 'application/presentation/common/uiComponents'

import s from '../SelectCarForm.module.scss'

type Props = {
  onNextPressHandler: Function
  onBackClick: Function
  saveIsLoading: boolean
  setManualAddCar: Function
  fetchedCar: {
    name: string
    modelName: string
    number: string
  }
}

const CarSubmitStage = ({
  onNextPressHandler,
  onBackClick,
  saveIsLoading,
  fetchedCar,
  setManualAddCar,
}: Props) => {
  return (
    <div className={s.auto_container}>
      <div className={s.inner_container}>
        <div className={s.title}>
          Подскажите, <br />
          Ваш автомобиль?
        </div>
        <div className={s.car_container}>
          <div className={s.top}>
            <div className={s.name}>
              {fetchedCar.name} {fetchedCar.modelName}
            </div>
            <div className={s.number}>
              <span>{fetchedCar.number}</span>
            </div>
          </div>
        </div>
        <Button
          text="Это мой автомобиль"
          color="fiol"
          containerClassName={s.btn}
          onClick={onNextPressHandler}
          isLoading={saveIsLoading}
        />
        <Button
          text="Не мой"
          color="gray"
          containerClassName={s.btn}
          onClick={() => setManualAddCar(true)}
        />
      </div>
    </div>
  )
}

export default React.memo(CarSubmitStage)
