import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as SberpaySvg } from 'images/payIcons/sberpay2.svg'
import { ReactComponent as CardSvg } from 'images/payIcons/card_icon.svg'
import { ReactComponent as MirSvg } from 'images/payIcons/mir.svg'
import { ReactComponent as VisaSvg } from 'images/payIcons/visa.svg'
import { ReactComponent as MasterCardSvg } from 'images/payIcons/mastercard.svg'
import _concat from 'lodash/concat'
import _map from 'lodash/map'
import { useUserPaymentCards } from 'application/core/domain/useCase/payments/userPaymentsCard'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { setPayMethod } from 'application/core/domain/store/paymentReducer'

import s from './PaySelector.module.scss'

const payMethods = [
  {
    id: 5,
    name: 'SberPay',
    icon: <SberpaySvg />,
  },
  { id: 1, name: 'Привязать новую карту', icon: <CardSvg /> },
]

type Props = {
  isBalance?: boolean
}

const PaySelector = ({ isBalance }: Props) => {
  const dispatch = useDispatch()
  const { token, selectedPayMethod } = useAppSelector(({ userReducer, paymentMethodReducer }) => ({
    token: userReducer.token,
    selectedPayMethod: paymentMethodReducer.selectedPayMethod,
  }))
  const { data: cardsData } = useUserPaymentCards(token)

  const onItemClick = useCallback(
    (item: { id: number }) => {
      setPayMethod(item.id)(dispatch)
      localStorage.setItem('last_pay_method', item.id.toString())
    },
    [dispatch],
  )

  const paymentSystems = useMemo(() => {
    if (cardsData) {
      const cards = _map(cardsData, (item) => {
        let icon = null
        switch (item.payment_system) {
          case 'VISA':
            icon = <VisaSvg />
            break
          case 'MASTERCARD':
            icon = <MasterCardSvg />
            break
          case 'MIR':
            icon = <MirSvg />
            break
          default:
            icon = <CardSvg />
            break
        }
        return {
          ...item,
          name: `${item.payment_system ? item.payment_system : ''} ${`**${
            item.card_number ? item.card_number.replace(/X/g, '') : ''
          }`}`,
          icon: icon,
        }
      })
      return _concat(cards, payMethods)
    }
    return payMethods
  }, [cardsData])

  return (
    <>
      <div className={s.out_container}>
        <div className={s.title}>Оплата</div>
        {/* <div className={s.container} onClick={openSelectPayMethod}>
          <div className={s.icon}>{payItemData.icon}</div>
          <div className={s.name}>{payItemData.card ? payItemData.card : payItemData.name}</div>
          <div className={s.trigger}>
            <TriggerSvg />
          </div>
        </div> */}
        <div className={s.list}>
          {paymentSystems.map((item) => {
            const isActive = selectedPayMethod === item.id
            return (
              <div
                className={s.item}
                onClick={() => onItemClick(item)}
                key={`pay_item_${item.name}`}
              >
                <div className={`${s.checkbox} ${isActive ? s.active : ''}`}>
                  {isActive && <div className={s.check_inner}></div>}
                </div>
                <div className={s.icon}>{item.icon}</div>
                <div className={s.name}>{item.name}</div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default React.memo(PaySelector)
