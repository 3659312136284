import { fetchCategories } from 'application/core/data/api/categories'
import { Category } from 'application/core/domain/entity/category/Category'
import { useQuery } from '@tanstack/react-query'

export const useCategories = (outlet_id: number) => {
  const query = useQuery<{ categories: Array<Category> }>(
    ['categories', outlet_id],
    () => fetchCategories(outlet_id),
    {
      enabled: !!outlet_id,
    },
  )

  return {
    ...query,
    data: query.data ? query.data.categories : [],
    isLoading: outlet_id ? query.isLoading : false,
  }
}
