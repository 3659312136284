import React, { useState, useCallback, useEffect, useMemo } from 'react'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import ProductInfoMW from 'application/presentation/common/ModalWindows/ProductInfoMW/ProductInfoMW'
import logoPng from 'images/logo_circle.png'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import PaySelector from 'application/presentation/common/uiComponents/PaySelector/PaySelector'
import { BasketItem, Button, ErrorMsg } from 'application/presentation/common/uiComponents'
import { useNavigate } from 'react-router'
import { useAppSelector } from 'application/core/domain/store/hooks'
import moment from 'moment'
import { mergeBasketData } from 'application/core/domain/useCase/basket/basketActions'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { PaymentProps } from 'application/core/domain/types/PaymentsType'
import mainPayProcess from 'application/core/domain/useCase/payments/MainPayProcess'
import {
  checkOutletWorkTime,
  getOutletAppliedParameter,
} from 'application/core/domain/useCase/outlets/outletActions'
import {
  getProductsIds,
  getSelectedAdditionasIds,
} from 'application/core/domain/useCase/products/productsAction'
import apiClient from 'application/core/data/apiClient/apiClient'
import _sumBy from 'lodash/sumBy'
import cn from 'classnames'
import { ReactComponent as TriggerSvg } from 'images/icons/black_down_trigger.svg'
import { useProducts } from 'application/core/domain/useCase/products/getProducts'
import { getCarTypeByClass, useCarTypes } from 'application/core/domain/useCase/car/carActions'
import { Product } from 'application/core/domain/entity/product/Product'
import { setBasket } from 'application/core/domain/store/basketReducer'
import { useDispatch } from 'react-redux'
import { reachGoalCreateOrderClick } from 'application/core/utils/metrika/yandexReachGoals'

import { useBasket } from './useBasket'
import Additionals from './components/Additionals'
import s from './CreateOrderPage.module.scss'

const basketItems = [
  {
    id: 1,
    name: 'Твердый воск',
    desc: 'AS+SP+BT+AS+IR+SP+RE+HD',
    cost: 3000,
    time: 40,
  },
  {
    id: 2,
    name: '3-х фазная мойка',
    desc: 'AS+SP+SW+турбопушка',
    cost: 700,
    time: 40,
  },
]

const CreateOrderPage = () => {
  const dispatch = useDispatch()
  const { basketData, user, outlet, bookingData, token, selectedPayMethod, currentCar } =
    useAppSelector(({ userReducer, basketReducer, paymentMethodReducer, carsReducer }) => ({
      user: userReducer.user,
      basketData: basketReducer.basket,
      outlet: userReducer.outlet,
      bookingData: basketReducer.bookingData,
      token: userReducer.token,
      selectedPayMethod: paymentMethodReducer.selectedPayMethod,
      currentCar: carsReducer.currentCar,
    }))

  const logoImage = useMemo(() => {
    const p = getOutletAppliedParameter(outlet, 'logo')
    return p?.value ? `${apiClient.defaults.baseURL}${p.value}` : logoPng
  }, [outlet])

  const navigate = useNavigate()
  const [comment, setComment] = useState<string>('')
  const [orderIsLoading, setOrderIsLoading] = useState<boolean>(false)
  const { data: productsData, isLoading: productsIsLoading } = useProducts(outlet ? outlet.id : 0)

  const carTypes = useCarTypes(outlet)

  const products = useMemo(() => {
    if (productsData && currentCar) {
      const carType = getCarTypeByClass(currentCar, carTypes, outlet)
      const prodArr = _map(productsData, (item) => ({
        ...item,
        varieties: _filter(item.varieties, (v) => v.name === carType.name),
      }))
      return _filter(prodArr, (p) => (p.varieties[0] ? true : false))
    }
    return []
  }, [productsData, currentCar, carTypes, outlet])

  const {
    isLoading: basketIsLoading,
    fullPrice,
    somethingNotInStock,
    calculatedBasket,
    error,
  } = useBasket({ outlet, basket: basketData, token })

  const mergedBasketData = useMemo(() => {
    const merged = mergeBasketData(basketData, calculatedBasket)
    return merged
  }, [basketData, calculatedBasket])

  const fullProductionTime = useMemo(() => {
    return _sumBy(basketData, (item) => parseInt(item.varieties[0].production_time, 10)) / 60
  }, [basketData])

  const onTimeClick = useCallback(() => navigate(-1), [navigate])
  const onCarsClick = useCallback(() => navigate('/cars'), [navigate])

  const onPayStart = useCallback(() => {
    setOrderIsLoading(true)
  }, [])

  const onPaySuccess = useCallback(
    (status: string, response?: any) => {
      if (status === 'success') {
        setOrderIsLoading(false)
        navigate(`/order_result?open_order_id=${response}`)
      } else {
        setOrderIsLoading(false)
        // console.log(response)
        if (response.redirect) {
          navigate(response.redirect, { replace: true })
        } else {
          // if (
          //   response.externalParams &&
          //   response.externalParams.sbolDeepLink &&
          //   response.externalParams.sbolDeepLink !== 'mockDeepLink'
          // ) {
          //   let isBlocked = false
          //   try {
          //     var win = window.open(response.externalParams.sbolDeepLink)
          //     if (win == null) {
          //       isBlocked = true
          //     }
          //   } catch (e) {
          //     isBlocked = true
          //   }
          //   if (isBlocked) {
          //     const url = response.externalParams.sbolDeepLink.replace('sberpay', 'sbolpay')
          //     window.location.href = url
          //   }
          // } else {
          window.location.href = response.formUrl
          // }
        }
      }
    },
    [navigate],
  )
  const onPayError = useCallback((type?: string, message?: string) => {
    ToastNotify(message ? message : 'Ошибка при совершении платежа')
    setOrderIsLoading(false)
  }, [])

  const payProcess = useCallback(
    (order_id: number, type: string) => {
      const payData = {
        amount: fullPrice,
        ip_address: '',
        card_name: '',
        order_id: order_id,
        selected_pay_method: type === 'balance' || fullPrice === 0 ? 4 : selectedPayMethod,
        returnUrl: `${window.location.origin}/order_result?open_order_id=${order_id}`,
      }
      const paymentFuncData: PaymentProps = {
        //@ts-ignore
        data: payData,
        onStart: onPayStart,
        onSuccess: onPaySuccess,
        onError: onPayError,
      }
      reachGoalCreateOrderClick()
      mainPayProcess(paymentFuncData)
    },
    [fullPrice, selectedPayMethod, onPayStart, onPaySuccess, onPayError],
  )

  const timeNotAvailable = useMemo(() => {
    if (outlet && bookingData && fullProductionTime) {
      const endTime = outlet.time_work.split('-')[1].split(':')
      const endTimeMinutes = +endTime[0] * 60 + +endTime[1]
      const bookingTime = moment(bookingData?.date).format('HH:mm').split(':')
      const bookingTimeMinutes = +bookingTime[0] * 60 + +bookingTime[1]
      const availableTime = endTimeMinutes - bookingTimeMinutes
      if (availableTime < fullProductionTime) {
        return true
      } else {
        return false
      }
    }
    return false
  }, [bookingData, fullProductionTime, outlet])

  const createOrder = useCallback(
    async (type: string) => {
      if (!user) {
        return navigate('/authorization?from_create_order=true')
      }
      if (!outlet) {
        ToastNotify('Не выбрана точка выдачи')
        return false
      }
      if (outlet && checkOutletWorkTime(outlet)) {
        ToastNotify('Выбранная точка не принимает заказы. Повторите попытку в рабочее время.')
        return false
      }
      // if (parseInt(bookingData?.date, 10)) / 60 )
      if (mergedBasketData && mergedBasketData.length) {
        setOrderIsLoading(true)

        const commentData: string = JSON.stringify({ car: currentCar, comment: comment || '' })
        let sendData = {
          outletId: outlet.id,
          orderItems: [],
          paymentType: type === 'balance' || fullPrice === 0 ? 'manual' : 'app',
          comment: commentData,
          booking_status: 0,
          booking_date: bookingData ? bookingData.date : '',
          outlet_area_table_id: bookingData ? bookingData.table.id : 0,
        }
        if (!user) {
          // @ts-ignore
          sendData.client_id = 0
        }

        sendData.orderItems = mergedBasketData.map((item: any) => {
          const volume = _find(item.varieties, { selected: true })
          return {
            id: item.id,
            temperature: item.temperature,
            variety_id: volume ? volume.variety_id : null,
            options: getSelectedAdditionasIds(item),
          }
        })

        apiClient
          .post('/api/sendDistanceOrder', sendData)
          .then((response: any) => {
            if (response.data.status === 'FAIL') {
              ToastNotify(response.data.message)
              setOrderIsLoading(false)
            } else if (response.data.open_order) {
              // setOpenOrderId(response.data.open_order.id)
              if (fullPrice === 0) {
                onPaySuccess('success', response.data.open_order.id)
              } else {
                payProcess(response.data.open_order.id, type)
              }
            }
          })
          .catch(() => {
            ToastNotify('Произошла ошибка, попробуйте позже')
            setOrderIsLoading(false)
          })
      } else {
        ToastNotify('Корзина пустая!')
      }
    },
    [
      outlet,
      mergedBasketData,
      fullPrice,
      comment,
      bookingData,
      user,
      onPaySuccess,
      payProcess,
      currentCar,
      navigate,
    ],
  )

  const changeProductsByCar = useCallback(() => {
    if (products && products.length) {
      const basketIds = getProductsIds(basketData)
      const newBasket: Array<Product> = []
      _map(products, (item) => {
        if (basketIds.indexOf(item.id) !== -1) {
          newBasket.push({ ...item, varieties: [{ ...item.varieties[0], selected: true }] })
        }
      })
      setBasket(newBasket)(dispatch)
    }
  }, [basketData, products, dispatch])

  useEffect(() => {
    if (currentCar) {
      changeProductsByCar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCar, products])

  return (
    <div className={s.container}>
      <div className={s.title}>Оформление заказа</div>
      <div className={s.inner_container}>
        <div className={s.left}>
          <div className={s.wash_block}>
            <div className={s.image}>
              <img src={logoImage} alt="" />
            </div>
            <div className={s.wash_info}>
              <div className={s.wash_name}>{outlet?.name}</div>
              <div className={s.wash_address}>{outlet?.address}</div>
            </div>
            <div>
              <div className={s.time} onClick={onTimeClick}>
                к {moment(bookingData?.date).format('H:mm')}
              </div>
              <div className={s.booking_date}>{moment(bookingData?.date).format('DD.MM.YY')}</div>
            </div>
          </div>
          <div className={s.separator}></div>
          <div className={s.user_block}>
            <div className={s.user_info}>
              <div className={s.label}>Вас зовут</div>
              <div className={s.value}>{user?.name || 'Неавторизованный пользователь'}</div>
            </div>
            {user ? (
              <div className={s.user_info_right}>
                <div className={s.label}>Ваш номер телефона</div>
                <div className={s.value}>{user?.phone}</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={s.separator}></div>
          <div className={cn(s.user_block, s.cars)} onClick={onCarsClick}>
            <div>
              <div className={s.label}>Автомобиль</div>
              {currentCar ? (
                <div className={s.value}>
                  {currentCar?.number} {currentCar?.regionNumber}{' '}
                  <b>
                    {currentCar.name} {currentCar?.modelName}
                  </b>
                </div>
              ) : (
                <div className={s.value}>Автомобиль не выбран</div>
              )}
            </div>
            <div className={s.trigger}>
              <TriggerSvg />
            </div>
          </div>
          <div className={s.separator}></div>
          <div className={s.comment_block}>
            <div className={s.value}>Комментарий специалисту</div>
            <UniversalInputComponent
              type="textarea"
              value={comment}
              onChange={setComment}
              placeholder="Напишите комментарий"
              containerClassName={s.input_container}
            />
          </div>
          <div className={s.separator}></div>
          <PaySelector isBalance={false} />
        </div>
        <div className={s.right}>
          <div className={s.basket}>
            {_map(mergedBasketData, (item) => (
              <BasketItem item={item} key={`basket_item_${item.id}`} canRemove />
            ))}
            <Additionals />
          </div>
        </div>
      </div>

      {error ? <ErrorMsg text={error} /> : <></>}

      <Button
        color="fiol"
        onClick={createOrder}
        containerClassName={s.btn_container}
        disabled={somethingNotInStock || !!error || timeNotAvailable}
        isLoading={basketIsLoading || orderIsLoading}
      >
        {!basketData.length && !somethingNotInStock ? (
          'Корзина пуста'
        ) : somethingNotInStock && basketData.length ? (
          <div className={s.btn_title}>Продукт из корзины в данный момент не активен</div>
        ) : timeNotAvailable ? (
          <div className={s.btn_title}>
            Мы не можем забронировать вас на такую продолжительность
          </div>
        ) : (
          <>
            <div className={s.btn_title}>Оплатить</div>
            <div className={s.btn_subtitle}>
              {fullPrice} ₽ <span>.</span> {fullProductionTime} мин
            </div>
          </>
        )}
      </Button>
      <ProductInfoMW />
    </div>
  )
}

export default React.memo(CreateOrderPage)
