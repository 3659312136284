import React, { useCallback } from 'react'
import cn from 'classnames'

import s from './Tips.module.scss'

const tipsVariety = [
  {
    id: 1,
    value: 0,
  },
  {
    id: 2,
    value: 5,
  },
  {
    id: 3,
    value: 10,
  },
  {
    id: 4,
    value: 15,
  },
]

type Props = {
  value: number
  onChange: Function
  containerClassName?: string
}

const Tips = ({ value, onChange, containerClassName }: Props) => {
  const onItemClick = useCallback((val: number) => onChange(val), [onChange])

  return (
    <div className={cn(s.tips, containerClassName || '')}>
      {tipsVariety.map((item) => (
        <div
          className={cn(s.item, { [s.active]: value === item.value })}
          key={`tips_item_${item.id}`}
          onClick={() => onItemClick(item.value)}
        >
          {item.value} %
        </div>
      ))}
    </div>
  )
}

export default React.memo(Tips)
