import {
  fetchActuallyOrders,
  fetchLastOrders,
  fetchOrder,
  fetchOrdersHistory,
} from 'application/core/data/api/orders'
import { Order } from 'application/core/domain/entity/order/Order'
import { useQuery } from '@tanstack/react-query'
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'
import _filter from 'lodash/filter'

export const useOrdersHistory = (user_token: string) => {
  const query = useQuery(['orders', user_token || ''], fetchOrdersHistory, {
    enabled: user_token ? true : false,
    // refetchInterval: user_token ? 10000 : false,
    retry: false,
  })

  if (query.data) {
    return {
      ...query,
      data: query.data.last_five_finish_orders
        ? _orderBy(query.data.last_five_finish_orders, 'order_id', 'desc')
        : [],
      openOrders: query.data.open_order
        ? _orderBy(
            _filter(query.data.open_order, (item) => item.status !== 'Отменен'),
            'id',
            'desc',
          )
        : [],
      freeCup: query.data.free_cup ? query.data.free_cup : 5,
    }
  }

  return { ...query, data: [], freeCup: 5, openOrders: [] }
}

export const useActuallyOrders = (user_token: string) => {
  const query = useQuery(['actually', user_token || ''], fetchActuallyOrders, {
    enabled: user_token ? true : false,
    refetchInterval: user_token ? 10000 : false,
    retry: false,
  })

  return {
    ...query,
    isLoading: user_token ? query.isLoading : false,
    data:
      query.data && query.data.open_orders
        ? query.data.open_orders.filter(
            (item: Order) => item.status !== 'Ожидает оплаты' && item.status !== 'Отменен',
          )
        : [],
  }
}

export const useOrder = (id: number, isOpenOrder: boolean) => {
  const query = useQuery<Order>(
    [isOpenOrder ? 'open_order' : 'order', id],
    () => fetchOrder(id, isOpenOrder),
    {
      enabled: id ? true : false,
      refetchInterval: 10000,
    },
  )
  return query
}

export const useLastOrders = (enabled = true) => {
  const query = useQuery(['lastOrders'], fetchLastOrders, {
    refetchInterval: 20000,
    enabled,
  })
  return {
    ...query,
    data: query.data ? query.data.map((item: any) => _sortBy(item, 'id')) : undefined,
  }
}
