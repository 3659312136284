import apiClient from 'application/core/data/apiClient'

export const fetchUser = async (token: string | null) => {
  if (token) {
    const { data } = await apiClient.get('/api/user', {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    })
    return data
  }
  return null
}

export const editUserRequest = async (userData: { name: string }) => {
  const { data } = await apiClient.post('/api/personal', userData)
  return data
}
