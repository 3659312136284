import React, { useState, useCallback } from 'react'
import { UniversalInputComponent } from 'application/presentation/common/uiComponents/InputComponents'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { Button } from 'application/presentation/common/uiComponents'
import { editUser } from 'application/core/domain/useCase/user/userActions'
import queryClient from 'application/core/data/apiClient/queryClient'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { useNavigate } from 'react-router'
import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import { setUser } from 'application/core/domain/store/userReducer'
import { useDispatch } from 'react-redux'

import s from './ProfilePage.module.scss'

const ProfilePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const [name, setName] = useState<string>(user?.name || '')
  const [phone, setPhone] = useState<string>(user?.phone || '')
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false)

  const saveChanges = useCallback(() => {
    setEditIsLoading(true)
    editUser({ name: name })
      .then(() => {
        queryClient.invalidateQueries(['profile'])
        setEditIsLoading(false)
        setUser(
          //@ts-ignore
          { ...user, name },
        )(dispatch)
        ToastNotify('Профиль отредактирован!')
        navigate(-1)
      })
      .catch(() => {
        setEditIsLoading(false)
        ToastNotify('Произошла ошибка')
      })
  }, [name, navigate, user, dispatch])

  return (
    <PageLayout title="Профиль">
      <div className={s.container}>
        <UniversalInputComponent type="text" value={name} onChange={setName} title="Ваше имя" />
        <UniversalInputComponent
          type="text"
          value={phone}
          onChange={() => {}}
          title="Ваш телефон"
          disabled
        />
        <div className={s.delete_btn}>Удалить профиль</div>
        <Button
          color="fiol"
          text="Сохранить"
          containerClassName={s.btn_container}
          onClick={saveChanges}
          isLoading={editIsLoading}
        />
      </div>
    </PageLayout>
  )
}

export default React.memo(ProfilePage)
