import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import _filter from 'lodash/filter'
import appLocalStorage from 'application/core/data/appLocalStorage'

import { Car, Cars } from '../entity/car/Car'

type CarReducerState = {
  cars: Cars
  currentCar: Car | null | undefined
}

const initialState: CarReducerState = {
  currentCar: undefined,
  cars: [],
}

export const carsReducer = createSlice({
  name: 'carsReducer',
  initialState,
  reducers: {
    setCurrentCarAction: (state, { payload: car }: PayloadAction<Car | null>) => {
      state.currentCar = car
      if (car) {
        appLocalStorage.setItem('currentCar', JSON.stringify(car))
      } else {
        appLocalStorage.removeItem('currentCar')
      }
    },
    addCarAction: (state, { payload: car }: PayloadAction<Car>) => {
      state.cars.push(car)
    },
    removeCarAction: (state, { payload: id }: PayloadAction<number>) => {
      state.cars = _filter(state.cars, (item) => item.id !== id)
    },
  },
})

const { addCarAction, removeCarAction, setCurrentCarAction } = carsReducer.actions

export const setCar = (car: Car | null) => (dispatch: Dispatch) =>
  dispatch(setCurrentCarAction(car))
export const addCar = (car: Car) => (dispatch: Dispatch) => dispatch(addCarAction(car))
export const removeCar = (id: number) => (dispatch: Dispatch) => dispatch(removeCarAction(id))

export default carsReducer.reducer
