import { HeadProvider } from 'react-head'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from 'application/core/data/apiClient/queryClient'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import moment from 'moment'
import store from 'application/core/domain/store/reduxStore'
import { Authorization as AuthProvider } from 'application/presentation/context/Authorization'
import Modal from 'react-modal'

import { ErrorHandler } from './ErrorBoundary'
import AppView from './AppView'
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/ru'
import './app.scss'

moment.locale('ru')
Modal.setAppElement('#root')

function App() {
  return (
    <BrowserRouter>
      <ErrorHandler>
        {/* @ts-ignore */}
        <HeadProvider>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <AuthProvider>
                <AppView />
              </AuthProvider>
            </Provider>
          </QueryClientProvider>
        </HeadProvider>
      </ErrorHandler>
    </BrowserRouter>
  )
}

export default App
