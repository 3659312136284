import React, { useCallback, useMemo } from 'react'
import logoPng from 'images/logo.png'
import { useAppSelector } from 'application/core/domain/store/hooks'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as RightTrigger } from 'images/icons/right_trigger.svg'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import apiClient from 'application/core/data/apiClient/apiClient'

import s from './Header.module.scss'
import UserMenu from './components/UserMenu'

const Header = () => {
  const navigate = useNavigate()
  const { user, outlet } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    outlet: userReducer.outlet,
  }))
  const logoImage = useMemo(() => {
    const p = getOutletAppliedParameter(outlet, 'logo')
    return p?.value ? `${apiClient.defaults.baseURL}${p.value}` : logoPng
  }, [outlet])
  const openOutlets = useCallback(() => navigate('#outlets'), [navigate])
  const showOutletSelector = useMemo(
    () =>
      //@ts-ignore
      window.ENV && window.ENV.outletId ? false : true,
    [],
  )
  return (
    <div className={s.container}>
      <NavLink className={s.logo} to="/booking">
        <img src={logoImage} alt="" />
      </NavLink>
      {showOutletSelector && (
        <div className={s.outlet} onClick={openOutlets}>
          <div>
            <div className={s.city}>{outlet ? outlet.region.name : ''}</div>
            <div className={s.outlet_name}>{outlet ? outlet.name : 'Точка не выбрана'}</div>
          </div>
          <div className={s.trigger}>
            <RightTrigger />
          </div>
        </div>
      )}

      <UserMenu user={user} />
    </div>
  )
}

export default React.memo(Header)
