import { fetchOutlets } from 'application/core/data/api/outlets'
import { RootState } from 'application/core/domain/store/reduxStore'
import _ from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import { Outlet } from '../../entity/outlet/Outlet'

const alohaIds = [40]

export const getOutlets = async (outlet_id?: number) => {
  const data = await fetchOutlets()
  if (outlet_id) {
    const isAloha = alohaIds.indexOf(outlet_id) !== -1
    return {
      outlets: _.filter(data.outlets, (item: Outlet) =>
        isAloha ? alohaIds.indexOf(item.id) !== -1 : alohaIds.indexOf(item.id) === -1,
      ),
    }
  }
  return data
}

export const useOutlets = () => {
  const region = useSelector(({ userReducer }: RootState) => userReducer.region)
  const query = useQuery<{ outlets: Array<Outlet> }>(
    ['outlets', region ? region.id : 0],
    () => fetchOutlets(region ? region.id : 0),
    {
      refetchInterval: 60000,
    },
  )

  return {
    ...query,
    data: query.data
      ? _.filter(query.data.outlets, (item) => alohaIds.indexOf(item.id) === -1)
      : [],
  }
}
