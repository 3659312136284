import {
  cancelOpenOrderRequest,
  createBaristaOrderRequest,
  rateOrderRequest,
} from 'application/core/data/api/orders'
import { useMutation } from '@tanstack/react-query'
import queryClient from 'application/core/data/apiClient/queryClient'
import _filter from 'lodash/filter'

import { CallbackType } from '../../types/callback'

export const createBaristaOrder = async ({
  onSuccess,
  onError,
  sendData,
}: {
  sendData: any
} & CallbackType) => {
  try {
    const data = await createBaristaOrderRequest(sendData)
    return onSuccess(data)
  } catch (e) {
    return onError(e)
  }
}

export const rateOrder = rateOrderRequest

type CancelOrderMutationDataType = {
  orderId: number
  comment: string
}

export const cancelOpenOrder = cancelOpenOrderRequest

export const CancelOrderMutation = (callback?: Function) => {
  const mutation = useMutation(
    ({ orderId, comment }: CancelOrderMutationDataType) =>
      cancelOpenOrderRequest({ open_order_id: orderId, comment }),
    {
      onMutate: async ({ orderId }) => {
        await queryClient.cancelQueries(['lastOrders'])
        const previousData = queryClient.getQueryData<any>(['lastOrders'])
        if (previousData) {
          let newData = _filter(previousData, (item) => item[0].order_id !== orderId)
          queryClient.setQueryData(['lastOrders'], newData)
          if (callback) {
            callback(newData)
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['lastOrders'], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['lastOrders'])
      },
    },
  )
  return mutation
}
