/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-use-before-define */
import apiClient from 'application/core/data/apiClient'

import { PaymentDataProps, PaymentProps } from '../../types/PaymentsType'

const sberPay = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  onStart()

  sberPayProcess(data, onSuccess, onError)
}

const sberPayProcess = (payData: PaymentDataProps, onSuccess: Function, onError: Function) => {
  const { amount, order_id, returnUrl, os_type, deep_link } = payData
  const data = {
    amount,
    open_order_id: order_id,
    type: 'sberpay',
    method: 'sberpay',
    os_type: os_type || '',
    return_url: returnUrl || `/basket/order/result?open_order_id=${order_id}`,
  }
  if (deep_link) {
    //@ts-ignore
    data.deep_link = deep_link
  }
  // if (/Android/i.test(window.navigator.userAgent)) {
  //   //@ts-ignore
  //   data.os_type = 'android'
  // } else {
  //   //@ts-ignore
  //   data.os_type = 'ios'
  // }
  apiClient({
    method: 'POST',
    url: '/api/payment',
    data: data,
  })
    .then(async (response) => {
      if (response.data) {
        onSuccess('3ds', response.data)
      }
    })
    .catch(({ response }: any) => {
      const error =
        response && response.data
          ? response.data.errorTypeName
            ? response.data.errorMessage
            : response.data.message
            ? response.data.message
            : 'Произошла ошибка при совершении оплаты'
          : 'Произошла ошибка при совершении оплаты'
      onError('error', error)
    })
}

export default sberPay
