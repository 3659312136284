import { Button, Loader } from 'application/presentation/common/uiComponents'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import cn from 'classnames'

import MWContainer from '../MWContainer'
import Tips from '../../uiComponents/Tips/Tips'

import s from './SelectTipsMW.module.scss'

const tipsVariety = [
  {
    id: 1,
    value: 0,
  },
  {
    id: 2,
    value: 5,
  },
  {
    id: 3,
    value: 10,
  },
  {
    id: 4,
    value: 15,
  },
]

type Props = {
  value: number
  onChange: Function
}

const SelectTipsMW = ({ value, onChange }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('select_tips') !== -1
  }, [location])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (location.hash.indexOf('select_tips') !== -1) {
          navigation(-1)
        }
      }}
    >
      <div className={s.container}>
        <div className={s.title}>Чаевые</div>
        <Tips containerClassName={s.tips} value={value} onChange={onChange} />
        <div className={s.notice}>
          Чаевые будут списаны при оплате банковской картой, если обслуживание вам понравится. В
          конце мойки вы всегда сможете изменить размер чаевых.
        </div>
        <Button
          color="fiol"
          text="Сохранить"
          onClick={() => navigation(-1)}
          containerClassName={s.btn_container}
        />
      </div>
    </MWContainer>
  )
}

export default React.memo(SelectTipsMW)
