import { Button, Loader } from 'application/presentation/common/uiComponents'
import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useProduct } from 'application/core/domain/useCase/products/getProducts'
import { useAppSelector } from 'application/core/domain/store/hooks'

import MWContainer from '../MWContainer'

import s from './ProductInfo.module.scss'

const ProductInfoMW = () => {
  const { outlet } = useAppSelector(({ userReducer }) => ({
    outlet: userReducer.outlet,
  }))
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf('info') !== -1
  }, [location])

  const productId = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const id = params.get('product_id')
    return id ? parseInt(id, 10) : 0
  }, [location])

  const { data, isLoading } = useProduct(productId, outlet ? outlet.id : 1)

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (location.hash.indexOf('info') !== -1) {
          navigation(-1)
        }
      }}
    >
      <div className={s.container}>
        {isLoading ? (
          <Loader size={32} color="#4200ff" />
        ) : data ? (
          <>
            <div className={s.title_container}>
              <div>{data.product_name}</div>
              <div>{data.varieties[0].cost} ₽</div>
            </div>
            <div className={s.subtitle_container}>
              <div>{/* AS+HS+турбосушка */}</div>
              <div>60 мин</div>
            </div>
            <div className={s.txt}>
              {data.description || ''}
              {/* Химчистка кузова - регулярная процедура для поддержания насыщенного цвета автомобиля и
              сохранение блеска лакированного покрытия. Это универсальная процедура, которая
              подходит всем автомобилям. Мойка включает чистку всех деталей кузова, дверных проемов,
              дисков и покрышек.
              <br />
              <br />
              AS - я не знаю что это такое
              <br />
              <br />
              HS - тоже не знаю что это такое */}
            </div>
          </>
        ) : (
          <></>
        )}

        <Button
          color="gray"
          text="Понятно"
          onClick={() => navigation(-1)}
          containerClassName={s.btn_container}
        />
      </div>
    </MWContainer>
  )
}

export default React.memo(ProductInfoMW)
