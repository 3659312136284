import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://isdon.ru',
  withCredentials: false,
  headers: {
    // Authorization: `Bearer ${AsyncStorage.getItem('userToken').then((value: any) => {
    //   return value
    // })}`,
    // 'Content-Type': 'application/vnd.api+json',
  },
})

export default apiClient
