import { Button } from 'application/presentation/common/uiComponents'
import React, { useCallback, useState } from 'react'
import ToastNotify from 'application/presentation/context/ToastNotify/ToastNotify'
import { getCarByNumber } from 'application/core/domain/useCase/av100/autoDetectCar'

import s from '../SelectCarForm.module.scss'
import { MaskedTextInput } from '../../uiComponents/InputComponents'

type Props = {
  carNumber: string
  setCarNumber: Function
  onNumberCheck: Function
}

const SelectCarNumberStage = ({ carNumber, setCarNumber, onNumberCheck }: Props) => {
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const savePressHandler = useCallback(() => {
    setSaveIsLoading(true)
    const formattedNumber = carNumber.replace(/ /g, '')

    getCarByNumber(formattedNumber)
      .then((responseData) => {
        // console.log(responseData)
        onNumberCheck(responseData)
      })
      .catch(() => {
        ToastNotify('Ошибка автоопределения')
        onNumberCheck(null)
      })
  }, [carNumber, onNumberCheck])

  return (
    <div>
      <MaskedTextInput
        value={carNumber}
        onChange={(val: string) => setCarNumber(val.toUpperCase())}
        mask="r 999 rr   999"
        maskChar=""
        placeholder="A 000 AA   21"
        containerClassName={s.car_number_container}
        inputClassName={s.car_number_input}
      />
      <Button
        text="Добавить"
        color="fiol"
        containerClassName={s.add_car_btn}
        disabled={carNumber.length < 13}
        isLoading={saveIsLoading}
        onClick={savePressHandler}
      />
    </div>
  )
}

export default React.memo(SelectCarNumberStage)
