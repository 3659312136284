import React, { useCallback } from 'react'
import { ReactComponent as BackSvg } from 'images/icons/back_icon.svg'
import { useNavigate } from 'react-router'

import s from './PageLayout.module.scss'

type Props = {
  children: React.ReactNode
  title: string
  rightComponent?: React.ReactNode
}

const PageLayout = ({ children, title, rightComponent }: Props) => {
  const navigate = useNavigate()

  const onBackClickHandler = useCallback(() => navigate(-1), [navigate])

  return (
    <div className={s.page_layout}>
      <div className={s.top_block}>
        <div className={s.back_btn} onClick={onBackClickHandler}>
          <BackSvg />
        </div>
        <div className={s.title}>{title}</div>
        <div className={s.right}>{rightComponent || <></>}</div>
      </div>
      <div className={s.content}>{children}</div>
    </div>
  )
}

export default React.memo(PageLayout)
