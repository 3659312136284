import React, { useCallback } from 'react'
import { ReactComponent as CheckSvg } from 'images/icons/yellow_check.svg'

import s from './PaymentSystemItem.module.scss'

type Props = {
  item: {
    id: number
    name: string
    icon: JSX.Element
  }
  isActive: boolean
  onItemClick: Function
}

const PaymentSystemItem = ({ item, isActive, onItemClick }: Props) => {
  const onClick = useCallback(() => onItemClick(item), [onItemClick, item])
  return (
    <div className={s.container} onClick={onClick}>
      <div className={s.icon}>{item.icon}</div>
      <div className={s.name}>{item.name}</div>
      <div className={s.check_container}>{isActive && <CheckSvg />}</div>
    </div>
  )
}

export default React.memo(PaymentSystemItem)
