import React from 'react'
import { ReactComponent as StarSvg } from 'images/icons/rate_star.svg'
import cn from 'classnames'

import s from './RatingIcon.module.scss'

type Props = {
  value: number
  containerClassName?: string
}

const RatingIcon = ({ value, containerClassName }: Props) => {
  return (
    <div className={cn(s.rate, containerClassName || '')}>
      <StarSvg />
      <div>{value}</div>
    </div>
  )
}

export default React.memo(RatingIcon)
