import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
// import { ReactComponent as CoffeIcon } from 'images/icons/coffe_icon_2.svg'
import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { ReactComponent as LocationSvg } from 'images/icons/location_icon.svg'
import { getOutletAppliedParameter } from 'application/core/domain/useCase/outlets/outletActions'
import apiClient from 'application/core/data/apiClient/apiClient'

import RatingIcon from '../RatingIcon/RatingIcon'

import s from './OutletItem.module.scss'

type Props = {
  data: Outlet
  onClick: Function
  isActive?: boolean
}

const OutletItem = ({ data, onClick, isActive }: Props) => {
  const clickHandler = useCallback(() => {
    // if (data.is_distance_order) {
    onClick(data)
    // }
  }, [onClick, data])

  const parameters = useMemo(() => {
    const rateParam = getOutletAppliedParameter(data, 'rate')
    const logoParam = getOutletAppliedParameter(data, 'logo')
    return {
      rate: rateParam?.value || '0',
      logo: logoParam?.value || null,
    }
  }, [data])

  return (
    <div className={cn(s.container, isActive ? s.active : '')} onClick={clickHandler}>
      <div
        className={cn({
          [s.left]: true,
          // [s.gray]: !data.is_distance_order
        })}
      >
        <div className={s.title}>
          {data.name}{' '}
          {parameters.rate && parameters.rate !== '0' ? (
            <RatingIcon value={parseInt(parameters.rate, 10)} containerClassName={s.rate} />
          ) : (
            ''
          )}
        </div>
        <div className={s.addr}>{data.address}</div>
        <div className={s.time}>{data.time_work}</div>
      </div>
      <div className={s.right}>
        {parameters.logo ? (
          <div className={s.logo}>
            <img src={`${apiClient.defaults.baseURL}${parameters.logo}`} alt="" />
          </div>
        ) : (
          <></>
        )}
        {data.distance ? (
          <div className={s.nav_container}>
            <LocationSvg />
            <div className={s.nav_txt}>{data.distance.toFixed(1)} км</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default React.memo(OutletItem)
