import apiClient from '../apiClient/apiClient'

export const fetchBookingList = async (outlet_id?: number) => {
  const { data } = await apiClient.get(`/api/booking_list?outlet_id=${outlet_id || 1}`)
  return data
}

export const fetchBookingAreas = async (outlet_id?: number) => {
  const { data } = await apiClient.get(
    `/api/outlets/areas?outlet_id=${outlet_id || 1}&show_tables=1`,
  )
  return data
}

export const fetchBookingLocks = async (outlet_id: number) => {
  const { data } = await apiClient.get(`/api/booking_locks?outlet_id=${outlet_id}`)
  return data
}

export const createBookingLocksRequest = async (sendData: {
  booking_date: string
  booking_status: number
  outlet_area_table_id: number
  outletId: number
}) => {
  const { data } = await apiClient.post('/api/booking_locks', sendData)
  return data
}

export const removeBookingLocksRequest = async (id: number) => {
  const { data } = await apiClient.delete('/api/booking_locks', {
    data: {
      id,
    },
  })
  return data
}
