import PageLayout from 'application/presentation/common/PageLayout/PageLayout'
import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import s from './InformationPage.module.scss'

const InformationPage = () => {
  return (
    <PageLayout title="Информация">
      <div className={s.content}>
        <div className={s.text}>
          Сервис представляет информационные услуги по представлению автомоечных комплексов в
          России.
          <br />
          Подробнее на сайте <a href="https://moyservice.com">moyservice.com</a>
        </div>
        <div className={cn(s.text, s.right)}>
          Услуги оказываются <br />
          ИП Федоров Павел Валерьевич
          <br />
          Банк АО "ТИНЬКОФФ БАНК"
          <br />
          ИНН 212885740013
          <br />
          428020, Россия, Чувашия Чувашская Республика, г Чебоксары, ул Хевешская, д 7, кв 117
          <br />
          БИК 044525974
          <br />
          Сч 30101810145250000974
          <br />
          рс/счет 40802810200000018345
          <br />
          <br />
          <br />
          Услуги оказываются на основании <br />
          <NavLink to="/offerta">договора оферты.</NavLink>
        </div>
      </div>
    </PageLayout>
  )
}

export default React.memo(InformationPage)
