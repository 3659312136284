import { useEffect, useState } from 'react'
import _find from 'lodash/find'
import _mapKeys from 'lodash/mapKeys'

import { Car } from '../../entity/car/Car'
import { Outlet } from '../../entity/outlet/Outlet'
import { getOutletAppliedParameter } from '../outlets/outletActions'

export const defaultCarTypes = [
  {
    id: 1,
    name: 'Легковые',
    classes: ['A', 'B', 'C', 'D', 'F', 'S'],
  },
  {
    id: 2,
    name: 'Кроссоверы',
    classes: ['X'],
  },
  {
    id: 3,
    name: 'Внедорожники',
    classes: ['J'],
  },
  {
    id: 4,
    name: 'Минивэны',
    classes: ['M'],
  },
]

export const getCarTypeByClass = (
  car: Car | null | undefined,
  types: Array<{ id: number; name: string; classes: Array<string> }>,
  outlet: Outlet | null,
) => {
  const parameter = getOutletAppliedParameter(outlet, 'edited_car_classes')
  let classChar = car && car.class ? car.class.toUpperCase() : 'A'
  if (parameter) {
    try {
      const editedCarClasses = JSON.parse(parameter.value)
      const carName = car ? car.carName || car.name : ''
      const carModelName = car ? car.modelName : ''
      if (
        car &&
        editedCarClasses[`${carName.toUpperCase()}`] &&
        editedCarClasses[`${carName.toUpperCase()}`][`${carModelName.toUpperCase()}`]
      ) {
        classChar = editedCarClasses[`${carName.toUpperCase()}`][`${carModelName.toUpperCase()}`]
      }
    } catch (e) {
      console.log(e)
    }
  }
  const type = _find(types, (item) => item.classes.indexOf(classChar) !== -1)
  return type ? type : types[0]
}

const getCarTypes = (outlet: Outlet | null) => {
  const parameter = getOutletAppliedParameter(outlet, 'car_classes')
  if (parameter) {
    try {
      const res: Array<{ id: number; name: string; classes: Array<string> }> = []
      const parsed = JSON.parse(parameter.value)
      if (parsed && parsed.Categories) {
        let id = 1
        _mapKeys(parsed.Categories, (value: any, key: string) => {
          res.push({
            id,
            name: key,
            classes: value,
          })
          id += 1
        })
        return res
      }
    } catch (e) {
      console.log(e)
    }
  }
  return defaultCarTypes
}

export const useCarTypes = (outlet: Outlet | null) => {
  const [carTypes, setCarTypes] = useState<
    Array<{ id: number; name: string; classes: Array<string> }>
  >(() => getCarTypes(outlet))

  useEffect(() => {
    const types = getCarTypes(outlet)
    setCarTypes(types)
  }, [outlet])

  return carTypes
}
