// import AsyncStorage from '@react-native-async-storage/async-storage'
import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import appLocalStorage from 'application/core/data/appLocalStorage'
import moment from 'moment'
import getRandomInRange from 'application/core/utils/getRandomInRange'

import { Basket } from '../entity/basket/Basket'
import { Product } from '../entity/product/Product'
import { BookingTable } from '../entity/booking/Booking'
import { recalculateBasketDiscont } from '../useCase/basket/basketActions'

type BookingDataType = {
  date: string
  table: BookingTable
} | null

type BasketState = {
  basket: Basket
  bookingData: BookingDataType
}

const initialState: BasketState = {
  basket: [],
  bookingData: null,
}

export const basketReducer = createSlice({
  name: 'basketReducer',
  initialState,
  reducers: {
    addProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket.push({ ...product, uniq_id: `${moment().unix()}${getRandomInRange(1, 1000)}` })
      state.basket = recalculateBasketDiscont(state.basket)
      appLocalStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    removeProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket = _.filter(state.basket, (p) => p.uniq_id !== product.uniq_id)
      appLocalStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    removeProductsAction: (state, { payload: uniq_ids }: PayloadAction<Array<string>>) => {
      state.basket = _.filter(
        state.basket,
        (p: Product) => _.findIndex(uniq_ids, (i: string) => p.uniq_id === i) === -1,
      )
    },
    editProductAction: (state, { payload: product }: PayloadAction<Product>) => {
      state.basket = _.map(state.basket, (item) => {
        if (product.uniq_ids && product.uniq_ids.length) {
          if (product.uniq_ids.indexOf(item.uniq_id) !== -1) {
            return {
              ...item,
              options: product.options,
              selected_options: product.selected_options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: product.variety,
            }
          }
        } else {
          if (product.uniq_id === item.uniq_id) {
            return {
              ...item,
              options: product.options,
              selected_options: product.selected_options,
              temperature: product.temperature,
              varieties: product.varieties,
              variety: product.variety,
            }
          }
        }

        return item
      })
      appLocalStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    setBasketDataAction: (state, { payload: basket }: PayloadAction<Basket>) => {
      if (basket.length) {
        state.basket = _.map(basket, (item) => ({
          ...item,
          uniq_id: `${moment().unix()}${getRandomInRange(1, 1000)}`,
        }))
      } else {
        state.basket = []
      }
      appLocalStorage.setItem('basketData', JSON.stringify(state.basket))
    },
    setBookingDataAction: (state, { payload: data }: PayloadAction<BookingDataType>) => {
      state.bookingData = data
      appLocalStorage.setItem('bookingData', JSON.stringify(data))
    },
  },
})

const {
  addProductAction,
  removeProductAction,
  setBasketDataAction,
  removeProductsAction,
  editProductAction,
  setBookingDataAction,
} = basketReducer.actions

export const addProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(addProductAction(product))

export const editProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(editProductAction(product))

export const removeProduct = (product: Product) => (dispatch: Dispatch) =>
  dispatch(removeProductAction(product))

export const removeProducts = (uniq_ids: Array<string>) => (dispatch: Dispatch) =>
  dispatch(removeProductsAction(uniq_ids))

export const setBasket = (basket: Basket) => (dispatch: Dispatch) =>
  dispatch(setBasketDataAction(basket))

export const setBookingData = (data: BookingDataType) => (dispatch: Dispatch) =>
  dispatch(setBookingDataAction(data))

export default basketReducer.reducer
