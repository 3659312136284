import { Basket } from 'application/core/domain/entity/basket/Basket'
import { Outlet } from 'application/core/domain/entity/outlet/Outlet'
import { Product } from 'application/core/domain/entity/product/Product'
import {
  calculateBasket,
  checkIsSomethingNotInStock,
} from 'application/core/domain/useCase/basket/basketActions'
import { getSelectedAdditionasIds } from 'application/core/domain/useCase/products/productsAction'
import _ from 'lodash'
import { useCallback, useRef, useState, useEffect } from 'react'

type Props = {
  outlet: Outlet | null
  basket: Basket
  token: string | null
}

export const useBasket = ({ outlet: userOutlet, basket: basketData, token }: Props) => {
  const timer = useRef<any>()
  const [fullPrice, setFullPrice] = useState<number>(0)
  const [somethingNotInStock, setSomethingNotInStock] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [calculatedBasket, setCalculatedBasket] = useState<Array<Product>>([])
  const [error, setError] = useState<string>('')

  const initBasket = useCallback(
    async (basket: Basket) => {
      setIsLoading(true)
      if (basket && basket.length && userOutlet) {
        const basketToSend = _.map(basket, (item) => {
          const volume = _.find(item.varieties, { selected: true })
          return {
            // product_id: item.product_id,
            id: item.id,
            temperature: item.temperature ? item.temperature : null,
            variety_id: volume ? volume.variety_id : null,
            options: getSelectedAdditionasIds(item),
          }
        })
        try {
          const calculated = await calculateBasket(basketToSend, userOutlet ? userOutlet.id : 0, {
            headers: {
              Authorization: token ? `Bearer ${token}` : '',
            },
          })
          setError('')
          // const merged = mergeBasketData(basket, calculated)
          const price = _.sumBy(calculated, 'full_cost') || 0
          // setMergedBasket(merged)
          setCalculatedBasket(calculated)
          setFullPrice(price)

          setSomethingNotInStock(checkIsSomethingNotInStock(calculated))
          setIsLoading(false)
        } catch (err: any) {
          if (err && err.response && err.response.data && err.response.data.error) {
            setError(err.response.data.error)
          } else {
            setError('Произошла ошибка при расчете корзины')
          }
          setIsLoading(false)
          setFullPrice(0)
        }
      } else {
        setError('')
        setCalculatedBasket([])
        setIsLoading(false)
        setFullPrice(0)
      }
    },
    [userOutlet, token],
  )

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => initBasket(basketData), 100)
  }, [basketData, initBasket])

  return {
    initBasket,
    calculatedBasket: calculatedBasket,
    fullPrice,
    somethingNotInStock,
    isLoading,
    error,
  }
}
