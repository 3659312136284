import React, { useMemo } from 'react'
import { ReactComponent as ArrowSvg } from 'images/icons/back_icon.svg'
import moment from 'moment'
import { useNavigate } from 'react-router'

import s from './ActuallyOrder.module.scss'

type ActuallyOrderProps = {
  order: any
}

const ActuallyOrder = ({ order }: ActuallyOrderProps) => {
  const nav = useNavigate()
  const day = useMemo(() => {
    if (order) {
      let today = new Date()
      let tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000)
      let momentToday = moment(today).format('DD.MM')
      let momentTomorrow = moment(tomorrow).format('DD.MM')
      let orderDay = moment(order.created_at).format('DD.MM')
      if (orderDay === momentToday) {
        return ' (Сегодня) '
      } else if (order === momentTomorrow) {
        return ' (Завтра) '
      } else {
        return ''
      }
    }
    return ''
  }, [order])

  const orderId = useMemo(() => {
    return order && order.status && order.status.toLowerCase() !== 'завершен'
      ? `is_open_order=true`
      : ''
  }, [order])

  return (
    <div
      className={s.container}
      onClick={() => nav(`/orders/${order?.id}${!!orderId ? `?${orderId}` : ''}`)}
    >
      <div className={s.left}>
        <div className={s.small_title}>Активный заказ</div>
        <div className={s.time}>
          {moment(order.created_at).format('DD.MM')}, {day}
        </div>
      </div>
      <div className={s.right}>
        <div className={s.icon}>
          <ArrowSvg />
        </div>
      </div>
    </div>
  )
}

export default React.memo(ActuallyOrder)
