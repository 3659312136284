import { Button } from 'application/presentation/common/uiComponents'
import React, { useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router'

import MWContainer from '../MWContainer'

import s from './NoticeMW.module.scss'

type Props = {
  title?: string
  text?: string
  triggerHash?: string
  confirmationProps?: {
    onConfirm: Function
    confirmIsLoading?: boolean
    yesBtnTxt?: string
    noBtnTxt?: string
  }
}

const NoticeMW = ({ title, text, triggerHash, confirmationProps }: Props) => {
  const location = useLocation()
  const navigation = useNavigate()
  const opened = useMemo(() => {
    return location.hash.indexOf(triggerHash || 'info') !== -1
  }, [location, triggerHash])

  const closeMW = useCallback(() => navigation(-1), [navigation])

  return (
    <MWContainer
      isOpen={opened}
      onRequestClose={() => {
        if (location.hash.indexOf(triggerHash || 'info') !== -1) {
          closeMW()
        }
      }}
    >
      <div className={s.container}>
        {title ? <div className={s.title}>{title}</div> : <></>}
        {text ? <div className={s.text} dangerouslySetInnerHTML={{ __html: text }}></div> : <></>}
        {confirmationProps ? (
          <>
            <Button
              color="fiol"
              text={confirmationProps.yesBtnTxt || 'Да'}
              onClick={confirmationProps.onConfirm}
              containerClassName={s.btn_container}
              isLoading={confirmationProps.confirmIsLoading}
            />
            <Button
              color="gray"
              text={confirmationProps.noBtnTxt || 'Нет'}
              onClick={closeMW}
              containerClassName={s.btn_container}
            />
          </>
        ) : (
          <Button
            color="fiol"
            text={'Понятно'}
            onClick={closeMW}
            containerClassName={s.btn_container}
          />
        )}
      </div>
    </MWContainer>
  )
}

export default React.memo(NoticeMW)
