import React, { useCallback } from 'react'
import { ReactComponent as EmptyStar } from 'images/icons/star_empty.svg'
import { ReactComponent as Star } from 'images/icons/rate_star.svg'
import cn from 'classnames'

import s from './Rating.module.scss'

type Props = {
  rating: number
  onChange: Function
  containerClassName?: string
}

const Rating = ({ rating, onChange, containerClassName }: Props) => {
  const onClickHandler = useCallback((value: number) => onChange(value), [onChange])
  return (
    <div className={cn(s.rates, containerClassName || '')}>
      <div className={s.star} onClick={() => onClickHandler(1)}>
        {rating >= 1 ? <Star /> : <EmptyStar />}
      </div>
      <div className={s.star} onClick={() => onClickHandler(2)}>
        {rating >= 2 ? <Star /> : <EmptyStar />}
      </div>
      <div className={s.star} onClick={() => onClickHandler(3)}>
        {rating >= 3 ? <Star /> : <EmptyStar />}
      </div>
      <div className={s.star} onClick={() => onClickHandler(4)}>
        {rating >= 4 ? <Star /> : <EmptyStar />}
      </div>
      <div className={s.star} onClick={() => onClickHandler(5)}>
        {rating >= 5 ? <Star /> : <EmptyStar />}
      </div>
    </div>
  )
}

export default React.memo(Rating)
